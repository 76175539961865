import React from "react";
import { useTranslation } from "react-i18next";

type ConfirmationModal = {
  onClose: () => void;
  onSave: () => void;
  disabled: boolean
};

const ConfirmationModal = (props: ConfirmationModal) => {
  const { onClose, onSave, disabled } = props;
  const { t } = useTranslation('translation');
  
  return (
      <div className="flex sm:flex-col justify-between px-4 gap-2 items-center m-auto h-full">
        <span>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10890_45439)">
            <circle cx="20" cy="20" r="20" fill="#8E55EA"/>
            <path d="M24.073 23.0138L29.041 18.0823M29.041 18.0823L24.073 13.1508M29.041 18.0823H15.3789C14.0613 18.0823 12.7977 18.6019 11.866 19.5267C10.9343 20.4515 10.4109 21.7059 10.4109 23.0138C10.4109 24.3217 10.9343 25.5761 11.866 26.5009C12.7977 27.4257 14.0613 27.9453 15.3789 27.9453H16.6209" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_10890_45439">
            <rect width="40" height="40" fill="white" transform="matrix(-1 0 0 -1 40 40)"/>
            </clipPath>
            </defs>
          </svg>
        </span>
        <p className="text-center text-md !text-white font-semibold">
          Move Document
        </p>
        <div className="flex gap-2 items-center">
        <button
          className="btn-stroke-light border-n-5 text-white btn-medium md:min-w-[5rem]"
          onClick={onClose}
          disabled={disabled}
        >
          {t('cancel_btn')}
        </button>
        <button
          className="btn-blue btn-medium ml-3 md:min-w-[5rem]"
          onClick={onSave}
          disabled={disabled}
        >
          {t('move')}
        </button>
      </div>
      </div>
   
  );
};
export default ConfirmationModal;
