import { WriteIcon } from "@/components/SVGs";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

type PageRangeSelectorTypes = {
  maxLength: number;
  from: number;
  to: number;
  setValues: any;
  setRangeVisible: any;
};
const PageRangeSelector = ({
  maxLength,
  from,
  to,
  setValues,
  setRangeVisible,
}: PageRangeSelectorTypes) => {
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);
  const { t } = useTranslation("translation");

  useEffect(() => {
    setFromValue(from);
    setToValue(to);
  }, [from, to]);

  const handleFromChange = (e: any) => {
    const value = parseInt(e.target.value, 10);
    if (value <= maxLength || !value) {
      setFromValue(value);
      setValues({ from: value, to: toValue });
    }
  };

  const handleToChange = (e: any) => {
    const value = parseInt(e.target.value, 10);
    console.log(value);
    console.log(maxLength);
    if (value < maxLength || !value) {
      setToValue(value);
      setValues({ from: fromValue, to: value });
    }
  };

  const clearRange = () => {
    setRangeVisible(false);
    setFromValue(1);
    setToValue(maxLength > 5 ? 5 : maxLength);
    setValues({ from: 1, to: maxLength > 5 ? 5 : maxLength });
  };

  return (
    <div className="flex items-center space-x-4 justify-between w-full border border-gray-300 h-[45px] rounded-lg px-[10px]">
      <div className="flex items-center w-full ">
        <div className="flex items-center border-transparent rounded dark:text-white">
          {/* <label className="font-medium mx-3 text-black text-s">{t('from')}</label> */}
          <input
            type="number"
            value={fromValue}
            onChange={handleFromChange}
            min="1"
            max={maxLength}
            className=" focus:outline-none custom-number-input text-black dark:text-white"
          />
        </div>
        <div className="flex items-center border-transparent rounded">
          {/* <label className="font-medium mx-3 text-black text-s">{t('to')}</label> */}
          <label className="font-medium mx-3 text-black dark:text-white text-s">-</label>
          <input
            type="number"
            value={toValue}
            onChange={handleToChange}
            min={fromValue}
            max={maxLength}
            className=" focus:outline-none custom-number-input custom-number-input text-black dark:text-white"
          />
        </div>
      </div>
      {/* <WriteIcon /> */}
      <button onClick={clearRange} className=" text-medium">
        ✕
      </button>
    </div>
  );
};

export default PageRangeSelector;
