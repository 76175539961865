import { createAsyncThunk } from "@reduxjs/toolkit";
import { IStoreDocumentListData,IUpdateDocumentListData } from "interfaces/document.interface";
import { documentListService } from "services/documentList.service";


export const storeDocumentList = createAsyncThunk(
  "translationStore/storeDocumentList",
  async (data: IStoreDocumentListData) => {
    try {
      const response = await documentListService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const editDocumentList = createAsyncThunk(
  "translationStore/storeDocumentList",
  async (params: any) => {
    try {
      const response = await documentListService.update(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getStoredDocumentList = createAsyncThunk(
  "translationStore/getStoredDocumentList",
  async () => {
    try {
      console.log("sending req to getStoredDocumentList");
      
      const response = await documentListService.index();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const destroyDocumentList = createAsyncThunk(
  "translationStore/destroyDocumentList",
  async (id: any, { rejectWithValue }) => {
    try {
      console.log("sending req to destroyDocumentList");
      const response = await documentListService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      // Use rejectWithValue to pass error details to the rejected action
      return rejectWithValue(response?.data?.message || response?.statusText || "Error occurred");
    }
  }
);
export const updateDocumentListId = createAsyncThunk(
  "translationStore/updateDocumentListId",
  async (data: IUpdateDocumentListData) => {
    try {
      const response = await documentListService.updateDocumentList(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const getDocumentListById = createAsyncThunk(
  "translationStore/getDocumentListById",
  async (params: any) => {
    try {
      const response = await documentListService.show(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

// export const exportChatLists = createAsyncThunk(
//   "chatgptStore/exportChatLists",
//   async (data: IExportChatData) => {
//     try {
//       const response = await chatListService.export(data);
//       return response.data;
//     } catch (error: any) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// );


// export const deleteChatList = createAsyncThunk(
//   "chatgptStore/destroyChatList",
//   async (ids: any) => {
//     try {
//       const response = await chatListService.deleteMultiple(ids);
//       return response.data;
//     } catch (error: any) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// );