import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE_URL } from "@/constants/user";
import { size } from "lodash";
import Modal from "../Modal";
import InviteUsersModal from "../Modal/InviteUsersModal";
import { setDebateUsersModal } from "store/slices/DebateSlice";
import ImageButtonIndicator from "../ImageButtonIndicator";
import Icon from "../Icon";
import TooltipIndicator from "../TooltipIndicator";

const DebateUser = () => {
  const dispatch: any = useDispatch();

  const userData = useSelector((state: any) => state.authStore);
  const debateData = useSelector((state: any) => state.debateStore);

  const { user } = userData;
  const { storeDebate, modalStates, debateInvitedUsers } = debateData;

  const [showCompleteUsers, setShowCompleteUsers] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<any>([]);

  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;

  useEffect(() => {
    try {
      setInvitedUsers([...debateInvitedUsers])
      if (size(debateInvitedUsers) > 2) {
        setShowCompleteUsers(false);
      } else setShowCompleteUsers(true);
    } catch (error) {
      
    }

  }, [debateInvitedUsers]);

  let firstThreeArrays = [], remainingArrays = [];
  if (size(debateInvitedUsers) > 2) {
    const invitedUsersCopy = [...invitedUsers, storeDebate?.data?.user];

    firstThreeArrays = invitedUsersCopy.splice(0, 3);
    remainingArrays = invitedUsersCopy ?? [];
  } else {
    firstThreeArrays = [...invitedUsers, storeDebate?.data?.user];
    remainingArrays = [];
  }

  const handleImageClick = () => {
    dispatch(setDebateUsersModal(true));
  };
  const handleCloseModal = () => {
    dispatch(setDebateUsersModal(false));
  };
  
  return (
    <div className="flex items-center">
      {
        isDebateAdmin && <div className="w-6 ss:w-4 ss:h-4 h-6 bg-primary-1 rounded-full text-white -ml-2 z-2 relative flex items-center justify-center text-lg cursor-pointer" onClick={handleImageClick}>
        <Icon name={"plus"} className="w-4 md:w-3 md:h-3 h-4 fill-white"/>
      </div>
      }
      {firstThreeArrays && firstThreeArrays.map((item: any, idx: number) => (
        <ImageButtonIndicator
          tooltipContent={item?.userName ?? ""}
          src={item?.profileUrl ?? PROFILE_URL}
          key={idx}
          onClick={handleImageClick}
        />
      ))}
      {showCompleteUsers &&
        size(remainingArrays) > 0 &&
        remainingArrays.map((item: any, idx: number) => (
          <ImageButtonIndicator
            tooltipContent={item?.userName ?? ""}
            src={item?.profileUrl ?? PROFILE_URL}
            key={idx}
            onClick={handleImageClick}
          />
        ))}
      {size(remainingArrays) > 0 && !showCompleteUsers && (
        <TooltipIndicator size={size(remainingArrays)} tooltipContent={remainingArrays} />
      )}
      <Modal
        className=""
        classWrap="max-w-[640px] md:min-h-screen-ios !p-9 !pt-6"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={modalStates?.inviteUsers}
        onClose={handleCloseModal}
      >
        <InviteUsersModal
          onCancel={handleCloseModal}
          setVisibleModal={modalStates?.inviteUsers}
          invitedUsers={invitedUsers}
        />
      </Modal>
    </div>
  );
};

export default DebateUser;
