var languageData = [
   

    {
     name: "Afrikaans",
     code:"af"
     },

     {
     name: "Albanian",
     code:"sq"
     },

     {
     name: "Amharic",
     code:"am"
     },

     {
     name: "Arabic",
     code:"ar"
     },

     {
     name: "Armenian",
     code:"hy"
     },

     {
     name: "Assamese",
     code:"as"
     },

     {
     name: "Azerbaijani (Latin)",
     code:"az"
     },

     {
     name: "Bangla",
     code:"bn"
     },

     {
     name: "Bashkir",
     code:"ba"
     },

     {
     name: "Basque",
     code:"eu"
     },

     {
     name: "Bosnian (Latin)",
     code:"bs"
     },

     {
     name: "Bulgarian",
     code:"bg"
     },

     {
     name: "Cantonese (Traditional)",
     code:"yue"
     },

     {
     name: "Catalan",
     code:"ca"
     },

     {
     name: "Chinese (Literary)",
     code:"lzh"
     },

     {
     name: "Chinese Simplified",
     code:"zh-Hans"
     },

     {
     name: "Chinese Traditional",
     code:"zh-Hant"
     },

     {
     name: "chiShona",
     code:"sn"
     },

     {
     name: "Croatian",
     code:"hr"
     },

     {
     name: "Czech",
     code:"cs"
     },

     {
     name: "Danish",
     code:"da"
     },

     {
     name: "Dari",
     code:"prs"
     },

     {
     name: "Divehi",
     code:"dv"
     },

     {
     name: "Dutch",
     code:"nl"
     },

     {
     name: "English",
     code:"en"
     },

     {
     name: "Estonian",
     code:"et"
     },

     {
     name: "Faroese",
     code:"fo"
     },

     {
     name: "Fijian",
     code:"fj"
     },

     {
     name: "Filipino",
     code:"fil"
     },

     {
     name: "Finnish",
     code:"fi"
     },

     {
     name: "French",
     code:"fr"
     },

     {
     name: "French (Canada)",
     code:"fr-ca"
     },

     {
     name: "Galician",
     code:"gl"
     },

     {
     name: "Georgian",
     code:"ka"
     },

     {
     name: "German",
     code:"de"
     },

     {
     name: "Greek",
     code:"el"
     },

     {
     name: "Gujarati",
     code:"gu"
     },

     {
     name: "Haitian Creole",
     code:"ht"
     },

     {
     name: "Hausa",
     code:"ha"
     },

     {
     name: "Hebrew",
     code:"he"
     },

     {
     name: "Hindi",
     code:"hi"
     },

     {
     name: "Hmong Daw (Latin)",
     code:"mww"
     },

     {
     name: "Hungarian",
     code:"hu"
     },

     {
     name: "Icelandic",
     code:"is"
     },

     {
     name: "Igbo",
     code:"ig"
     },

     {
     name: "Indonesian",
     code:"id"
     },

     {
     name: "Inuinnaqtun",
     code:"ikt"
     },

     {
     name: "Inuktitut",
     code:"iu"
     },

     {
     name: "Inuktitut (Latin)",
     code:"iu-Latn"
     },

     {
     name: "Irish",
     code:"ga"
     },

     {
     name: "Italian",
     code:"it"
     },

     {
     name: "Japanese",
     code:"ja"
     },

     {
     name: "Kannada",
     code:"kn"
     },

     {
     name: "Kazakh",
     code:"kk"
     },

     {
     name: "Khmer",
     code:"km"
     },

     {
     name: "Kinyarwanda",
     code:"rw"
     },

     {
     name: "Klingon",
     code:"tlh-Latn"
     },

     {
     name: "Klingon (plqaD)",
     code:"tlh-Piqd"
     },

     {
     name: "Konkani",
     code:"gom"
     },

     {
     name: "Korean",
     code:"ko"
     },

     {
     name: "Kurdish (Central)",
     code:"ku"
     },


     {
     name: "Kurdish (Northern)",
     code:"kmr"
     },

     {
     name: "Kyrgyz (Cyrillic)",
     code:"ky"
     },

     {
     name: "Lao",
     code:"lo"
     },

     {
     name: "Latvian",
     code:"lv"
     },

     {
     name: "Lithuanian",
     code:"lt"
     },

     {
     name: "Lingala",
     code:"ln"
     },

     {
     name: "Lower Sorbian",
     code:"dsb"
     },

     {
     name: "Luganda",
     code:"lug"
     },

     {
     name: "Macedonian",
     code:"mk"
     },

     {
     name: "Maithili",
     code:"mai"
     },

     {
     name: "Malagasy",
     code:"mg"
     },

     {
     name: "Malay (Latin)",
     code:"ms"
     },

     {
     name: "Malayalam",
     code:"ml"
     },

     {
     name: "Maltese",
     code:"mt"
     },

     {
     name: "Maori",
     code:"mi"
     },

     {
     name: "Marathi",
     code:"mr"
     },

     {
     name: "Mongolian (Cyrillic)",
     code:"mn-Cyrl"
     },

     {
     name: "Mongolian (Traditional)",
     code:"mn-Mong"
     },

     {
     name: "Myanmar",
     code:"my"
     },

     {
     name: "Nepali",
     code:"ne"
     },

     {
     name: "Norwegian",
     code:"nb"
     },

     {
     name: "Nyanja",
     code:"nya"
     },

     {
     name: "Odia",
     code:"or"
     },

     {
     name: "Pashto",
     code:"ps"
     },

     {
     name: "Persian",
     code:"fa"
     },

     {
     name: "Polish",
     code:"pl"
     },

     {
     name: "Portuguese (Brazil)",
     code:"pt"
     },

     {
     name: "Portuguese (Portugal)",
     code:"pt-pt"
     },

     {
     name: "Punjabi",
     code:"otq"
     },

     {
     name: "Queretaro Otomi",
     code:"fa"
     },

     {
     name: "Romanian",
     code:"ro"
     },

     {
     name: "Rundi",
     code:"run"
     },

     {
     name: "Russian",
     code:"ru"
     },

     {
     name: "Samoan (Latin)",
     code:"sm"
     },

     {
     name: "Serbian (Cyrillic)",
     code:"sr-Cyrl"
     },

     {
     name: "Serbian (Latin)",
     code:"sr-Latn"
     },

     {
     name: "Sesotho",
     code:"st"
     },

     {
     name: "Sesotho sa Leboa",
     code:"nso"
     },

     {
     name: "Setswana",
     code:"tn"
     },

     {
     name: "Sindhi",
     code:"sd"
     },

     {
     name: "Sinhala",
     code:"si"
     },

     {
     name: "Slovak",
     code:"sk"
     },

     {
     name: "Slovenian",
     code:"sl"
     },

     {
     name: "Somali (Arabic)",
     code:"so"
     },

     {
     name: "Spanish",
     code:"es"
     },

     {
     name: "Swahili (Latin)",
     code:"sw"
     },

     {
     name: "Swedish",
     code:"sv"
     },

     {
     name: "Tahitian",
     code:"ty"
     },

     {
     name: "Tamil",
     code:"ta"
     },

     {
     name: "Tatar (Latin)",
     code:"tt"
     },

     {
     name: "Telugu",
     code:"te"
     },

     {
     name: "Thai",
     code:"th"
     },

     {
     name: "Tibetan",
     code:"bo"
     },

     {
     name: "Tigrinya",
     code:"ti"
     },

     {
     name: "Tongan",
     code:"to"
     },

     {
     name: "Turkish",
     code:"tr"
     },

     {
     name: "Turkmen (Latin)",
     code:"tk"
     },

     {
     name: "Ukrainian",
     code:"uk"
     },

     {
     name: "Upper Sorbian",
     code:"hsb"
     },

     {
     name: "Urdu",
     code:"ur"
     },

     {
     name: "Uyghur (Arabic)",
     code:"ug"
     },

     {
     name: "Uzbek (Latin)",
     code:"uz"
     },

     {
     name: "Vietnamese",
     code:"vi"
     },

     {
     name: "Welsh",
     code:"cy"
     },

     {
     name: "Xhosa",
     code:"xh"
     },

     {
     name: "Yoruba",
     code:"yo"
     },

     {
     name: "Yucatec Maya",
     code:"yua"
     },
     {name: "Zulu", code:"zu"}

]
export default languageData;