import { HttpService } from './base.service'
import { IAddToLiveNotebookData, IDeleteUserNotebooks } from 'interfaces/notebook.interface';

class NotebookService extends HttpService {
    private readonly prefix: string = "notebook";

    index = (data: string): Promise<any> => this.post(this.prefix + '/index', data);

    store = (data: IAddToLiveNotebookData): Promise<any> => this.post(this.prefix + '/store', data);

    destroy = (id: any): Promise<any> => this.delete(this.prefix + `/destroy/${id}`);
    destroyByUserId = (data: IDeleteUserNotebooks): Promise<any> => this.post(this.prefix + '/destroy-by-user', data);

    update = (data: any): Promise<any> => this.put(this.prefix + '/update', data);
    generateTextFromPdf=(data: any): Promise<any> => this.post(this.prefix + '/generateTextFromPdf', data);
    generateTextFromPdfLink=(data: any): Promise<any> => this.post(this.prefix + '/generateTextFromPdfLink', data);
}

export const notebookService = new NotebookService();