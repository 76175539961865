import React, { useState } from "react";
import Modal from "@/components/Modal";
import UploadDocument from "../UploadDocument";
import TranslateDocument from "../TranslateDocument";
import TranslatingDocument from "../TranslatingDocument";
import SuccessFullyTranslated from "../SuccessfullyTranslated";
import toast from "react-hot-toast";
import axios from "axios";
import Config from "../../config/index";
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { isEmpty } from "lodash";
import { addSummaryDocument } from "store/slices/ChatGptSlice";
import { LANGUAGE_CODE } from "@/constants/audio";
import Notify from "../Notify";
import Field from "../Field";
import { useTranslation } from "react-i18next";
import { UrlIcon } from "../SVGs";
import { ImSpinner9 } from "react-icons/im";

type UploadDocumentModalProps = {
  visible: boolean;
  onClose: () => void;
  setIsLoading?: any;
};

const SummarizeUrlModal = ({
  visible,
  onClose,
  setIsLoading,
}: UploadDocumentModalProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");

  const { pathname, query } = router;

  const chatData = useSelector((state: any) => state.chatGptStore);

  const [mtype, setmtype] = useState<string>();
  const [payload, setpayload] = useState<IDocumentTranslatorState>({
    targetLanguage: "",
    file: "",
  });
  const [response, setresponse] = useState<any>({});
  const [translateProgress, settranslateProgress] = useState<number>(0);
  const [curStep, setcurStep] = useState<number>(1);
  const [value, setValue] = useState("");

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      setcurStep((ps: number) => ps - 1);
    } else {
      console.log("hiiiiiiii l2");
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  const showToast = (msg: any, err?: boolean) => {
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const initSummary = async () => {
    try {
      let data: any = {
        url: value,
      };
      if (pathname === ROUTES.DOCUMENT_PROCESSING) {
        if (!isEmpty(chatData.createChat.data?.id)) {
          data.chatId = chatData.createChat.data?.id;
        }
      } else if (
        query.id &&
        (pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID)
      ) {
        data.chatId = router.query.id;
      }
      changeStepHandler();
      console.log("data urlSummary", data);

      const res = await axios.post(
        `${Config.baseApiUrl}/document-summary/urlSummary`,
        data,
        {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            settranslateProgress(Math.ceil(percentCompleted / 1.2));
          },
        }
      );
      if (res?.status == 200) {
        settranslateProgress(100);
        setresponse(res.data);
        changeStepHandler();
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6">Summarize successfully!</div>
            </Notify>
          ),
          { duration: 3000 }
        );
        dispatch(addSummaryDocument(res.data));
        setcurStep(1);
        setIsLoading(true);
        settranslateProgress(0);
        setValue("");
        onClose();
      } else {
        showToast("Failed to Summerize.", true);
        changeStepHandler(true);
        setValue("");
      }
    } catch (error: any) {
      console.log("kkkk", error);
      changeStepHandler(true);
      setValue("");
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to summerize due to Unknown reason."
      );
    }
  };
  const isValidUrl = () => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div
      className="max-w-[43rem] p-[2.5rem] bg-n-2 rounded-3xl dark:bg-n-7 relative group"
      // classWrap="max-w-[43rem] p-8"
      // classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      // visible={visible}
      // onClose={() => { setcurStep(1); setValue(""); settranslateProgress(0); onClose(); }}
      // nonClosed={false}
    >
      <button
        className="text-large absolute opacity-0 right-[16px] top-[16px] group-hover:opacity-100 transition-opacity duration-200"
        onClick={() => {
          //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
          onClose();
        }}
      >
        ✕
      </button>
      {curStep == 1 && (
        <div>
          <Field
            name=""
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            placeholder={t("paste_url")}
            IconComponent={() => <UrlIcon />}
            className="fill-[#0084FF] h-28"
            classInput="h-24 pl-24 text-lg bg-transparent focus:bg-transparent border border-gray-200 dark:text-white"
          />
          <button
            className="btn btn-blue w-full mt-5"
            disabled={!isValidUrl()}
            onClick={initSummary}
          >
            {false && <ImSpinner9 className="animate-spin" />}
            {t("next")}
          </button>
        </div>
      )}
      {curStep == 2 && (
        <TranslatingDocument
          nextClick={changeStepHandler}
          mtype={"summary_url"}
          progress={translateProgress}
          onClose={onClose}
        />
      )}
      {/* {curStep == 4 && <SuccessFullyTranslated nextClick={changeStepHandler} mtype={mtype} data={response} state={payload} setState={setpayload} downloadFile={downloadFile} />} */}
    </div>
  );
};

export default SummarizeUrlModal;
