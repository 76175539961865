import React, { useState,useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { UploadIcons } from '@/components/LandingPage/Icons';
import config from '@/config/index';
import { notebookService } from 'services/notebook.service';
import { useDispatch, useSelector } from 'react-redux';
import { addToLiveNotebook } from 'store/actions/notebook.actions';
import { useTranslation } from 'react-i18next';
import { ImSpinner9 } from 'react-icons/im';
import toast from "react-hot-toast";
import Notify from '@/components/Notify';
import { useRouter } from 'next/router';
import Loader from '@/components/Loader';
import { PDFViewer } from '@/components/Modal/PdfPreview';
import Modal from '..';
import { setFileText } from 'store/slices/TextToSpeechSlice';
import { pdfjs } from 'react-pdf';
import PageRangeSelector from './components/RangeInput';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface IProps {
  setuploadDocument?: any,
  fromTextToSpeech?: boolean
  fromDocumentProcessing?:boolean
}

const UploadLiveNoteBookModal = ({ setuploadDocument, fromTextToSpeech }: IProps) => {
  const { t } = useTranslation('translation');
  const fileTypes = [
    // 'application/msword',
    // 'msword',
    // 'text/plain',
    // 'plain',
    'pdf',
    'application/pdf',
    // 'application/xml',
    // 'doc',
    // 'docs',
    // 'docx',
    // 'dox',
    // 'txt'
  ];

  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [rangeVisible, setRangeVisible] = useState(false);
  const [pageRange, setPageRange] = useState<any>({ from: 1, to: 2 });
  const [noOfPages, setNoOfPages] = useState(1);

  const setValues = (newValues: any) => {
    setPageRange(newValues);
  };

  const handleChange = async(selectedFile:any) => {
    if (selectedFile) {
      const arrayBuffer = await selectedFile.arrayBuffer();
      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
      setNoOfPages(pdf.numPages);
      if(pdf.numPages < 5){
        setPageRange({ from: 1, to: pdf.numPages })
      }else{
        setPageRange({ from: 1, to: 5 })
      }
    }
    setFile(selectedFile);
    fromTextToSpeech && setModalVisible(true)
  };

  const dispatch: any = useDispatch();
  const chatData = useSelector((state: any) => state.chatGptStore);
  const router = useRouter()

  const handleSelectedPagesChange = (newSelectedPages: any) => {
    setSelectedPages(newSelectedPages);
  };

  
  const {
    createChat,
  } = chatData;
  
  const showToast = (msg:any,err?:boolean) => {
    const icon = err == true ? 'iconError' : 'iconCheck'
    toast((t) => (
      //@ts-ignore
      <Notify className="md:flex-col md:items-center md:px-10" iconError>
        <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
          {msg}
        </div>
 
        <div className="flex justify-center">
          {/* <button
            className="btn-stroke-light btn-medium md:min-w-[6rem]"
            onClick={() => toast.dismiss(t.id)}
          >
            {lt('cancel_btn"')}
          </button> */}
 
          {/* <button
            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
            onClick={async () => {
              toast.dismiss(t.id);
            }}
          >
           {'Close'}
          </button> */}
        </div>
      </Notify>
    ), {duration: 5000});
  }
  function getPageNumbersArray() {
    const pageNumbers: number[] = [];
    for (let i = pageRange.from; i <= pageRange.to; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
  
  const handleNextClick = async () => {
    
    setLoading(true)
    if (file) {

      // if(file?.size > 10 * 1024 * 1024){
      //   showToast('File size should be less than 10mb')
      //   setLoading(false)
      //   return;
      // }
      if(file?.size < 1024){
        showToast('File size should be greater than 1kb')
        setLoading(false)
        return;
      }
      if(!rangeVisible && noOfPages > 1){
        showToast(t("select_page_range"))
        setLoading(false)
        return;
      }
      if(pageRange.from > pageRange.to){
        showToast(t("from_to_error"))
        setLoading(false)
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('selectedPages', JSON.stringify(getPageNumbersArray()));
      try {
        const response = await notebookService.generateTextFromPdf(formData);
  
        if (response && response.data && !fromTextToSpeech) {
          let notebookData: any = {
            chatId: createChat?.data?.id,
            response: response.data.data,
          };
          if(router?.pathname?.includes('debate')){
            notebookData['debateId'] = router?.query?.id
          }
  
          dispatch(addToLiveNotebook(notebookData));
          setuploadDocument && setuploadDocument(false)

        } else if (fromTextToSpeech) {
          dispatch(setFileText(response.data.data));
          setModalVisible(false);
        } else {
          console.error('Invalid or missing response data.');
          showToast('Invalid or missing response data.')
        }
      } catch (error:any) {
        console.error('Error while making the API call:', error);
        const errorMsg = error?.response?.data?.message ?? error?.message ?? 'Unknown Error'
        showToast(errorMsg)
      }
    } else {
      console.warn('No file selected.');
      showToast('No file selected.');
    }
    setLoading(false)
  };
  // useEffect(()=>{
  //   alert('debate')
  //   if(router.pathname.includes('debate')){
  //     alert(JSON.stringify(router.query))
  //   }
  // },[])

  return (
    <div style={{ width: '-webkit-fill-available' }}>
      <div className={`bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5 ${fromTextToSpeech && ' mt-4'}`}>
        <label className={`relative cursor-pointer border-white border-dashed border w-full h-[${fromTextToSpeech ? 200 : 288}px] rounded-[20px] flex flex-col justify-center items-center`}>
          {loading ? 
          <Loader color='white' /> 
          : <FileUploader disabled={loading} handleChange={handleChange} multiple={false} classes="drag_drop" name='file' types={fileTypes}>
            <div className='flex justify-center items-center flex-col w-full  focus:!border-transparent focus-within:!outline-none cursor-pointer'>
              <span className='mb-6 block'>
                <UploadIcons />
              </span>
                <h2 className={`h5 text-white !font-mont mb-1 ${fromTextToSpeech ? "-mt-2" : ""}`}>{fromTextToSpeech ? t('choose_file') : t('upload')}</h2>
                <div className='text-white m-auto text-center  w-[90%]'>
                  {file ? `${file?.name}` : fromTextToSpeech ? t('text_speech_drag') : t('doc_dragDrop')}
                </div>
            </div>
          </FileUploader>}
        </label>
      </div>
      {fromTextToSpeech ?
        <Modal
          className="d-flex align-items-center justify-content-center overflow-auto "
          classOverlay=""
          classWrap="max-w-[640px] md:min-h-screen-ios !p-9 max-h-[75vh] overflow-a !pr-0"
          classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
          visible={modalVisible} onClose={() => setModalVisible(false)}

        >
          <div className="overflow-y-auto max-h-[calc(75vh-72px)] pr-5">
          {!rangeVisible ? 
        <button className='btn btn-transparent border-[#0084FF] text-[#0084FF] w-full  left-7 sm:left-3 my-3' onClick={()=>setRangeVisible(true)}>
        <span className='text-lg mr-3 pb-1'>+</span> {t('custom_range')}
        </button>
        :
        <div className=' flex flex-row w-full my-3'>
          <PageRangeSelector
            maxLength={noOfPages}
            from={pageRange.from}
            to={pageRange.to}
            setValues={setValues}
            setRangeVisible={setRangeVisible}
          />
        </div>}
            {file && <button className='btn btn-blue w-full bottom-2 left-7 sm:left-3' disabled={!file || loading} onClick={handleNextClick}>
              {loading && <ImSpinner9 className="animate-spin" />}
              {t('next')}
            </button>
            }
          </div>
        </Modal>
        : file && noOfPages > 1 ?
        !rangeVisible ? 
        <button className='btn btn-transparent border-[#0084FF] text-[#0084FF] w-full  left-7 sm:left-3 my-3' onClick={()=>setRangeVisible(true)}>
        <span className='text-lg mr-3 pb-1'>+</span> {t('custom_range')}
        </button>
        :
        <div className=' flex flex-row w-full my-3'>
          <PageRangeSelector
            maxLength={noOfPages}
            from={pageRange.from}
            to={pageRange.to}
            setValues={setValues}
            setRangeVisible={setRangeVisible}
          />
        </div>
        : null
      }


      {file && !fromTextToSpeech && <button className='btn btn-blue w-full  left-7 sm:left-3' disabled={!file || loading} onClick={handleNextClick}>
      {loading && <ImSpinner9 className="animate-spin"/> }
      {t('next')}
      </button>
      }
<span className='text-lg mr-3 pb-1'> {t('custom_range')} </span> 
<div className=' flex flex-row w-full my-3'>

          <PageRangeSelector
            maxLength={noOfPages}
            from={pageRange.from}
            to={pageRange.to}
            setValues={setValues}
            setRangeVisible={setRangeVisible}
          />
        </div>
    </div>
  );
};

export default UploadLiveNoteBookModal;
