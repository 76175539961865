import React, { useEffect, useState } from "react";
import Image from "next/image";
import Icon from "@/components/Icon";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import SerachEmailSelect from "@/components/SerarchEmailSelect";
import { audioService } from "services/audio.service";
import { isEmpty, size, throttle } from "lodash";
import { getAllUsers } from "store/actions/auth.actions";
import { PROFILE_URL } from "@/constants/user";
import { leaveDebateById, updateInvitedUsers, removeUserFromDebate } from "store/actions/debate.actions";
import { resetLeaveDebateById, resetUpdateInvitedUsers, setDebateInvitedUsers } from "store/slices/DebateSlice";
import { isValidEmailRegex } from "lib/regex";
import { useTranslation } from "react-i18next";

type InviteUsersModalProps = {
  onCancel: () => void;
  setVisibleModal?: any;
  invitedUsers?: any;
};

const throttledSearch = throttle((search, dispatch) => {
  const params = { search: search };
  dispatch(getAllUsers(params));
}, 1500);

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({ onCancel, invitedUsers }) => {
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation(['translation','auth']);

  const userData = useSelector((state: any) => state.authStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const {
    storeDebate,
    updateDebateUsers,
    debateInvitedUsers,
    leaveDebate: leaveDebateData,
  } = debateData;
  const { user } = userData;

  const [userIds, setUserIds] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;

  useEffect(() => {
    if (!isEmpty(storeDebate.data?.id)) {
      const allUsers = [storeDebate.data?.user, ...storeDebate.data?.invitedUsers];
      setSelectedUsers(allUsers);
    }
  }, [storeDebate.data]);

  useEffect(() => {
    if (size(invitedUsers) >= 0 && !isEmpty(storeDebate.data?.user)) {
      const allUsers = [storeDebate.data.user, ...invitedUsers];
      setSelectedUsers(allUsers);
    }
  }, [invitedUsers]);

  const loadOptions = async (search: string) => {
    const isValidEmail = isValidEmailRegex.test(search);

    if (!isValidEmail) {
      // If it's not a valid email, return an empty array
      return [];
    }
    try {
      const response = await audioService.getAllUsers({
        search,
        page: 1,
        perPage: 12,
      });

      if (response?.data && size(response?.data?.data?.users) === 0) {
        return [];
      }

      return response?.data?.data?.users.map((item: any) => ({
        value: item?._id,
        label: (
          <div className="flex gap-3 items-center react-select pr-20">
            <>
              {
                item?.profileUrl ?
                <figure className="w-8 h-8 rounded-full relative">
                <Image src={item?.profileUrl ?? PROFILE_URL} fill className="w-full h-full rounded-full" alt="email-profile"/>
                </figure>:
                <div className="w-8 h-8 rounded-full text-white bg-primary-1 flex justify-center items-center flex-shrink-0">
                {item.userName && item?.userName?.charAt(0)?.toUpperCase()}
                </div>
              }
            </>
            <div className="flex flex-col">
              <span className="text-sm !font-mont">{item?.userName}</span>
              <p className="text-xs text-n-4 !font-mont">{item?.email}</p>
            </div>
          </div>
        ),
        email: item.email,
        userName: item.userName,
        profileUrl: item?.profileUrl ?? PROFILE_URL,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const handleSearch = (search: string) => {
    throttledSearch(search, dispatch);
  };

  const handleRemoveInvite = (item: any) => {
    const allInvitedUsers = [...selectedUsers];
    const allUserIds = [...userIds];

    const indexToRemove = allInvitedUsers.findIndex((el: any) => el?._id === item?._id);
    const idxOfUserToRemove = allUserIds.findIndex(id=> id === item?._id);
    const data = {
      debateId: storeDebate.data.id,
      userToRemove: item._id
    }
    dispatch(removeUserFromDebate(data)).unwrap().then((response: any)=> {
      const { data } = response;
      if(data) {
        dispatch(setDebateInvitedUsers(data?.invitedUsers ?? []));
      }
      if (indexToRemove !== -1) {
        allInvitedUsers.splice(indexToRemove, 1);
        setTimeout(() => {setSelectedUsers(allInvitedUsers)}, 10);
      }
      if (idxOfUserToRemove !== -1) {
        allUserIds.splice(idxOfUserToRemove, 1);
        setTimeout(() => {setUserIds(allUserIds)}, 10);
      }
      toast(
        (t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">
              {'User removed from debate successfully'}
            </div>
          </Notify>
        ),
        { duration: 2000 }
      );
      setTimeout(() => {
        dispatch(resetUpdateInvitedUsers(null));
      }, 1400);
    }).catch((err: any) => {
        setSelectedUser({});
        toast(
          (t) => (
            <Notify iconError>
              <div className="h6 sm:text-sm">
                {err?.message ?? lt('something_wrong', { ns:'auth' })}
              </div>
            </Notify>
          ),
          { duration: 2000 }
        );
        setTimeout(() => {
          dispatch(resetUpdateInvitedUsers(null));
        }, 1400);
      });
  };

  const handleInviteUser = () => {
    const isUserAlreadySelected = selectedUsers.find((user: any) => user?._id === selectedUser.value);
    if (isEmpty(isUserAlreadySelected)) {
      const users = [...selectedUsers, selectedUser];
      if(size(users) >= 50) {
        const allUserIds = [...userIds];

        const idxOfUserToRemove = allUserIds.findIndex(id=> id === selectedUser?.value);
        if (idxOfUserToRemove !== -1) {
          allUserIds.splice(idxOfUserToRemove, 1);
          setTimeout(() => {setUserIds(allUserIds)}, 10);
        }
        setSelectedUser({});
        toast(
          (t) => (
            <Notify iconError>
              <div className="h6 sm:text-sm">{lt('max_debate_users', { ns: 'translation' })}</div>
            </Notify>
          ),
          { duration: 2400 }
        );
      } else {
        setSelectedUsers(users);
        updateDebateInvitedUsers();
      }
    } else {
      setSelectedUser({});
      toast(
        (t) => (
          <Notify iconError>
            <div className="h6 sm:text-sm">{lt('already_select', { ns: 'translation' })}</div>
          </Notify>
        ),
        { duration: 2400 }
      );
    }
  };

  const updateDebateInvitedUsers = () => {
    const data = {
      debateId: storeDebate.data.id,
      invitedUser: selectedUser.value,
    };
    dispatch(updateInvitedUsers(data))
      .unwrap()
      .then((response: any) => {
        const { data } = response;
        if(data) {
          dispatch(setDebateInvitedUsers(data?.invitedUsers ?? []));
        }
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">
                {lt('invite_updateSuccess', { ns: 'translation' })}
              </div>
            </Notify>
          ),
          { duration: 2000 }
        );
        setSelectedUser({});
        setTimeout(() => {
          dispatch(resetUpdateInvitedUsers(null));
        }, 1400);
      })
      .catch((err: any) => {
        setSelectedUser({});
        toast(
          (t) => (
            <Notify iconError>
              <div className="h6 sm:text-sm">
                {err?.message ?? lt('something_wrong', {ns: 'auth'})}
              </div>
            </Notify>
          ),
          { duration: 2000 }
        );
        setTimeout(() => {
          dispatch(resetUpdateInvitedUsers(null));
        }, 1400);
      });
  };
  const leaveDebate = () => {
    const data = { debateId: storeDebate.data.id };
    dispatch(leaveDebateById(data))
      .unwrap()
      .then((response: any) => {
        const { data } = response;
        if(data) {
          dispatch(setDebateInvitedUsers(data?.invitedUsers ?? []))
        }
        onCancel();
      })
      .catch((err: any) => {
        setSelectedUser({});
        toast(
          (t) => (
            <Notify iconError>
              <div className="h6 sm:text-sm">
                {err?.message ?? lt('something_wrong',{ns:'auth'})}
              </div>
            </Notify>
          ),
          { duration: 2400 }
        );
        setTimeout(() => {
          dispatch(resetLeaveDebateById(null));
        }, 1400);
        onCancel();
      });
  };

  const allowDebateUsersUpdation = () => {
    const isDebateUser = debateInvitedUsers?.find(
      (el: any) => el?._id === user?.data?.id
    );
    if (
      isDebateAdmin &&
      (size(selectedUsers) === 0 || updateDebateUsers.loading)
    ) {
      return true;
    } else if (!isDebateAdmin && (leaveDebateData.loading || !isDebateUser)) {
      return true;
    } else return false;
  };

  return (
    <div>
      <h3 className="h4 mb-5 pr-20 truncate">{storeDebate?.data?.title}</h3>
      <div className="p-4 border border-n-3 dark:border-n-6 rounded-xl mb-4">
        {isDebateAdmin && (
          <>
            <p className="text-sm font-semibold mb-2 block">
              {lt('member_invite', { ns: 'translation' })}
            </p>
            <div className="relative">
              <SerachEmailSelect
                loadOptions={loadOptions}
                onSearchChange={handleSearch}
                selectedUserIds={userIds}
                onUserSelect={(selectedUser: any) => setUserIds([...userIds, selectedUser])}
                setSelectedUser={(selectedUser: any) => setSelectedUser(selectedUser)}
                selectedUser={selectedUser}
              />
              <button
                disabled={isEmpty(selectedUser)|| updateDebateUsers.loading}
                onClick={handleInviteUser}
                className="absolute btn-blue top-1/2 -translate-y-1/2 right-3 !py-1 h-9"
              >
                {lt('invite', {ns: 'translation'})}
              </button>
            </div>
          </>
        )}
        <p className="text-n-4 text-sm opacity-50% mb-3">{lt('debate_members', {ns: 'translation'})}</p>
        <div className="space-y-4 max-h-[calc(100vh-500px)] overflow-auto pr-5 AtScroll">
          {selectedUsers && selectedUsers.map((item: any, idx: number) => {
            return (
              <div className="flex justify-between items-center" key={idx}>
                <div className="flex items-center gap-3">
                  <>
                    {
                      item?.profileUrl ?
                      <figure className="w-10 h-10 rounded-full relative">
                      <Image src={item?.profileUrl ?? PROFILE_URL} fill className="w-full h-full rounded-full" alt="email-profile"/>
                      </figure>:
                      <div className="w-10 h-10 rounded-full text-white bg-primary-1 flex justify-center items-center flex-shrink-0">
                        {item?.userName && item.userName?.charAt(0).toUpperCase()}
                      </div>
                    }
                  </>
                  <div className="flex flex-col">
                    <p className="text-sm font-semibold">{item?.userName}</p>
                    <span className="text-xs text-n-4 !font-mont opacity-[50%]">
                      {item?.email}
                    </span>
                  </div>
                </div>
                {item?._id === storeDebate.data?.user?._id && (
                  <span className="!font-mont ">Organiser</span>
                )}
                {isDebateAdmin && item?._id !== storeDebate.data?.user?._id && (
                  <span
                    onClick={() =>handleRemoveInvite(item)}
                    className="cursor-pointer !font-mont"
                  >
                    <Icon
                      className="w-4 h-4 fill-n-6 dark:fill-n-3 opacity-[50%] hover:opacity-70"
                      name="close"
                    />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex gap-3">
          {
            !isDebateAdmin && (<button
            disabled={allowDebateUsersUpdation()}
            className={`btn ${
              isDebateAdmin ? "btn-blue" : "btn-red"
            }  hover:opacity-90`}
            onClick={isDebateAdmin ? updateDebateInvitedUsers : leaveDebate}
          >
            {lt('leave_debate', {ns:'translation'})}
          </button>)
          }
        </div>
      </div>
    </div>
  );
};

export default InviteUsersModal;
