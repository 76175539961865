import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Icon from "@/components/Icon";
import Files from "./Files";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import {
  resetChatLoadingAndError,
  setCreateChatData,
  setStopGenerateChat,
} from "store/slices/ChatGptSlice";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import UploadDocumentDropdown from "../UploadDocumentDropdown";
import MentionInput from "../MentionInput";
import {
  ABORT_EVENTS,
  CHAT_MODEL,
  CUSTOM_MODELS,
  MAX_INPUT_FIELD_LIMIT,
} from "@/constants/chats";
import {
  stopDebateMessageStream,
  updateDebate as updateDebateAction,
} from "store/actions/debate.actions";
import { updateDebateStream } from "services/debate.fetch.service";
import { DEBATE_MSG_TYPE, lokiChatMention } from "@/constants/debate";
import { useTranslation } from "react-i18next";
import { fetchStream } from "services/chat.fetch.service";
import { v4 as uuidv4 } from "uuid";
import {
  stopMessageStream,
  storeMessageStream,
} from "store/actions/chat.actions";
import { getStoredChatList } from "store/actions/chatList.actions";
import {
  pushNewDebateStreamMsg,
  removeDebateStreamMsg,
  setStopGenerateDebate,
} from "store/slices/DebateSlice";
import { ErrorToast } from "@/constants/toast";
import { BackIcon, SendIcon } from "../SVGs";
import { useColorMode } from "@chakra-ui/color-mode";
import MentionInputComponent from "../MentionInputComponent";
import SignUpModal from "../Modal/SignUpModal";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";

type MessageProps = {
  value: any;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  image?: string;
  document?: any;
  setMessage?: any;
  isRegenerate?: any;
  disabled?: boolean;
  setMessageData?: any;
  selectedChatModel?: any;
  mentionInput?: any;
  isBotQuestion?: any;
  setIsBotQuestion?: any;
  isUpdateMsgBotMentioned?: any;
  reply?: string;
  setReply?: any;
  fromGuest?: string;
  docProcessing?: boolean;
};

let abortController = new AbortController();
const { GENERATE, RE_GENERATE, UPDATE } = ABORT_EVENTS;
const { CHAT_GPT_3, EMBEDED_MODEL, GEMENI_MODEL, COPY_WRITER } = CHAT_MODEL;

const Message = ({
  value,
  onChange,
  placeholder,
  image,
  document,
  setMessage,
  disabled,
  setMessageData,
  selectedChatModel,
  mentionInput,
  isBotQuestion,
  setIsBotQuestion,
  isRegenerate,
  isUpdateMsgBotMentioned,
  reply,
  setReply,
  fromGuest,
  docProcessing,
}: MessageProps) => {
  const stylesButton = "group absolute right-3 bottom-2 w-10 h-10";

  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");

  const transformedProfiles = [
    {
      id: "artist_profile",
      userName: t("Artist"),
    },
    {
      id: "architect_profile",
      userName: t("Architect"),
    },
    {
      id: "biologist_profile",
      userName: t("Biologist"),
    },
    {
      id: "business_profile",
      userName: t("Business Master"),
    },
    {
      id: "chemical_profile",
      userName: t("Chemical Engineer"),
    },
    {
      id: "designer_profile",
      userName: t("Designer"),
    },
    {
      id: "computer_profile",
      userName: t("Computer Scientist"),
    },
    {
      id: "doctor_profile",
      userName: t("Doctor"),
    },
    {
      id: "lawyer_profile",
      userName: t("Lawyer"),
    },
    {
      id: "engineer_profile",
      userName: t("Engineer"),
    },
    {
      id: "historian_profile",
      userName: t("Historian"),
    },
    {
      id: "economist_profile",
      userName: t("Economist"),
    },
    {
      id: "physicist_profile",
      userName: t("Physicist"),
    },
    {
      id: "mathematician_profile",
      userName: t("Mathematician"),
    },
    {
      id: "political_profile",
      userName: t("Political Analyst"),
    },
    {
      id: "philosopher_profile",
      userName: t("Philosopher"),
    },
    {
      id: "psychologist_profile",
      userName: t("Psychologist"),
    },
    {
      id: "theologist_profile",
      userName: t("Theologist"),
    },
    {
      id: "professor_profile",
      userName: t("Professor"),
    },
    {
      id: "sociologist_profile",
      userName: t("Sociologist"),
    },
    {
      id: "mega_lawyer",
      userName: t("mega_lawyer"),
    },
  ];

  const { colorMode, setColorMode } = useColorMode();
  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const userData = useSelector((state: any) => state.authStore);

  const { user } = userData;
  const { pathname, query } = router;
  const {
    stream,
    updateStream,
    createChat,
    selectedChatListItem,
    promptQuestion,
  } = chatData;
  const {
    storeDebate,
    updateDebate,
    stopGenerateDebate,
    debateInvitedUsers,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;

  const { CHAT, CHAT_ID, DEBATE_ID, CHAT_LIST, CHAT_LIST_ID } = ROUTES;

  const [pauseCreateChat, setPauseCreateChat] = useState<any>(false);
  const [selectedMentions, setSelectedMentions] = useState<any>([]);
  const [mentionDescription, setMentionDescription] = useState("");
  const [messagePassCode, setMessagePassCode] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const textareaRef = useRef<any>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [createChat.data]);

  useEffect(() => {
    if (promptQuestion !== "") {
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 1000);
    }
  }, [promptQuestion]);

  useEffect(() => {
    try {
      if (
        chatData.stopGenerateChat?.keyName === GENERATE &&
        chatData.stopGenerateChat?.value
      ) {
        console.log("Called-1");
        setPauseCreateChat(false);
        abortController.abort("Chat Paused with user permission");
        abortController = new AbortController();
        const data = {
          messageId: messagePassCode,
          chatId: createChat.data?.id ?? null,
          isUpdate: false,
        };
        if (!isEmpty(createChat?.data?.id)) {
          dispatch(stopMessageStream(data))
            .unwrap()
            .then((res: any) => {});
        }
      }
    } catch (error) {}
  }, [chatData.stopGenerateChat]);

  useEffect(() => {
    try {
      if (
        stopGenerateDebate?.keyName === GENERATE &&
        stopGenerateDebate?.value &&
        pauseCreateChat
      ) {
        console.log("stopGenerateDebate");
        setPauseCreateChat(false);
        abortController.abort("Chat Paused with user permission");
        abortController = new AbortController();
        const localDebateData = JSON.parse(
          localStorage.getItem("debateData") ?? "{}"
        );

        const data = {
          messageId: localDebateData.messageId,
          debateId: router.query?.id ?? null,
          stopEventType: "isNewMessage",
          responseId: localDebateData.responseId,
        };
        dispatch(stopDebateMessageStream(data))
          .unwrap()
          .then((res: any) => {
            localStorage.removeItem("debateItem");
          });
      }
    } catch (error) {}
  }, [stopGenerateDebate]);

  useEffect(() => {
    if (!isEmpty(createChat.error)) {
      ErrorToast(createChat.error, 3000);

      setTimeout(() => {
        dispatch(resetChatLoadingAndError(null));
      }, 3000);
    }
  }, [createChat]);

  const createChatClick = async (event: any) => {
    event.preventDefault();
    try {
      console.log("yy");
      if (fromGuest) {
        const session = loadDecryptedValue(USER_LOCAL.LOCAL);
        if (session) {
          router.push(ROUTES.CHAT);
        } else {
          setVisible(true);
        }
        return;
      }
      dispatch(setStopGenerateChat({ keyName: "", value: false }));
      const data: any = { message: value?.trim() };
      const  key  = GEMENI_MODEL;
      if (
        key === CHAT_GPT_3 ||
        key === GEMENI_MODEL ||
        key === COPY_WRITER ||
        key === EMBEDED_MODEL ||
        CUSTOM_MODELS.includes(key)
      ) {
        data.chatModel = key;
      } else {
        data.chatModel = CHAT_GPT_3;
      }

      if (
        (pathname === CHAT || pathname === CHAT_LIST) &&
        !isEmpty(createChat.data?.id)
      ) {
        data.chatId = createChat.data?.id;
        data.chatModel = createChat.data?.chatModel;
      } else if (
        query.id &&
        (pathname === CHAT_ID || pathname === CHAT_LIST_ID)
      ) {
        data.chatId = query.id;
        data.chatModel = createChat.data?.chatModel;
      }
      if (isEmpty(createChat.data?.id)) {
        if (pathname === CHAT_LIST) {
          data.chatListId = selectedChatListItem.data.id;
        }
      }

      if (reply && reply != "") {
        data.reply = reply;
      }

      const passCode = uuidv4();
      data.messagePassCode = passCode;
      setMessagePassCode(passCode);
      setMessageData(data?.message);
      console.log("data", data);
      setPauseCreateChat(true);
      setMessage("");
      setReply && setReply("");
      if (createChat?.data?.chatDocUrl) {
        data.chatDocUrl = createChat?.data?.chatDocUrl;
      }

      dispatch(storeMessageStream(data))
        .unwrap()
        .then((res: any) => {
          dispatch(setCreateChatData(res.data));
          data.chatId = res.data.id;
          localStorage.setItem("isUpdate", "false");
          localStorage.setItem("chatId", res.data.id);
          localStorage.setItem("messageId", passCode);
          dispatch(fetchStream(data, abortController.signal));
          fetchStoredChatLists();
        })
        .catch((err: any) => {
          console.log("err", err);
          ErrorToast(err.message);
        });
    } catch (error: any) {
      console.log("err", error);
      ErrorToast(error.message);
    }
  };

  const fetchStoredChatLists = () => {
    if (pathname === CHAT_LIST && size(createChat.data.messages) <= 1) {
      dispatch(getStoredChatList());
    }
  };

  const handleKeyDown = (event: any) => {
    try {
      if (event.key == "Enter" && !event.shiftKey) {
        event.preventDefault();
      }
      if (event.key == "Enter" && !event.shiftKey && size(value?.trim()) > 0) {
        createChatClick(event);
      }
    } catch (error) {}
  };

  const createDebateHandler = (event: any) => {
    event.preventDefault();
    try {
      if (size(mentionDescription?.trim())) {
        console.log("mentionDescription", mentionDescription);
        const mentionedUsersSet = new Set(
          selectedMentions.map((el: any) => el)
        );
        mentionedUsersSet.delete(lokiChatMention._id);
        const mentionedUsers = Array.from(mentionedUsersSet); // Convert the Set back to an array
        const data: any = {
          message: mentionDescription,
          debateId: query.id,
          isBotMentioned: false,
          mentionedUsers: mentionedUsers || [],
          messageId: uuidv4(),
        };
        const isBotMentioned = selectedMentions.find(
          (id: any) => id == lokiChatMention._id
        );

        if (isBotMentioned) {
          data.isBotMentioned = true;
          setIsBotQuestion(true);
        } else setIsBotQuestion(false);

        setMessageData(data?.message);
        const debateMsgObj = {
          messageId: data.messageId,
          sender: { _id: user.data.id },
          message: data.message,
          addedInNotebook: false,
          responseTo: null,
          isBotResponse: false,
          isBotMentioned: data.isBotMentioned,
          type: DEBATE_MSG_TYPE.USER_MESSAGE,
          id: "",
        };
        dispatch(pushNewDebateStreamMsg(debateMsgObj));
        dispatch(updateDebateAction(data))
          .unwrap()
          .then((res: any) => {
            if (data?.isBotMentioned) {
              const passcode = uuidv4();
              setMessagePassCode(passcode);
              const debateDataToSaveOnLocal = {
                message: mentionDescription,
                debateId: query.id,
                messageId: data.messageId,
                responseId: passcode,
              };
              data.messageId = passcode;
              localStorage.setItem(
                "debateData",
                JSON.stringify(debateDataToSaveOnLocal)
              );
              dispatch(
                updateDebateStream(data, abortController.signal, user.data.id)
              );
              setPauseCreateChat(true);
            }
          })
          .catch((err: any) => {
            console.log("message request error", err);
            const payload = { messageId: data.messageId };
            dispatch(removeDebateStreamMsg(payload));
          });
        setMentionDescription("");
        setMessage("");
      }
    } catch (error) {}
  };

  const isChatPage = () => {
    return (
      pathname === CHAT ||
      pathname === CHAT_ID ||
      pathname === CHAT_LIST_ID ||
      pathname === CHAT_LIST ||
      pathname?.includes("/features")
    );
  };

  const onClickHandler = (event: any) => {
    try {
      if (isChatPage() && size(value?.trim()) > 0) {
        createChatClick(event);
      } else if (pathname === DEBATE_ID) {
        createDebateHandler(event);
      }
    } catch (error) {}
  };

  const isDebateUser = debateInvitedUsers?.find(
    (el: any) => el?._id === user?.data?.id
  );
  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;

  const displayMentionInputPlaceholder = () => {
    if (!isDebateUser && !isDebateAdmin && !isDebateArchived && !disabled) {
      return t("leave_debate_text");
    } else if (isDebateArchived) {
      return t("archived_debate_text");
    } else {
      return t("ask_anything") + " ...";
    }
  };

  const pauseDebateGeneration = () => {
    try {
      let keyName;
      if (updateDebate.loading) {
        keyName = GENERATE;
      } else if (updateDebateBotMessage.loading && isRegenerate) {
        keyName = RE_GENERATE;
      } else if (updateDebateMessage.loading) {
        keyName = UPDATE;
      }

      dispatch(setStopGenerateDebate({ keyName: keyName, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateDebate({ keyName: "", value: false }));
      }, 3000);
    } catch (error) {}
  };

  const pauseChatGeneration = () => {
    try {
      let keyName;
      if (stream) {
        keyName = GENERATE;
      } else if (updateStream.loading && isRegenerate) {
        keyName = RE_GENERATE;
      } else if (updateStream.loading && !isRegenerate) {
        keyName = UPDATE;
      }
      dispatch(setStopGenerateChat({ keyName: keyName, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateChat({ keyName: "", value: false }));
      }, 2000);
    } catch (error) {}
  };

  const handlePause = () => {
    if (pathname === DEBATE_ID) {
      pauseDebateGeneration();
    } else {
      pauseChatGeneration();
    }
  };

  const displayHandlePauseBtn = () => {
    if (pathname === DEBATE_ID) {
      if (
        (updateDebateMessage.loading && isUpdateMsgBotMentioned) ||
        updateDebateBotMessage.loading ||
        (updateDebate.loading && isBotQuestion)
      ) {
        return true;
      } else return false;
    } else {
      return stream || updateStream.loading;
    }
  };

  return (
    <>
      {reply && (
        <div className=" w-[85%] ml-10 self-center bg-[#F3F5F7] dark:bg-[#141718] flex px-10 pt-4 rounded-t-xl relative pb-1">
          <div className=" absolute top-3 left-2">
            <BackIcon />
          </div>
          <button
            onClick={() => setReply("")}
            className=" absolute top-3 right-2"
          >
            <Icon name={"close"} className=" dark:stroke-white h-5 w-5" />
          </button>
          <div className="max-h-[4.5em] overflow-hidden">{reply}</div>
        </div>
      )}
      <div
        className={`relative flex gap-2 items-center z-5 px-10 pb-6 ${
          !fromGuest && "before:absolute"
        } before:-top-6 
        before:left-0 before:right-6 before:bottom-1/2 before:bg-gradient-to-b  before:to-n-1  before:from-n-1/0 before:pointer-events-none 2xl:px-6 2xl:pb-5 md:px-4 md:pb-4 dark:before:to-n-6  dark:before:from-n-6/0`}
        style={fromGuest == "chat_with_doc" ? { pointerEvents: "auto" } : {}}
      >
        {docProcessing ? (
          <></>
        ) : (
          <>
            {!mentionInput && (
              <UploadDocumentDropdown
                selectedChatModel={selectedChatModel}
                fromGuest={fromGuest}
              />
            )}
          </>
        )}

        <div
          className="relative w-full z-2 border-2 border-n-3 rounded-xl dark:border-n-5"
          id="step1"
        >
          {(image || document) && <Files image={image} document={document} />}
          <div
            className={`${
              displayHandlePauseBtn() ? "pr-4" : "pr-16"
            } flex items-center min-h-[3.5rem] pl-4 mentionInput relative w-full max-h-[144px]`}
          >
            <>
              {mentionInput ? (
                <>
                  <MentionInput
                    onClickHandler={onClickHandler}
                    setSelectedMentions={setSelectedMentions}
                    setDescription={setMentionDescription}
                    description={mentionDescription}
                    placeholder={displayMentionInputPlaceholder()}
                    disabled={
                      (DEBATE_ID && !isDebateUser && !isDebateAdmin) ||
                      disabled ||
                      isDebateArchived
                    }
                  />
                </>
              ) : (
                <MentionInputComponent
                  textareaRef={textareaRef}
                  value={value}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type here..."
                  disabled={
                    disabled ||
                    fromGuest === "translation" ||
                    (fromGuest &&
                      !createChat?.data?.chatDocUrl &&
                      fromGuest != "profiles")
                  }
                  mentionUsers={transformedProfiles}
                  reply={reply}
                  fromGuest={fromGuest}
                />
              )}
            </>
            {(pathname === DEBATE_ID &&
              ((!isDebateUser && !isDebateAdmin) || isDebateArchived)) ||
            (!value && !mentionDescription) ? (
              ""
            ) : (
              <button
                disabled={false}
                onClick={onClickHandler}
                className={`${stylesButton}  rounded-xl transition-colors `}
              >
                <SendIcon
                  color={colorMode === "dark" ? "#B0B0B0" : "#3e3e3e"}
                />
              </button>
            )}
            {displayHandlePauseBtn() && (
              <div onClick={handlePause} className="flex items-center sm:pt-12">
                <Icon
                  className="w-8 h-8 transition-colors hover:fill-white/80 cursor-pointer dark:fill-white fill-white bg-black rounded-full"
                  name="pause-circle"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SignUpModal
        visible={visible}
        onClose={() => setVisible(false)}
        navigateTo={ROUTES.CHAT}
      />
    </>
  );
};

export default Message;
