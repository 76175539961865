import React, { useState } from "react";
import Modal from "@/components/Modal";
import UploadDocument from "../UploadDocument";
import TranslateDocument from "../TranslateDocument";
import TranslatingDocument from "../TranslatingDocument";
import SuccessFullyTranslated from "../SuccessfullyTranslated";
import toast from "react-hot-toast";
import axios from "axios";
import Config from "../../config/index";
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { isEmpty } from "lodash";
import { addSummaryDocument } from "store/slices/ChatGptSlice";
import { LANGUAGE_CODE } from "@/constants/audio";
import Notify from "../Notify";

type UploadDocumentModalProps = {
  visible: boolean;
  onClose: () => void;
  selectedChatModel?: any;
  setIsLoading?: any;
};

const UploadDocumentModal = ({
  visible,
  onClose,
  selectedChatModel,
  setIsLoading,
}: UploadDocumentModalProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();

  const { pathname, query } = router;

  const chatData = useSelector((state: any) => state.chatGptStore);
  console.log("chatData.createChat.data?.id", chatData.createChat.data?.id);

  const [mtype, setmtype] = useState<string>("summary");
  const [payload, setpayload] = useState<IDocumentTranslatorState>({
    targetLanguage: "",
    file: "",
  });
  const [response, setresponse] = useState<any>({});
  const [translateProgress, settranslateProgress] = useState<number>(0);
  const [curStep, setcurStep] = useState<number>(1);

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep((ps: number) => ps - 1);
      }
    } else {
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  const showToast = (msg: any, err?: boolean) => {
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const initTranslation = async () => {
    console.log("i am in the initTranslation");

    if (!payload.file) {
      showToast("File and Target Language is required.");
      return;
    }
    if (payload.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      changeStepHandler(true);
      return;
    }
    // const chatModel = "gemini-pro";
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("targetLanguage", LANGUAGE_CODE.EN);
      // formData.append('chatModel',chatModel );
      if (pathname === ROUTES.DOCUMENT_PROCESSING) {
        if (!isEmpty(chatData.createChat.data?.id)) {
          // console.log("i am in if checking if chatData.createChat.data?.id is",chatData.createChat.data?.id);
          formData.append("chatId", chatData.createChat.data?.id);
        } else if (!chatData.createChat?.data?.id) {
          console.log("i am in else if checking if there is");
          console.log(selectedChatModel.key);

          formData.append("chatModel", selectedChatModel.key);
        }
      } else if (
        query.id &&
        (pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID)
      ) {
        //@ts-ignore
        formData.append("chatId", router.query.id);
      }
      console.log("formData-->", formData);

      changeStepHandler();
      const res = await axios.post(
        `${Config.baseApiUrl}/document-summary/generate`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            settranslateProgress(Math.ceil(percentCompleted / 1.2));
          },
        }
      );
      if (res?.status == 200) {
        settranslateProgress(100);
        setresponse(res.data);
        changeStepHandler();
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6">Summarize successfully!</div>
            </Notify>
          ),
          { duration: 3000 }
        );
        dispatch(addSummaryDocument(res.data));
        setcurStep(1);
        setIsLoading(true);
        settranslateProgress(0);
        onClose();
      } else {
        showToast("Failed to Summerize.", true);
        changeStepHandler(true);
      }
    } catch (error: any) {
      console.log(error);
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to summerize due to Unknown reason."
      );
      changeStepHandler(true);
    }
  };
  const downloadFile = (url: string) => {
    // url = "https://first1122.blob.core.windows.net/mycontainer/fr-new (1).pdf"
    url = response?.data?.translatedDocumentUrl;
    // console.log(url);
    if (url) {
      var frame = document.createElement("iframe");
      frame.src = url; // your Azure url
      frame.style.display = "none";
      document.body.appendChild(frame);
    } else {
      // toast.error('URL Unavailable.')
      showToast("URL Unavailable.");
    }
  };

  return (
    <div
      className="max-w-[43rem] relative group"
      // classWrap="max-w-[43rem] p-8"
      // classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      // visible={visible}
      // onClose={() => { setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0); onClose(); }}
      // nonClosed={false}
    >
      <button
        className="text-large absolute right-[30px] top-[20px] z-[1000] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        onClick={() => {
          //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
          onClose();
        }}
      >
        ✕
      </button>
      {curStep == 1 && (
        <UploadDocument
          nextClick={changeStepHandler}
          state={payload}
          setState={setpayload}
        />
      )}
      {curStep == 2 && (
        <TranslateDocument
          nextClick={changeStepHandler}
          mtype={mtype}
          state={payload}
          setState={setpayload}
          initTranslation={initTranslation}
        />
      )}
      {curStep == 3 && (
        <TranslatingDocument
          nextClick={changeStepHandler}
          mtype={mtype}
          state={payload}
          setState={setpayload}
          progress={translateProgress}
        />
      )}
      {curStep == 4 && (
        <SuccessFullyTranslated
          nextClick={changeStepHandler}
          mtype={mtype}
          data={response}
          state={payload}
          setState={setpayload}
          downloadFile={downloadFile}
        />
      )}
    </div>
  );
};

export default UploadDocumentModal;
