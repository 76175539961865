import React from "react";
import { Button, Tooltip } from "flowbite-react";
 
interface IProps {
    tooltipContent: any;
    className?:string;
    size?: any;
}
 
const TooltipIndicator = ({ tooltipContent, className, size }: IProps) => {
  return (
    <Tooltip
      content={tooltipContent.map((el: any)=><div>{el?.userName??''}</div> )}
      placement="bottom"
      className={`bg-n-4  text-xs Tooltip max-w-[150px] z-50 ${className}`}
    >
      <Button className="w-9 ss:w-6 ss:h-6 h-9 bg-primary-1 rounded-full text-white -ml-2 md:-ml-3 z-2 relative flex items-center justify-center text-xs ss:text-[8px]">
       <p className="flex gap-[2px] ss:gap-[1px] !font-mont text-xs ss:text-[10px]"><span>+</span> {size}</p>
      </Button>
    </Tooltip>
  );
};
 
export default TooltipIndicator;