import React, { useState, useEffect } from 'react'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import { FileUploader } from "react-drag-drop-files";
import { UploadIcons } from '@/components/LandingPage/Icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    nextClick: Function;
    state: any;
    setState: Function;

}
const UploadDocumentSelectModal = ({nextClick,state,setState}: any) => {
  const { t } = useTranslation('translation');
  const fileTypes = ["application/msword", "msword", "text/plain", "plain", "pdf", "application/pdf", "application/xml", "doc","docs","docx","dox"];
  const [file, setFile] = useState<any>(null);
  useEffect(()=>{
    setState((ps:IDocumentTranslatorState)=>({...ps,file:file}))
  },[file])

  const handleChange = (file: any) => {
    setFile(file);
  };
  
  return (
    <div>
      <div className=' bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5'>
        {/* <label className='relative cursor-pointer border-white border-dashed border w-full h-[288px] rounded-[20px] flex flex-col justify-center items-center'>
          <span className='mb-6 block'><UploadIcons /></span>
          <h2 className='h5 text-white !font-mont mb-1'>Upload Document</h2>
          <p className='text-white'>{file ? `${file?.name}` : "Please click or drag and drop your document to upload"}</p>
           <FileUploader handleChange={handleChange} multiple={false} name="file" types={fileTypes}/>
        </label> */}

        <label className='relative cursor-pointer border-white border-dashed border w-full h-[288px] rounded-[20px] flex flex-col justify-center items-center'>
           <FileUploader handleChange={handleChange} multiple={false} name="file" classes="drag_drop" types={fileTypes}>
            <div className='flex justify-center items-center flex-col w-full h-[288px] focus:!border-transparent focus-within:!outline-none cursor-pointer'>
              <span className='mb-6 block'><UploadIcons/></span>
              <h2 className='h5 text-white !font-mont mb-1'>{t('upload_doc')} {t('exam_data')}</h2>
              <p className='text-white m-auto text-center w-[450px] md:w-[300px] sm:w-[230px] truncate'>{file ? `${file?.name}` : t('doc_dragDrop')}</p>
            </div>
           </FileUploader>
        </label>
      
      </div>
      <button className='btn btn-blue w-full' disabled={!state.file} onClick={nextClick}>{t('next')}</button>
    </div>
  )
}

export default UploadDocumentSelectModal