import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import UploadDocument from "../UploadDocument";
import TranslateDocument from "../TranslateDocument";
import TranslatingDocument from "../TranslatingDocument";
import SuccessFullyTranslated from "../SuccessfullyTranslated";
import toast from "react-hot-toast";
import axios from "axios";
import Config from "../../config/index";
import {
  setresponse,
  settranslateProgress,
  setpayload,
  // setcurStep,
} from "store/slices/TranslationSlice";
import Notify from "../Notify";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";

type UploadDocumentModalProps = {
  visible: boolean;
  onClose: () => void;
  fromGuest?: string
};

const UploadDocumentModal = ({
  visible,
  onClose,
  fromGuest
}: UploadDocumentModalProps) => {
  // const [payload, setpayload] = useState<IDocumentTranslatorState>({
  //   targetLanguage: "",
  //   file: "",
  // });

  const dispatch = useDispatch();

  const { response, translateProgress, payloadData } = useSelector(
    (state: any) => state.translationStore.translations
  );

  // console.log("");

  // const [response, setresponse] = useState<any>({});
  // const [translateProgress, settranslateProgress] = useState<number>(0);
  const [curStep, setcurStep] = useState<number>(1);

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep((ps: number) => ps - 1);
      }
    } else {
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  useEffect(() => {
    if (curStep === 4 && !response) {
      setcurStep(1);
    }
  }, [response, curStep]);

  const showToast = (msg: any, err?: boolean) => {
    const icon = err == true ? "iconError" : "iconCheck";
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>

          {/* <div className="flex justify-center">
          <button
            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
            onClick={() => toast.dismiss(t.id)}
          >
           {'OK'}
          </button>
        </div> */}
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const initTranslation = async () => {
    if (!payloadData.file || !payloadData.targetLanguage) {
      toast.error("File and Target Language is required.");
      return;
    }
    // if (payloadData.file?.size > 10 * 1024 * 1024) {
    //   showToast("File size should be less than 10mb");
    //   changeStepHandler(true);
    //   return;
    // }
    if (payloadData.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      changeStepHandler(true);
      return;
    }
    try {
      const formData = new FormData();
      // console.log("payloadData.targetLanguage",payloadData.targetLanguage);
      
      formData.append("file", payloadData.file);
      formData.append("targetLanguage", payloadData.targetLanguage);
      changeStepHandler();
      const res = await axios.post(
        `${Config.baseApiUrl}/document-translation/store`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(settranslateProgress(Math.ceil(percentCompleted / 1.2)));
          },
        }
      );
      if (res.status == 200) {
        dispatch(settranslateProgress(100));
        dispatch(setresponse(res.data));
        changeStepHandler();
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6">Translated successfully!</div>
            </Notify>
          ),
          { duration: 3000 }
        );
        dispatch(settranslateProgress(0));
      } else {
        toast.error("Failed to Translate.");
        showToast("Failed to Translate.");
        changeStepHandler(true);
      }
    } catch (error: any) {
      //@ts-ignore
      toast.error(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to translate due to Unknown reason."
      );
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to translate due to Unknown reason."
      );
      changeStepHandler(true);
    }
  };

  // const downloadFile = (url: string) => {
  //   url = response?.data?.translatedDocumentUrl;
  //   if (url) {
  //     var frame = document.createElement("iframe");
  //     frame.src = url;
  //     frame.style.display = "none";
  //     document.body.appendChild(frame);
  //     settranslateProgress(0);
  //     setcurStep(1);
  //     onClose();
  //   } else {
  //     toast.error("URL Unavailable.");
  //     showToast("URL Unavailable.");
  //   }
  // };

  const downloadFile = (url: string) => {
    url = response?.data?.translatedDocumentUrl;
    if (url) {
      const customFileName = "your_custom_file_name_given.docx";
      saveAs(
        url,
        `${payloadData.targetLanguage}-translated-${payloadData.file?.name}`
      );

      dispatch(settranslateProgress(0));
      setcurStep(1);
      dispatch(setresponse(null));
      onClose();
    } else {
      toast.error("URL Unavailable.");
      showToast("URL Unavailable.");
    }
  };

  return fromGuest === 'translation' ? (
    <div className=" absolute -top-[28rem] left-[40%] z-50 bg-n-2 rounded-3xl dark:bg-n-7 p-5 " style={{ pointerEvents: "auto" }}>
      {curStep == 1 && (!response?.data?.translatedDocumentUrl) && (
        <UploadDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
        />
      )}
      {/* <UploadingDocument/> will be removed.*/}
      {curStep == 2 && (
        <TranslateDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          initTranslation={initTranslation}
        />
      )}
      {curStep == 3 && (
        <TranslatingDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          progress={translateProgress}
        />
      )}
      {(curStep == 4 || response?.data?.translatedDocumentUrl) &&(
        <SuccessFullyTranslated
          nextClick={changeStepHandler}
          data={response}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          downloadFile={downloadFile}
        />
      )}
    </div>
  ) : (
    <Modal
      classWrap="max-w-[43rem] p-8"
      classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      visible={visible}
      onClose={() => {
        //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
        onClose();
      }}
      // nonClosed={true}
    >
      {curStep == 1 && (
        <UploadDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
        />
      )}
      {/* <UploadingDocument/> will be removed.*/}
      {curStep == 2 && (
        <TranslateDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          initTranslation={initTranslation}
        />
      )}
      {curStep == 3 && (
        <TranslatingDocument
          nextClick={changeStepHandler}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          progress={translateProgress}
        />
      )}
      {curStep == 4 && (
        <SuccessFullyTranslated
          nextClick={changeStepHandler}
          data={response}
          state={payloadData}
          setState={(data: any) => {
            dispatch(setpayload(data));
          }}
          downloadFile={downloadFile}
        />
      )}
    </Modal>
  );
};

export default UploadDocumentModal;
