import React, { useEffect, useState } from "react";
import Field from "@/components/Field";
import Select from "@/components/Select";
import { translate } from "@/mocks/colors";
import { documentListSchema } from "lib/validations/documentList";
import { BiErrorCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import { editChatList, getStoredChatList, storeChatList } from "store/actions/chatList.actions";
import { useTranslation } from 'react-i18next';
import { editDocumentList, getStoredDocumentList, storeDocumentList } from "store/actions/documentList.actions";

type AddDocumentListProps = {
  onCancel?: () => void;
  isFromEdit?: any;
};

const AddDocumentList = ({ onCancel, isFromEdit }: AddDocumentListProps) => {
    const initialErrors = { title: "", color: "" };

    const dispatch: any = useDispatch();
    const { t:lt } = useTranslation(['translation', 'auth']);

    const colors = translate(lt);

    const documentData = useSelector((state: any) => state.translationStore);
    const { storeDocumentListData } = documentData;

  const [name, setName] = useState<string>("");
  const [color, setColor] = useState<any>(colors[1]);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (isFromEdit) {
      setName(isFromEdit?.title);
      let tempColor = colors.find(color => color.color === isFromEdit?.color)
      setColor(tempColor);
    }
  }, [isFromEdit])


  const handleAddDocumentList = async (event: any) => {
    event.preventDefault();

    let fieldErrors;
    let data: any = { title: name, color: color.color };

    await documentListSchema
      .validate({ title: name, color: color?.color }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error?.inner?.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err?.path]: err?.message,
          };
        }, {});
        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      if (isFromEdit) {
        data.id = isFromEdit.id;
        dispatch(editDocumentList(data)).unwrap().then(() => {
          dispatch(getStoredDocumentList());
        });
      } else {
        dispatch(storeDocumentList(data)).unwrap().then(() => {
          dispatch(getStoredDocumentList());
        });
      }
    }
  };

  const isValidAddToDocumentList = () => {
    return size(name) === 0 || size(color?.color) === 0 || storeDocumentListData?.loading;
  }
  // useEffect(() => {
  
  //   console.log("dispatch(getStoredDocumentList());");
    
  //   dispatch(getStoredDocumentList());
  // }, [])
  return (
    <div className="p-12 lg:px-8 md:pt-6 md:px-5 md:pb-6">
      <div className="mb-8 h4 !font-mont font-extrabold text-n-6 dark:text-n-3">{isFromEdit ? 'Edit Document List' : 'Add Document List'}</div>
      <div className="relative z-10 flex mb-8 md:block">
        <div className="grow">
        <Field
          className=" mr-3 md:mr-0 md:mb-3"
          label={lt('name', {ns: "auth"})}
          placeholder={lt('name', {ns: "auth"})}
          icon="chat-1"
          value={name}
          onChange={(e: any) => {setName(e.target.value); setErrors(initialErrors)}}
          required
        />
        {errors?.title && (
          <div className="text-red-500 text-xs mt-2 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors.title}</div>
        )}
        </div>
        <Select
          label={lt('color', {ns: "translation"})}
          className="shrink-0 min-w-[14.5rem]"
          items={colors}
          value={color}
          onChange={(val: any)=>{setColor(val); setErrors(initialErrors)}}
        />
        {errors?.color && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors?.color}</div>
        )}
      </div>
      <div className="flex justify-end">
        <button className="btn-stroke-light mr-3 text-n-6 dark:text-n-3" disabled={storeDocumentListData?.loading} onClick={onCancel}>
          {lt('cancel_btn', {ns: "translation"})}
        </button>
        <button className="btn-blue" disabled={isValidAddToDocumentList()} onClick={handleAddDocumentList}>{lt(isFromEdit ? 'save' : 'add_list', { ns: "translation" })}</button>
      </div>
    </div>
  );
};

export default AddDocumentList;
