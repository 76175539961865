import { Fragment , useState} from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { DocumentTranslation, ChatWithDoc, SummarizeDocument, Transcription } from "../LandingPage/Icons";
import UploadDocumentModal from "../UploadDocumentModal";
import TranscriptionModel from "../UploadDocumentModal/TranscriptionModel";
import SummaryModal from '../SummaryModal/index'
import Modal from "../Modal";
import DocumentSelectModal from "../Modal/DocumentSelectModal";
import { useTranslation } from "react-i18next";
import CreateTranscriptionModal from "../Modal/CreateTranscriptionModal";
import ChatWithDocModal from "../ChatWithDocModal";
import { AudioSummaryIcon, SummarizeUrl } from "../SVGs";
import { useColorMode } from "@chakra-ui/color-mode";
import AudioSummaryModal from "../AudioSummaryModal";
import SummarizeUrlModal from "../SummarizeUrlModal";
import SignUpModal from "../Modal/SignUpModal";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import { ROUTES } from "@/constants/routes";
import { useRouter } from "next/router";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}



export default function UploadDocumentDropdown(props: any) {
  const { selectedChatModel } = props;
  const { t } = useTranslation('translation');
  const { colorMode } = useColorMode();
  const router = useRouter();
  const isDarkMode = colorMode === "dark";
  const [visibleModal, setVisibleModal] = useState<boolean>(false);  
  const [docChatModal, setDocChatModal] = useState<boolean>(false);
  const [audioSummarizer, setAudioSummarizer] = useState<boolean>(false);
  const [summarizeVisible, setsummarizeVisible] = useState<any>(null);
  const [documentSelect, setDocumentSelect] = useState(false);
  const [visibleTranscriptionModal, setVisibleTranscriptionModal] = useState<boolean>(false);  
  const [visible, setVisible] = useState(false);

  const [createTranscription, setCreateTranscription] = useState(false);


  const Data = [
    {
      title: t('doc_translation'),
      icon:<DocumentTranslation/>,
      onClick: () => setVisibleModal(true)
    },
    {
      title: t('chat_with_doc'),
      icon: <ChatWithDoc />,
      onClick: () => setDocChatModal(true)
    },
    {
      title: t('create_transcription'),
      icon:<Transcription/>,
      onClick: () => setVisibleTranscriptionModal(true)
    },
    {
      title: t('audio_summarizer'),
      icon: <AudioSummaryIcon fill={isDarkMode ? '#FEFEFE' : 'black'} />,
      onClick: () => setAudioSummarizer(true)
    },
    {
      title: t('summarize_doc'),
      icon: <SummarizeDocument/>,
      onClick: () => setsummarizeVisible('doc')
    },
    {
      title: t('summarize_url'),
      icon: <SummarizeUrl />,
      onClick: () => setsummarizeVisible('url')
    },
    // {
    //   title: t('exam_me'),
    //   icon: <ExamMe/>,
    //   onClick: () => setDocumentSelect(true)
    // },
  ];

  const onButtonClick = (item:any)=>{
   
   console.log("checking chat with your doc");
    
   if(((props?.fromGuest === 'chat_with_doc' && item.title !== t('chat_with_doc'))) || props?.fromGuest === 'profiles') {
      const session = loadDecryptedValue(USER_LOCAL.LOCAL);
      if(session){
        router.push(ROUTES.CHAT)
      }else{
        setVisible(true);
      }
     }else{
      item.onClick()
     }
  }
  return (
    <Menu
      as="div"
      className=""
    >
      <Menu.Button
        className={`relative rounded-full w-8 h-8 flex items-center justify-center ${!props?.fromGuest && 'dark:bg-n-1'} bg-[#A6A9AA] hover:opacity-75`} id="step2"
      >
        <AiOutlinePlus className={`text-white ${!props?.fromGuest &&  'dark:text-black'}`}/>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="bottom-24 w-[268px] shadow-md p-1 !px-1 rounded-lg bg-white dark:bg-black absolute z-10"
        >
          
            {Data && Data.map((item, i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <span
                  onClick={()=>onButtonClick(item)}
                    key={item?.title}
                    className={classNames(active ? "" : "", "text-base cursor-pointer dark:text-white !font-mont text-black flex items-center gap-3 p-5 bg-transparent dark:hover:bg-n-6 hover:bg-[#F3F5F7] rounded-lg")}
                  >
                    <span className="">{item?.icon}</span>
                    {item?.title}
                  </span>
                )}
              </Menu.Item>
            ))}
     
        </Menu.Items>
      </Transition>

      <UploadDocumentModal visible={visibleModal} onClose={() => setVisibleModal(false)} fromGuest={props?.fromGuest} />
      <ChatWithDocModal visible={docChatModal} onClose={(v:any) => setDocChatModal(v)} fromGuest={props?.fromGuest} />
      <AudioSummaryModal visible={audioSummarizer} onClose={() => setAudioSummarizer(false)} />
      <TranscriptionModel visible={visibleTranscriptionModal} onClose={() => setVisibleTranscriptionModal(false)} />

      <SummaryModal visible={summarizeVisible === 'doc'} onClose={() => setsummarizeVisible(null)} selectedChatModel={selectedChatModel} />
      <SummarizeUrlModal visible={summarizeVisible === 'url'} onClose={() => setsummarizeVisible(null)} />
      <CreateTranscriptionModal createTranscription={createTranscription} onClose={()=> setCreateTranscription(false)} />
      <SignUpModal visible={visible} onClose={() => setVisible(false)}/>
      <Modal
        className=""
        classOverlay=""
        classWrap="md:min-h-screen-ios !p-9  !py-6 max-w-[688px] relative"
        classButtonClose="absolute top-6 right-6 w-6 h-6 rounded-full bg-n-2 md:right-5 dark:bg-n-4 dark:fill-white dark:hover:fill-white"
        visible={documentSelect}
        onClose={() => {setDocumentSelect(false) }}
      >
        <DocumentSelectModal onCancel={() => {setDocumentSelect(false)}} selectedChatModel={selectedChatModel}/>
      </Modal>
      
    </Menu>
  );
}