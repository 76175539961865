import React, { useState, useEffect } from 'react'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import { useTranslation } from 'react-i18next';
import Select from '@/components/Select';
import { Language, SpeedIcon, VoiceIcon } from '@/components/SVGs';
import { gendersTranslate, speeds } from '@/constants/audio';
import Icon from '@/components/Icon';
import { useSelector } from 'react-redux';

interface IProps {
    onNext: any;
}
const AudioSettings = ({ onNext }: IProps) => {
    const { t } = useTranslation('translation');
    const { LanguageDetails: languages } = useSelector((state: any) => state.AudioSummarySlice);
    const genders = [
        {
            id: "0",
            title: t('female'),
            // title: "Female",
            value: "Female",
        },
        {
            id: "1",
            title: t('male'),
            // title: 'Male',
            value: 'Male',
        },
    ];
    const [gender, setGender] = useState<any>(genders[0]);
    const [speed, setSpeed] = useState<any>(speeds[1]);
    const [voice, setVoice] = useState<any>(languages[0]?.gender?.female[0]);
    const [language, setLanguage] = useState<any>(languages[0]);

    const handleGenderChange = (value: any) => {
        try {
            setGender({ ...value });
            if (value.value.toLowerCase() == "male") {
                setVoice(language.gender.male[0])
            } else if (value.value.toLowerCase() == "female") {
                setVoice(language.gender.female[0])
            }
        } catch (error) {

        }
    };

    const nextHandler = () => {
        const data = {
            gender, speed, voice, language
        }
        onNext(data);
    }

    return (
        <div className='mt-3 p-2'>
            <Select
                className="w-full "
                classButton="bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6"
                IconComponent={() => <Language />}
                items={languages}
                value={language}
                onChange={setLanguage}
                placeholder={t('language')}
                arrowColor={'black'}
            />
            <Select
                className="w-full mt-3"
                classButton="bg-[#F0FAEB] dark:bg-[#F0FAEB] dark: text-n-6 "
                items={speeds}
                value={speed}
                onChange={setSpeed}
                PreIcon={() => <SpeedIcon />}
                placeholder={t('speed')}
                title={t('speed')}
                arrowColor={'black'}
            />
            <div className="flex justify-between flex-wrap mr-1.5 2xl:mt-2 mt-4 rounded-md shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)] bg-[#F1EFFE] md:w-full md:mr-0 dark:bg-[#F1EFFE] dark:shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15),inset_0_0_0_0.0625rem_rgba(254,254,254,.1)]">
                <Select
                    classButton="shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:shadow-none dark:text-n-6"
                    title={t('voice')}
                    items={genders}
                    value={gender}
                    onChange={handleGenderChange}
                    PreIcon={() => <VoiceIcon />}
                    arrowColor={'black'}
                />
                {language?.gender && language?.gender[gender?.value?.toLowerCase()]?.length > 0 ? (<Select
                    classButton="shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:text-n-6 dark:shadow-none w-40"
                    icon="volume"
                    className=""
                    items={language?.gender[gender?.value?.toLowerCase()] ?? []}
                    value={voice}
                    // disabled={!edit && !showGenerateAudioButton()}
                    onChange={setVoice}
                    voice
                    arrowColor={'black'}
                />) : (language &&
                    <>
                        <Icon name={'volume'} className=" self-center" />
                        <button
                            className="btn-white btn-small hover:!bg-transparent md:w-[calc(50%-0.5rem)] flex items-center self-center mr-3"
                        >
                            <span className='!font-mont'>{language?.gender[gender?.value?.toLowerCase()][0]?.title ?? ""}</span>

                        </button>
                    </>
                )}
            </div>

            <button className='btn btn-blue w-full mt-5' onClick={nextHandler}>{t('next')}</button>
        </div>

    )
}

export default AudioSettings