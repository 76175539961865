import { useEffect, useState } from "react";
import Icon from "@/components/Icon";
import Field from "@/components/Field";
import { useDispatch, useSelector } from "react-redux";
import { requestOtp } from "store/actions/auth.actions";
import { isEmpty } from "lodash";
import { requestOtpSchema } from "lib/validations/authentications";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

type ForgotPasswordProps = {
  setForgot?: any;
  setForgetSelectedIndex?: any;
  setSelectedIndex?: any;
};

const ForgotPassword = ({
  setForgot,
  setForgetSelectedIndex,
  setSelectedIndex,
}: ForgotPasswordProps) => {
  const initialErrors = { email: "" };
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('auth');


  const userData = useSelector((state: any) => state.authStore);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (!isEmpty(userData.requestOtp.data)) {
      setForgetSelectedIndex(1);
    }
  }, [userData.requestOtp]);

  const validateForgotEmail = () => {
    if (userData.requestOtp.loading || isEmpty(email)) {
      return true;
    }
  };

  const handleForgotClick = async (event: any) => {
    event.preventDefault();

    let fieldErrors;

    await requestOtpSchema
      .validate({ email }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      dispatch(requestOtp({ email }));
    }
  };

  return (
    <>
      <button
        className="group flex items-center mb-3 h4 m-auto"
        id="headlessui-portal-root"
      >
        {lt('forgot_password')}
      </button>
      <p className="text-center lg max-w-[370px] m-auto mb-[3.5rem]">
        {lt('reset_code')}
      </p>
      <form action="" onSubmit={() => console.log("Submit")}>
        <div className="mb-6">
        <Field
          classInput="dark:bg-n-7 dark:border-n-7"
          placeholder={lt('type_email')}
          icon="email"
          type="email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrors(initialErrors);
          }}
          required
        />
        {errors.email && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors.email}</div>
        )}
        </div>
        <button
          disabled={validateForgotEmail()}
          className="btn-blue btn-large w-full mb-6"
          type="submit"
          onClick={handleForgotClick}
        >
          {lt('generate_otp')}
        </button>
      </form>
      <div
        className="group absolute cursor-pointer top-4 left-8 flex justify-center items-center w-10 h-10 rounded-full text-0 transition-colors"
        onClick={() => {
          setSelectedIndex(1);
          setForgot(false);
        }}
      >
        <Icon
          className="mr-4 transition-transform group-hover:-translate-x-1 dark:fill-n-1"
          name="arrow-prev"
        />
      </div>
    </>
  );
};

export default ForgotPassword;
