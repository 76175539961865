import React from 'react'
import { UploadIcons } from '../LandingPage/Icons'
import UploadingProgressbar from '../UploadingProgressbar'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
interface IProps{
  nextClick:Function
  state:any;
  setState:Function;
  progress:number;
  mtype?:string
}
const UploadingDocument = ({nextClick,state,setState,mtype,progress}:IProps) => {
  return (
    <div>
        <div className=' bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5'>
            <label className=' border-white border-dashed border w-[592px] h-[288px] rounded-[20px] flex flex-col justify-center items-center'>
             <UploadingProgressbar completed={progress}/>
                <h2 className='h5 text-white !font-mont mb-1'>{progress<98 ? 'Uploading' : 'Processing'}</h2>
                <p className='text-white'>{state?.file?.name ?? 'Unknown'}</p>
            </label>
        </div>
        {/* <button className='btn btn-accent w-full bg-[#D84C10]'>Cancel Uploading</button> */}
    <button disabled className='btn btn-accent w-full bg-[#D84C10]' onClick={()=>{nextClick()}}>Cancel Uploading </button>

    </div>
  )
}

export default UploadingDocument