import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { useColorMode } from "@chakra-ui/color-mode";
import Logo from "@/components/Logo";
import SignIn from "./SignIn";
import CreateAccount from "./CreateAccount";
import ForgotPassword from "./ForgotPassword";
import SendLink from "./SendLink";
import ResetPassword from "./ResetPassword";
import Success from "./Success";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleAuth from "@/templates/SignInPage/Form/GoogleAuth";
import AppleAuth from "@/templates/SignInPage/Form/AppleAuth";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { resetPasswordError, resetUserData } from "store/slices/AuthSlice";
import RefferalCode from "./RefferalCode";
import UserProfile from "./UserProfile";
import OnBoarding from "./OnBoarding";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { detect } from "detect-browser";
import { verifyRefferalCode as verifyReferralCodeAction } from "store/actions/auth.actions";
import ThankYouCreatingAccount from "./ThankYouCreatingAccount";

type FormProps = { setShowIcon?: any, navigateTo?: string };

const Form = ({ setShowIcon, navigateTo }: FormProps) => {
  const dispatch: any = useDispatch();
  const { colorMode } = useColorMode();
  const { t: lt } = useTranslation("auth");
  const router = useRouter();
  const browser = detect();

  const isLightMode = colorMode === "light";

  const userData = useSelector((state: any) => state.authStore);
  const tabNav = [lt("create_account"), lt("signIn_btn")];
  const {
    user,
    registerUser,
    requestOtp,
    verifyOtp,
    verifyRefferalCode,
    refferalVerificationFailed,
  } = userData;

  const [forgot, setForgot] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [forgetSelectedIndex, setForgetSelectedIndex] = useState(0);
  const [signUp, setSignUp] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [useronBoardMail, setUseronBoardMail] = useState()

  const verifyRefferalCodeDirectly = (refferalCode: any) => {
    let data: any = {
      email: verifyOtp?.data?.email || userEmail || user?.data?.email,
      appleProviderId: user?.data?.appleProviderId,
      gmailProviderId: user?.data?.gmailProviderId,
      referralCode: refferalCode,
    };

    data.os = browser?.os;
    data.browserName = browser?.name;
    data.browserVersion = browser?.version;

    dispatch(verifyReferralCodeAction(data));
  };


  useEffect(() => {
    if (!isEmpty(registerUser.error)) {
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6 sm:text-sm">{registerUser.error}</div>
          </Notify>
        ),
        { duration: 3000 }
      );

      setTimeout(() => {
        dispatch(resetPasswordError(null));
      }, 1000);
    }
  }, [registerUser]);

  useEffect(() => {
    if (!isEmpty(user.error)) {
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6 sm:text-sm">{user.error}</div>
          </Notify>
        ),
        { duration: 3000 }
      );
      if (user.error === "Your account is not verified yet.") {
        setForgetSelectedIndex(1);
        setForgot(true);
        setSignUp(true);
      }
      setTimeout(() => {
        dispatch(resetPasswordError(null));
      }, 1000);
    }
    // if (user.error === lt("enter_referralCode")) {
    //   if (router.query?.referralCode) {
    //     setForgetSelectedIndex(1);
    //     verifyRefferalCodeDirectly(router.query?.referralCode);
    //   } else {
    //     //router.push("/referral-code")
    //     // setForgetSelectedIndex(4);
    //     setForgot(true);
    //     setSignUp(false);
    //   }
    // }

    if (user && user.data && !user.data.isReferralVerified) {
      if (router.query?.referralCode) {
        setForgetSelectedIndex(1);
        // verifyRefferalCodeDirectly(router.query?.referralCode);
      } else {
        // setForgot(true);
        //router.push("/referral-code")
        // setForgetSelectedIndex(4);
        setSignUp(false);
      }
    } else if (user && user.data && !user.data.userName) {
      setForgetSelectedIndex(5);
      setForgot(true);
      setSignUp(false);

      toast(
        (t) => (
          <Notify iconCheck>
            <div className="mr-6 ml-3 h6 sm:text-sm">
              {lt("enter_userName")}
            </div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
  }, [user]);

  useEffect(() => {
    if (user && user.data && !user.data.isReferralVerified) {
      dispatch(resetUserData());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(verifyOtp.error)) {
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6 sm:text-sm">{verifyOtp.error}</div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
    if (isEmpty(verifyOtp.error) && !isEmpty(verifyOtp.data)) {
      setForgetSelectedIndex(2);
      toast(
        (t) => (
          <Notify iconCheck>
            <div className="mr-6 ml-3 h6 sm:text-sm">{lt("otp_varify")}</div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }

    if (isEmpty(verifyOtp.error) && !isEmpty(verifyOtp.data) && signUp) {
      if (router.query?.referralCode) {
        setForgetSelectedIndex(1);
        // verifyRefferalCodeDirectly(router.query?.referralCode);
      } else {
    //  setTimeout(() => { }, 6000);
    //  router.push("/referral-code")
    router.push("/thank-you-creating-account")
     setForgetSelectedIndex(4); //when we signup success flow
      }
    }
  }, [verifyOtp]);

  useEffect(() => {
    if (!isEmpty(verifyRefferalCode.error)) {
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6 sm:text-sm">
              {verifyRefferalCode.error}
            </div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
  }, [verifyRefferalCode]);

  useEffect(() => {
    if (!isEmpty(requestOtp.error)) {
      toast(
        (t) => (
          <Notify iconDelete>
            <div className="mr-6 ml-3 h6 sm:text-sm">{requestOtp.error}</div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
    if (isEmpty(requestOtp.error) && !isEmpty(requestOtp.data)) {
      toast(
        (t) => (
          <Notify iconCheck>
            <div className="mr-6 ml-3 h6 sm:text-sm">{lt("otp_send")} </div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
  }, [requestOtp]);

  useEffect(() => {
    if (refferalVerificationFailed == true) {
      //router.push("/referral-code")
      // setForgetSelectedIndex(4);
    }
  }, [refferalVerificationFailed]);

  return (
    <div className="w-full max-w-[34rem] mx-auto flex flex-col">
      <Logo
        className="max-w-[11.875rem] mx-auto mb-7 grow"
        dark={isLightMode}
      />
      <div className="grow">
        {forgot ? (
          <div className="">
            {forgetSelectedIndex === 0 && (
              <ForgotPassword
                setForgot={setForgot}
                setForgetSelectedIndex={setForgetSelectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            )}
            {forgetSelectedIndex === 4 && (
             <SendLink
             setForgot={setForgot}
             setSignUp={setSignUp}
             signUp={signUp}
             setForgetSelectedIndex={setForgetSelectedIndex}
             setSelectedIndex={setSelectedIndex}
             userEmail={userEmail}
           />
            )}

            {forgetSelectedIndex === 5 && <UserProfile userEmail={userEmail} />}
            {forgetSelectedIndex === 1 && (
              <SendLink
                setForgot={setForgot}
                setSignUp={setSignUp}
                signUp={signUp}
                setForgetSelectedIndex={setForgetSelectedIndex}
                setSelectedIndex={setSelectedIndex}
                userEmail={userEmail}
              />
            )}
            {forgetSelectedIndex === 2 && (
              <ResetPassword setForgetSelectedIndex={setForgetSelectedIndex} />
            )}
            {forgetSelectedIndex === 3 && (
              <Success
                dark={isLightMode}
                setForgot={setForgot}
                setForgetSelectedIndex={setForgetSelectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            )}
            {forgetSelectedIndex === 6 && (
              <OnBoarding
                setForgot={setForgot}
                setForgetSelectedIndex={setForgetSelectedIndex}
                setSelectedIndex={setSelectedIndex}
                userEmail = {useronBoardMail}
              />
            )}
          </div>
        ) : (
          <>
            <div className="mb-8">
              {selectedIndex == 0 ? (
                <h4 className="h4 text-center mb-2">
                  {lt("create_yourAccount")}
                </h4>
              ) : (
                <h4 className="h4 text-center mb-2">
                  {lt("logIn_yourAccount")}
                </h4>
              )}
              <p className="text-center body1S">{lt("easy_free")}</p>
            </div>
            <Tab.Group
              defaultIndex={0}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <Tab.List className="flex mb-8 p-1 bg-n-2 rounded-xl dark:bg-n-7">
                {tabNav.map((button, index) => (
                  <Tab
                    className="basis-1/2 h-10 rounded-[0.625rem] base2 font-semibold text-n-4 transition-colors outline-none hover:text-n-7 ui-selected:bg-n-1 ui-selected:text-n-7 ui-selected:shadow-[0_0.125rem_0.125rem_rgba(0,0,0,0.07),inset_0_0.25rem_0.125rem_#FFFFFF] tap-highlight-color dark:hover:text-n-1 dark:ui-selected:bg-n-6 dark:ui-selected:text-n-1 dark:ui-selected:shadow-[0_0.125rem_0.125rem_rgba(0,0,0,0.07),inset_0_0.0625rem_0.125rem_rgba(255,255,255,0.02)]"
                    key={index}
                  >
                    {button}
                  </Tab>
                ))}
              </Tab.List>
              <GoogleOAuthProvider clientId="187336532962-nlraap9tp921l4kqd5ma3uqkumiei8ca.apps.googleusercontent.com">
                <GoogleAuth />
              </GoogleOAuthProvider>
              <AppleAuth dark={isLightMode} />

              <div className="flex items-center my-4">
                <span className="grow h-0.25 bg-n-4/50 !font-mont"></span>
                <span className="shrink-0 mx-5 text-n-4/50 !font-mont ">{lt("or")}</span>
                <span className="grow h-0.25 bg-n-4/50"></span>
              </div>
              <Tab.Panels className="">
                <Tab.Panel>
                  <CreateAccount
                    setSignUp={setSignUp}
                    setForgetSelectedIndex={setForgetSelectedIndex}
                    setForgot={setForgot}
                    navigateTo={navigateTo}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <SignIn
                    onClick={() => {
                      setForgot(true);
                      setForgetSelectedIndex(0);
                      setSignUp(false);
                    }}
                    setUserEmail={(email: any) => setUserEmail(email)}
                    setSelectedIndex={setSelectedIndex}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
