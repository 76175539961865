import React, { useState, useEffect } from "react";
import Icon from "@/components/Icon";
import Field from "@/components/Field";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordSchema } from "lib/validations/authentications";
import { resetPassword } from "store/actions/auth.actions";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { BsEye } from "react-icons/bs";
import { IoEyeOffOutline } from "react-icons/io5";

type ResetPasswordProps = {
  setForgot?: any;
  setForgetSelectedIndex?: any;
};

const ResetPassword = ({
  setForgot,
  setForgetSelectedIndex,
}: ResetPasswordProps) => {
  const initialErrors = { newPassword: "", confirmNewPassword: "" };

  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('auth');

  const userData = useSelector((state: any) => state.authStore);
  const { resetPassword: resetPasswordData, verifyOtp: verifyOtpData } = userData;

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [errors, setErrors] = useState(initialErrors);
  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);


  useEffect(() => {
    if (isEmpty(resetPasswordData.error) && !isEmpty(resetPasswordData.data)) {
      setForgetSelectedIndex(3);
    }
    if (!isEmpty(resetPasswordData.error) && isEmpty(resetPasswordData.data)) {
      toast((t) => (
        <Notify iconDelete>
          <div className="mr-6 ml-3 h6 sm:text-sm">{resetPasswordData.error}</div>
        </Notify>
      ),{duration: 3000});
    }
  }, [resetPasswordData]);

  const handleResetPassword = async (event: any) => {
    event.preventDefault();

    const data: any = { newPassword, confirmNewPassword };

    let fieldErrors;

    await resetPasswordSchema
      .validate({ newPassword, confirmNewPassword }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      
      data.email = verifyOtpData.data.email;
      data.otp = verifyOtpData.data.otp;

      dispatch(resetPassword(data));
    }
  };

  const isAllowToResetPassword = () => {
    if (
      resetPasswordData.loading ||
      isEmpty(newPassword) ||
      isEmpty(confirmNewPassword)
    ) {
      return true;
    }
  };

  return (
    <>
      <p className=" mb-3 h4 text-center" id="headlessui-portal-root">
        {lt('forgot_password')}
      </p>
      <p className="text-center text-lg max-w-[370px] m-auto mb-8">
        {lt('create_password')}
      </p>
      <form action="">
        <div className="mb-4 relative">
        <Field
          classInput="dark:bg-n-7 dark:border-n-7"
          placeholder={lt('new_password')}
          icon="lock"
          type={eye?"text":"password"}
          value={newPassword}
          onChange={(e: any) => {
            setNewPassword(e.target.value);
            setErrors(initialErrors);
          }}
          required
        />
        {errors.newPassword && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors.newPassword}</div>
        )}
         <span className="absolute top-1/2 -translate-y-1/2 right-3 !font-mont">
        {
          eye?
        <BsEye className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye(false)}}/>
         :
        <IoEyeOffOutline className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye(true)}}/>

        }
      </span>
        </div>
        <div className="mb-8 relative">
          <Field
            classInput="dark:bg-n-7 dark:border-n-7"
            placeholder={lt('confirm_password')}
            icon="lock"
            type={eye1?"text":"password"}
            value={confirmNewPassword}
            onChange={(e: any) => {
              setConfirmNewPassword(e.target.value);
              setErrors(initialErrors);
            }}
            required
          />
        {errors.confirmNewPassword && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            <BiErrorCircle className="text-base"/> {errors.confirmNewPassword}
          </div>
        )}
         <span className="absolute top-1/2 -translate-y-1/2 right-3 !font-mont">
        {
          eye1?
        <BsEye className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye1(false)}}/>
         :
        <IoEyeOffOutline className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye1(true)}}/>

        }
      </span>
        </div>
      </form>
      <button
        className="btn-blue btn-large w-full mb-6"
        type="submit"
        disabled={isAllowToResetPassword()}
        onClick={handleResetPassword}
      >
       {lt('reset_password')}
      </button>
      <div
        className="group absolute cursor-pointer top-4 left-8 flex justify-center items-center w-10 h-10 rounded-full text-0 transition-colors"
        onClick={() => {
          setForgetSelectedIndex(1);
        }}
      >
        <Icon
          className="mr-4 transition-transform group-hover:-translate-x-1 dark:fill-n-1"
          name="arrow-prev"
        />
      </div>
    </>
  );
};

export default ResetPassword;
