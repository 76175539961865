import React from 'react'
import SelectSubjectSelect from './SelectSubjectSelect';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


type SelectSubjectModal = {
    onCancel?: () => void;
    setexamData:any;
    examData:any;
    initExamMeResponse:Function;
    examLoading:boolean;
  };
const SelectSubjectModal = ({ onCancel,examData,setexamData,initExamMeResponse,examLoading}: SelectSubjectModal) => {
  const { t } = useTranslation('translation');
  return (
    <div>
        <h6 className='text-base font-semibold !font-mont mb-5'>{t('select_subject')}</h6>
        <SelectSubjectSelect examData={examData} setexamData={setexamData} examLoading={examLoading}/>
        <button disabled={!examData?.domain || !examData?.subject || examLoading} onClick={()=>{
          if(!examData?.domain || !examData?.subject){
            toast.error(t('select_validSub'))
          }else{
            initExamMeResponse('subject')
          }
        }} className='btn btn-blue w-full mt-8'>{t('take_exam')}</button>
    </div>
  )
}

export default SelectSubjectModal