import React, { useState, useEffect } from "react";
import { DocumentUpload } from '../LandingPage/Icons'
import Icon from '../Icon'
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import LanguageSelect from "../LanguageSelect";
import languageData from '../../constants/language'
import LanguagesSearchSelect from "../LanguagesSearchSelect";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
interface IProps {
  nextClick: Function
  state: IDocumentTranslatorState;
  setState: Function;
  initTranslation: Function;
  mtype?: string;
}
const TranslateDocument = ({ nextClick, state, setState, initTranslation, mtype }: IProps) => {
  const [selected, setSelected] = useState("");
  const { t } = useTranslation('translation');

  useEffect(()=>{
    //@ts-ignore
    // console.log();
    console.log("its un define its for language search",selected?.value);
  },[selected])

  //@ts-ignore
  useEffect(() => { setState({ ...state, targetLanguage: selected?.value?.toString()?.toLowerCase() }) }, [selected])
  return (
    <div>
      <h1 className='font-semibold !font-mont font-base mb-5'>{mtype == 'summary' ? 'Summarize Document' : t('translation_lang_select')}</h1>
      <div className='p-3 border-2 border-[#F3F5F7] dark:border-n-4 w-full flex justify-between items-center rounded-[20px] mb-8'>
        <div className='flex items-center gap-6'>
          <span className='w-16 h-16 flex justify-center !font-mont items-center bg-[#E5F2FE] rounded-lg'>
            <DocumentUpload />
          </span>
          <div>
            <p className='font-bold w-[400px] md:w-[200px] sm:w-[120px] truncate'>{state?.file?.name}</p>
            <p className='text-xs'>{(Number(state?.file?.size) / 1000000).toFixed(2)} MB</p>
            {/* <span className='text-xs'><b>{Math.ceil(Number(state.file?.size) / 7)} words</b></span> */}
          </div>

        </div>
        <span onClick={() => { nextClick(true) }} ><Icon className="w-5 h-5 cursor-pointer" fill='#909292' name="close" /></span>
      </div>
      {mtype != 'summary' && <>
        <p className='font-base font-semibold mb-2'>{t('translation_lang_select_2')}</p>
        <span className='text-xs text-[#999B9C] mb-2 !font-mont block'>{t('translation_lang_select_3')}</span>
        <div className="mb-8 relative">
          {/* <LanguageSelect
          Data={languageData}
          selected={selected}
          setSelected={setSelected}
          placeholder="Select Language"
        /> */}
          <LanguagesSearchSelect
            langData={languageData}
            selected={selected}
            setSelected={setSelected}
            placeholder="Select Language"
          />
          <span className="absolute top-1/2 -translate-y-1/2 right-3 !font-mont">
            <IoIosArrowDown />
          </span>
        </div>
      </>}
      <button className='btn btn-blue w-full' disabled={mtype == 'summary' ? false : (!state.file || !state.targetLanguage)} onClick={() => { initTranslation() }} >{mtype == 'summary' ? 'Summarize Document' : 'Translate Document'}</button>
    </div>
  )
}

export default TranslateDocument