import Field from "@/components/Field";
import { detect } from "detect-browser";
import { size } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateUserName } from "store/actions/auth.actions";

type UserProfileProps = {
  userEmail: any;
};

const UserProfile = ({ userEmail }: UserProfileProps) => {
  const userData = useSelector((state: any) => state.authStore);

  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");
  const { email, updateUserName: updatingUserName } = userData;
  const browser = detect();

  const [userName, setUserName] = useState("");
  const handleConfirmCode = (event: any) => {
    event.preventDefault();
    let data: any = { email: userEmail || email?.data, userName: userName };

    data.os = browser?.os;
    data.browserName = browser?.name;
    data.browserVersion = browser?.version;

    dispatch(updateUserName(data));
  };

  const isValidUserName = () => {
    if (userName) {
      return true;
    }
  };

  const handleFieldChange = (value: string) => {
    setUserName(value);
  };
  return (
    <>
      <button
        className="group flex items-center mb-20 h4 m-auto"
        id="headlessui-portal-root"
      >
        {t("user_name")}
      </button>
      <form action="" onSubmit={() => console.log("Submit")}>
        <div className="mb-6">
          <label className="font-semibold text-base text-[#425466] dark:text-n-5 !font-mont mb-3 block">
            Enter the {t("user_name")} to login.
          </label>
          <input
            className="grow w-full h-13 px-3.5 bg-n-2 border-2 border-n-2 rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 dark:bg-n-6 dark:border-n-6 dark:text-n-3"
            placeholder={t("enter_userName")}
            value={userName}
            onChange={(e: any) => setUserName(e.target.value)}
          />
        </div>
        {updatingUserName && updatingUserName.loading ? (
          <button
            className="btn-blue btn-large w-full mb-6"
            type="button"
            disabled={true}
          >
            {t("submit_btn")}
          </button>
        ) : (
          <button
            className="btn-blue btn-large w-full mb-6"
            type="submit"
            disabled={userName && userName.length > 0 ? false : true}
            onClick={handleConfirmCode}
          >
            {t("submit_btn")}
          </button>
        )}
      </form>
    </>
  );
};

export default UserProfile;
