
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import { t } from "i18next";


  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }
export default function SelectSubjectSelect({examData,setexamData,examLoading}:any) {
    const [selected, setSelected] = useState<any>();
    useEffect(()=>{
      console.log(selected, selected?.title," ===> Selected!");
      setexamData((ps:any)=>({...ps,subject:selected?.title,domain:'Latest'}))
    },[selected])

    const data = [
      {
        title: t('math'),
        color:"#46494A"
      },
      {
        title: t('architect'),
        color:"#8E55EA"
      },
      {
        title: t('science'),
        color:"#8C6584"
      },
      {
        title: t('arts_media'),
        color:"#FB692A"
      },
      {
        title: t('coding'),
        color:"#3E90F0"
      },
        
      ];
    
  return (
    <Listbox as="div" value={selected} onChange={setSelected} disabled={examLoading}>
      <div className="relative mt-1">
        <Listbox.Button className="relative bg-n-2  dark:bg-n-5 w-full h-13 flex justify-start items-center px-4 rounded-xl">
        <span
            className={`block truncate text-secondary !font-mont`}
            title={selected?.title || t('select_subject')}
          >
            {selected?.title || t('select_subject')}
          </span>
            <span className="absolute !font-mont top-1/2 -translate-y-1/2 right-4"><IoIosArrowDown /></span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`absolute right-0 z-10 mt-1 w-full overflow-auto rounded-lg p-4 space-y-4 bg-n-2  dark:bg-n-5 text-xl focus:outline-none shadow-xl`}>
          
            {data && data.map((item: any, Idx: any) => {
              return (
                  <Listbox.Option
                    key={Idx}
                    className={({ active }: any) =>`text-sm font-medium !font-mont  hover:opacity-70 cursor-pointer ${
                        active ? "" : ""
                      }`
                    }
                    value={item}
                  >
                    {/* <div className="w-3 h-3 rounded-sm" style={{background:item.color}}></div> */}

                    {item?.title}
                  </Listbox.Option>
              );
            })}
          
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}



