import React, { useEffect, useState } from "react";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { enablePageScroll, clearQueueScrollLocks } from "scroll-lock";
import Head from "next/head";
import { useMediaQuery } from "react-responsive";
import LeftSidebar from "@/components/LeftSidebar";
import RightSidebar from "@/components/RightSidebar";
import Icon from "@/components/Icon";
import Burger from "./Burger";
import GoalManagementSideBar from "../GoalManagementSideBar";
import GoalDetails from "../GoalDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddGoalRightSidebar,
  setAddGoalSide,
  setAddGoalSidebar,
  setGoalEdit,
  setSelectedGoal,
} from "store/slices/GoalSlice";
import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import { SOCKET_OPTIONS, SOCKET_URL } from "@/config/socketConfig";
import { io } from "socket.io-client";
import { setReferringUser, setUserSubscription } from "store/slices/AuthSlice";
import { getUserDetails } from "store/actions/audio.actions";
import {
  pushIntoCreateChat,
  pushIntoUpdateChat,
} from "store/slices/ChatGptSlice";
import { isEmpty } from "lodash";
import { languageActions } from "store/actions/language.actions";
import { useTranslation } from "react-i18next";
import OfflineAlert from "../OfflineAlert";
// import ProductTour from "../ProductTour";

type LayoutProps = {
  smallSidebar?: boolean;
  documentProcessing?: boolean;
  hideRightSidebar?: boolean;
  backUrl?: string;
  children: React.ReactNode;
  toggle?: any;
  setToggle?: any;
  showCross?: any;
  goalsidebar?: any;
  edit?: any;
  setEdit?: any;
  selectedItem?: any;
  setSelectedItem?: any;
  addGoalSide?: any;
  setaddGoalSide?: any;
  isMobile?: any;
};

const socket = io(SOCKET_URL, SOCKET_OPTIONS);

const Layout = ({
  smallSidebar,
  hideRightSidebar,
  backUrl,
  children,
  toggle,
  setToggle,
  showCross,
  goalsidebar,
  isMobile,
  documentProcessing,
}: LayoutProps) => {
  const dispatch: any = useDispatch();

  const { t, i18n } = useTranslation("translation");
  const goalData = useSelector((state: any) => state.goalStore);
  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);

  const { user } = userData;
  const { goalState } = goalData;
  const { stream, createChat, updateStream } = chatData;

  const [visibleSidebar, setVisibleSidebar] = useState<any>(
    smallSidebar || false
  );
  const isDesktop = useMediaQuery({ query: "(max-width: 1300px)" });

  const handleClickOverlay = () => {
    setVisibleSidebar(true);
    dispatch(setAddGoalRightSidebar(false));
    dispatch(setAddGoalSidebar(false));
    clearQueueScrollLocks();
    enablePageScroll();
  };

  // useEffect(() => {
  //   if(navigator?.language?.includes('es') || navigator?.language?.includes('ES')){
  //     handleLanguageChange({
  //       img: 'https://lokichatdev.blob.core.windows.net/languages/lang/spanish.svg',
  //       title: t('spanish'),
  //       locale: 'es'
  //     })
  //   }
  // }, [])

  useEffect(() => {
    setVisibleSidebar(smallSidebar || isDesktop);
  }, [isDesktop, smallSidebar]);

  const handleSubscribedReferral = (payload: any) => {
    const referringUser = payload.referringUser;
    dispatch(setReferringUser(referringUser));
  };

  useEffect(() => {
    try {
      dispatch(getUserDetails())
        .unwrap()
        .then((res: any) => {
          const updatedUser = { ...user.data, ...res.data };
          dispatch(setUserSubscription(updatedUser));
        });
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      socket.on(
        `${SOCKET_EVENT_TYPES.REFERRAL_COMPLETED}_${user?.data?.id}`,
        handleSubscribedReferral
      );
      return () => {
        socket.off(
          `${SOCKET_EVENT_TYPES.REFERRAL_COMPLETED}_${user?.data?.id}`,
          handleSubscribedReferral
        );
      };
    } catch (error) {}
  }, []);

  const handleSocketEvent = (payload: any) => {
    try {
      const newMessage = payload?.message;
      if (isEmpty(createChat.data?.id) && !stream && !isEmpty(newMessage)) {
        dispatch(pushIntoCreateChat(newMessage));
      } else if (
        !isEmpty(createChat.data) &&
        !stream &&
        createChat.data?.id === newMessage.id
      ) {
        dispatch(pushIntoCreateChat(newMessage));
      }
    } catch (error) {
      console.log("chatErrr", error);
    }
  };

  const handleUpdateSocketEvent = (payload: any) => {
    try {
      const updateMessage = payload?.message;
      console.log("updateMessage", updateMessage);
      if (
        !isEmpty(createChat.data) &&
        !updateStream.loading &&
        createChat.data?.id === updateMessage.id
      ) {
        dispatch(pushIntoUpdateChat(updateMessage));
      }
    } catch (error) {}
  };

  useEffect(() => {
    socket.on(
      `${SOCKET_EVENT_TYPES.CREATE_STREAM_COMPLETION}_${user?.data?.id}`,
      handleSocketEvent
    );

    return () => {
      socket.off(
        `${SOCKET_EVENT_TYPES.CREATE_STREAM_COMPLETION}_${user?.data?.id}`,
        handleSocketEvent
      );
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    socket.on(
      `${SOCKET_EVENT_TYPES.UPDATE_STREAM_COMPLETION}_${user?.data?.id}`,
      handleUpdateSocketEvent
    );

    return () => {
      socket.off(
        `${SOCKET_EVENT_TYPES.UPDATE_STREAM_COMPLETION}_${user?.data?.id}`,
        handleUpdateSocketEvent
      );
    };
  }, [handleUpdateSocketEvent]);

  return (
    <>
      <Head>
        <title>Loki </title>
      </Head>
      {/* {user && (
        !user?.data?.hasSeenTour && <ProductTour />
      )} */}
      <div
        className={`pr-6 bg-n-7 md:p-0 md:bg-n-1 dark:md:bg-n-6 md:overflow-hidden
         ${
           visibleSidebar
             ? `pl-24 ${!isMobile ? "md:pl-16" : "ml-0"} `
             : smallSidebar
             ? "pl-24 md:pl-16"
             : "pl-80 3xl:pl-24 md:pl-16"
         }`}
      >
        <OfflineAlert />
        {!isMobile && (
          <LeftSidebar
            value={visibleSidebar}
            setValue={setVisibleSidebar}
            visibleRightSidebar={goalState.addGoalRightSideBar}
            visibleGoalSideBar={goalState.addGoalSideBar}
            smallSidebar={smallSidebar}
          />
        )}
        <div
          className={`flex md:py-0 duration-500 rightsectionheight bg-white dark:bg-n-6 rounded-[1.25rem] my-[3rem] no-scrollbar md:mt-[2px]  ${
            hideRightSidebar ? "" : "h-screen h-screen-ios overflow-auto"
          }`}
        >
          <div
            className={`relative flex grow max-w-full bg-n-1  md:rounded-none dark:bg-n-6 rounded-[1.25rem] ${
              !hideRightSidebar
                ? documentProcessing
                  ? "pr-[41.875rem] 2xl:pr-[24rem] 4xl:pr-[31.875rem]  lg:pr-0 duration-500"
                  : "pr-[31.875rem] 2xl:pr-[25rem] lg:pr-0 duration-500"
                : goalState?.edit && goalState.goalsidebar
                ? "pr-[31.875rem] 2xl:pr-[25rem] lg:pr-0 duration-500"
                : "pr-0 duration-500"
            }`}
          >
            <div
              className={`relative flex flex-col grow max-w-full ${
                (!hideRightSidebar || goalsidebar) && ""
              }`}
            >
              {(!hideRightSidebar || goalsidebar) && (
                <Burger
                  className={`${!visibleSidebar && "md:hidden"}`}
                  visibleRightSidebar={goalState.addGoalRightSideBar}
                  onClick={() => {
                    dispatch(setGoalEdit(true));
                    dispatch(setAddGoalSidebar(!goalState.addGoalSideBar));
                    dispatch(
                      setAddGoalRightSidebar(!goalState.addGoalRightSideBar)
                    );
                  }}
                />
              )}
              {hideRightSidebar && showCross && (
                <Link
                  className="absolute top-6 right-16 flex justify-center items-center w-10 h-10 border-2 border-n-4/25 rounded-full text-0 transition-colors hover:border-transparent hover:bg-n-4/25"
                  href={backUrl || "/"}
                >
                  <Icon className="fill-n-4" name="close" />
                </Link>
              )}
              {children}
            </div>
            {/* ///////////////////////Right sidebar part //////////////// */}
            {!hideRightSidebar && (
              <RightSidebar
                className={`${
                  !visibleSidebar &&
                  " md:before:absolute md:before:z-30 md:before:inset-0"
                }`}
                visible={goalState.addGoalRightSideBar}
                setToggle={setToggle}
                toggle={toggle}
              />
            )}
            {goalsidebar && (
              <>
                {goalState.addGoalSide ? (
                  <GoalDetails
                    visible={goalState.addGoalSideBar}
                    addGoalSide={goalState.addGoalSide}
                    edit={goalState.edit}
                    setEdit={(value: any) => dispatch(setGoalEdit(value))}
                    setaddGoalSide={(value: any) =>
                      dispatch(setAddGoalSide(value))
                    }
                    className={`${
                      !visibleSidebar &&
                      " md:before:absolute md:before:z-30 md:before:inset-0"
                    }`}
                    selectedItem={goalState.selectedItem}
                  />
                ) : (
                  <GoalManagementSideBar
                    edit={goalState?.edit}
                    setEdit={(value: any) => dispatch(setGoalEdit(value))}
                    setSelectedItem={(value: any) =>
                      dispatch(setSelectedGoal(value))
                    }
                    selectedItem={goalState?.selectedItem}
                    className={`${
                      !visibleSidebar &&
                      " md:before:absolute md:before:z-30 md:before:inset-0"
                    }`}
                    visible={goalState?.addGoalSideBar}
                    setaddGoalSide={(value: any) =>
                      dispatch(setAddGoalSide(value))
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={twMerge(
            `fixed inset-0 z-10 bg-n-7/80 invisible opacity-0 md:hidden  ${
              (!visibleSidebar && smallSidebar) ||
              ((goalState?.addGoalRightSideBar || goalState?.addGoalSideBar) &&
                "visible lg:opacity-100")
            }`
          )}
          onClick={handleClickOverlay}
        />
      </div>
    </>
  );
};

export default Layout;
