import Image from "next/image";

type IllustrationProp = {
  img?: any;
  height?: number;
  width?: number;
  className?: string;
};

export const Illustration = ({ img, height, width, className }: IllustrationProp) => {
  return (
    <div className="py-[32px] sm:py-[6px]">
      <div className="container flex justify-center">
        <figure
          className={`${className}`}
        >
          <Image
            // @ts-ignore
            src={img}
            width={width}
            height={height}
            placeholder="blur"
            // blurDataURL={img}
            className="w-full h-full rounded-2xl object-cover"
            alt="banner"
          />
        </figure>
      </div>
    </div>
  );
};
