export const markdownToHtml = (markdown: string) => {
    // Convert headings
    markdown = markdown.replace(/^###### (.*$)/gim, '<h6>$1</h6>');
    markdown = markdown.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
    markdown = markdown.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
    markdown = markdown.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    markdown = markdown.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    markdown = markdown.replace(/^# (.*$)/gim, '<h1>$1</h1>');

    // Convert bold and italic
    markdown = markdown.replace(/\*\*(.*)\*\*/gim, '<b>$1</b>');
    markdown = markdown.replace(/\*(.*)\*/gim, '<i>$1</i>');

    // Convert blockquotes
    markdown = markdown.replace(/^\> (.*)$/gm, '<blockquote class="border-l-4 pl-4">$1</blockquote>');

    // Convert fenced code blocks
    markdown = markdown.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

    // Convert inline code
    markdown = markdown.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Convert ordered lists (treat as block)
    markdown = markdown.replace(/(^\d+\..+$(\r?\n)*)+/gm, (match) => {
        const items = match.trim().split('\n').map(item => item.replace(/^\d+\. /, '<li>') + '</li>').join('');
        return `<ol class="list-decimal pl-10">${items}</ol>`;
    });

    // Convert unordered lists (treat as block)
    markdown = markdown.replace(/(^\* .+$(\r?\n)*)+/gm, (match) => {
        const items = match.trim().split('\n').map(item => item.replace(/^\* /, '<li>') + '</li>').join('');
        return `<ul class="list-disc pl-10">${items}</ul>`;
    });

    // Convert new lines to <br> only outside lists and code blocks
    markdown = markdown.replace(/(<\/(ul|ol|blockquote|pre)>)/g, '$1\n'); // Add new lines after block elements
    markdown = markdown.replace(/(?<!<\/(ul|ol|li|blockquote|pre|code)>)\n/g, '<br/>'); // Replace new lines with <br> outside lists and code blocks

    return markdown.replaceAll('</li></li>', "</li>").trim(); // Remove leading/trailing whitespace
};

export const removeStartingAndEndingTags = (html: any) => {
    // Remove the starting tag if it exists
    html = html.replace(/^<[^>]+>/, '');
    // Remove the ending tag if it exists
    html = html.replace(/<\/[^>]+>$/, '');
    console.log('mmm ', html);

    return html;
};

export const htmlToPlainText = (html: any) => {
    // Create a new div element
    const tempDiv = document.createElement('div');

    // Set the HTML content
    tempDiv.innerHTML = html;

    // Extract and return the text content
    return tempDiv.textContent || tempDiv.innerText || '';
};