import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { DocumentTranslation, ExamMe, SummarizeDocument } from '@/components/LandingPage/Icons'
import { useTranslation } from 'react-i18next';

const DocumentOptionSelect=({setType,setSelectedExamType}:any) =>{
  const { t } = useTranslation('translation');
  let [plan, setPlan] = useState('startup')

  const data=[
    {
        title: t('about_subject'),
        icon:<DocumentTranslation/>,
        type:'subject'
    },
    {
        title: t('about_chat'),
        icon:<SummarizeDocument/>,
        type:'chat'
    },
    {
        title: t('about_doc'),
        icon:<ExamMe/>,
        type:'document'
    },
]

  return (
    <RadioGroup value={plan} onChange={setPlan}>
        <div className='DocumentSelect'>
        {
        data && data.map((item,i)=>(
      <RadioGroup.Option value={item.title} key={i}>
        {({ checked }) => (
            <div className={`${checked? "border-primary-1":"border-n-26 dark:border-n-6"} w-full p-4 duration-1000 border hover:border-primary-1 dark:hover:border-primary-1 rounded-2xl flex justify-center flex-col items-center`} 
            onClick={()=>{setType(i)}}
            >
               <div className={`${checked? "bg-primary-1 duration-1000":"bg-primary-5 duration-1000 dark:bg-n-5"} w-full h-20  rounded-2xl flex justify-center items-center mb-3`}>
                <span className={`${checked?"text-white ":"text-primary-1"} !font-mont`}>{item.icon}</span>
                </div> 
          <span className="text-sm font-semibold !font-mont whitespace-nowrap">{item.title}</span>
          </div>
        )}
      </RadioGroup.Option>
        ))
     }
       </div>
    </RadioGroup>
  )
}
export default DocumentOptionSelect