import React, { useState } from "react";
import Modal from "@/components/Modal";
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import UploadAudioDocument from "./UploadAudioDocument";
import UploadingDocument from "@/components/UploadingDocumentModal";
import GenerateTranscription from "./GenerateTranscription";


type CreateTranscriptionModalProps = {
    createTranscription: boolean;
  onClose: () => void;
};

const CreateTranscriptionModal = ({
    createTranscription,
  onClose,
}: CreateTranscriptionModalProps) => {
    const [payload, setpayload] = useState<IDocumentTranslatorState>({
        targetLanguage: "",
        file: "",
      });
  
  const [response, setresponse] = useState<any>({});
  const [translateProgress, settranslateProgress] = useState<number>(0);
  const [curStep, setcurStep] = useState<number>(1);

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep((ps: number) => ps - 1);
      }
    } else {
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  return (
    <Modal
      classWrap="max-w-[43rem] p-8"
      classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      visible={createTranscription}
      onClose={() => { setcurStep(1);  onClose(); }}
      nonClosed={true}
    >
      {curStep == 1 && <UploadAudioDocument nextClick={changeStepHandler} state={payload} setState={setpayload} />}
       {/* <UploadingDocument nextClick={changeStepHandler} state={payload} setState={setpayload} progress={0}/> */}
       {curStep == 2 && <GenerateTranscription nextClick={changeStepHandler} state={payload} setState={setpayload}/>}
    </Modal>
  );
};

export default CreateTranscriptionModal;
