import { ROUTES } from "@/constants/routes";
import { USER_LOCAL } from "@/constants/user";
import { encryptAndSave, loadDecryptedValue } from "lib/encryption";
import { clearLogoutStorage, getUserBrowserInfo, getUserIP } from "lib/user";
import config from "../config";
import { setUpdateStreamError } from "store/slices/ChatGptSlice";
import { IRefreshToken, ISession } from "interfaces/auth.interface";
import axios from "axios";
import Config from "../config/index";
import {
    pushNewDebateStreamMsg,
    removeDebateStreamMsg,
    updateDebateMsgContent,
    setUpdateDebateStreamLoading,
    setUpdateDebateStreamText,
    setDebateBotMsgLoading,
    setDebateBotMsgError,
    setDebateUpdateStreamMsgError,
    setUpdateDebateStreamMessageText,
    resetUpdateDebateStreamMessageText,
    setDebateUpdateStreamMsgLoading,
    setRegenerateDebateStreamText
  } from "store/slices/DebateSlice";
import { DEBATE_MSG_TYPE } from "@/constants/debate";

export const updateDebateStream = (data: any, signal: any, userId: string) => async (dispatch: any) => {
  try {
    console.log('Im inside updateDebateStream');
    dispatch(setUpdateDebateStreamLoading(true));
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Transfer-Encoding", "chunked");
    headers.append('Accept', 'application/json, text/plain, */*');

    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    if (!sessions) {
      clearLogoutStorage();
      localStorage.clear();
      window.location.href = ROUTES.SIGNIN;
    }

    headers.append('Authorization', `Bearer ${sessions.token}`);
    const streamUrl = `${config.baseApiUrl}/debate/update-stream`;
    const msgDataObject = {
      messageId: data.messageId,
      sender: {_id: userId},
      message: "",
      addedInNotebook: false,
      responseTo: null,
      isBotResponse: true,
      isBotMentioned: false,
      type: DEBATE_MSG_TYPE.USER_MESSAGE,
      id: "",
      createdAt: "",
      updatedAt: ""
    };
    dispatch(pushNewDebateStreamMsg(msgDataObject));
    let response: any = await fetch(streamUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      signal: signal
    });
    if (!response.ok) {
      console.error(`Request failed with status: ${response.status} - ${response.statusText}`);
      if (response.status === 405) {
        await handleTokenExpiry();
        headers.delete('Authorization');
        const updatedSessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        headers.append('Authorization', `Bearer ${updatedSessions.token}`);

        response = await fetch(streamUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
          signal: signal
        });
      } else {
        const errorData = await response.json();
        console.log('errorData', errorData);
        const payload = { messageId: data.messageId };
        dispatch(removeDebateStreamMsg(payload));
        dispatch(setUpdateDebateStreamLoading(false));
        dispatch(setUpdateStreamError(errorData.message ?? "Stream service error"));
        return;
      }
    }
    const reader = await response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        dispatch(setUpdateDebateStreamLoading(false));
        break;
      }
      try {
        let chunks: any = new TextDecoder("utf-8").decode(value).toString();
        console.log('debate chunk', chunks);
        const payload = {
          chunks: chunks ?? "",
          messageId: data.messageId
        }
        dispatch(setUpdateDebateStreamText(payload));
      } catch (error) {
        console.log("Error json parse", error);
      }
    }
  } catch (err: any) {
    console.log("fetch error", err);
    if (err.name === "AbortError") {
      console.log("Request aborted");
    dispatch(setUpdateDebateStreamLoading(false));
      return;
    } else {
      const payload = { messageId: data.messageId };
      dispatch(removeDebateStreamMsg(payload));
    }
    dispatch(setUpdateDebateStreamLoading(false));
  }
};

export const regenerateDebateStream = (data: any, signal: any, previousContent: string) => async (dispatch: any) => {
  try {
    const payload = { text: "", messageId: data.messageId };

    dispatch(updateDebateMsgContent(payload));
    dispatch(setDebateBotMsgLoading(true));

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Transfer-Encoding", "chunked");
    headers.append('Accept', 'application/json, text/plain, */*');

    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    if (!sessions) {
      clearLogoutStorage();
      localStorage.clear();
      window.location.href = ROUTES.SIGNIN;
    }

    headers.append('Authorization', `Bearer ${sessions.token}`);
    const streamUrl = `${config.baseApiUrl}/debate/regenerate-stream`;

    let response: any = await fetch(streamUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      signal: signal
    });
    if (!response.ok) {
      console.error(`Request failed with status: ${response.status} - ${response.statusText}`);
      if (response.status === 405) {
        await handleTokenExpiry();
        headers.delete('Authorization');
        const updatedSessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        headers.append('Authorization', `Bearer ${updatedSessions.token}`);

        response = await fetch(streamUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
          signal: signal
        });
      } else {
        const errorData = await response.json();
        console.log('errorData', errorData);
        const payload = { text: previousContent, messageId: data.messageId };

        dispatch(updateDebateMsgContent(payload));
        dispatch(setDebateBotMsgLoading(false));
        dispatch(setDebateBotMsgError(errorData.message ?? "Stream service error"));
        return;
      }
    }
    const reader = await response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        dispatch(setDebateBotMsgLoading(false));
        break;
      }
      try {
        let chunks: any = new TextDecoder("utf-8").decode(value).toString();
        console.log('chunks', chunks);
        const payload = {
          chunks: chunks ?? "",
          messageId: data.messageId
        }
        dispatch(setRegenerateDebateStreamText(payload));
      } catch (error) {
        console.log("Error json parse", error);
      }
    }
  } catch (err: any) {
    console.log("fetch error", err);
    if (err.name === "AbortError") {
      console.log("Request aborted");
    dispatch(setUpdateDebateStreamLoading(false));
      return;
    } else {
      const payload = { text: previousContent, messageId: data.messageId };
      dispatch(updateDebateMsgContent(payload));
    }
    dispatch(setUpdateDebateStreamLoading(false));
  }
};

export const updateDebateStreamMessage = (data: any, signal: any, previousContent: string, updatedMessage: string, debateStore: any, userId: string) => async (dispatch: any) => {
  try {
    console.log('Im inside updateDebateStream', );
    const payload = { text: updatedMessage, messageId: data.messageId };
    dispatch(updateDebateMsgContent(payload));
    dispatch(setDebateUpdateStreamMsgLoading(true));

    const { storeDebate } = debateStore;
    const msgIdxToUpdate = storeDebate.data.messages.findIndex((msg: any) => msg.responseTo == data.messageId);
    console.log('msgIdxToUpdate--1', msgIdxToUpdate);
    if(msgIdxToUpdate === -1) {
      const msgDataObject = {
        messageId: null,
        sender: {_id: userId},
        message: "",
        addedInNotebook: false,
        responseTo: data.messageId,
        isBotResponse: true,
        isBotMentioned: false,
        type: DEBATE_MSG_TYPE.USER_MESSAGE,
        id: "",
        createdAt: "",
        updatedAt: ""
      };
      dispatch(pushNewDebateStreamMsg(msgDataObject));
    } else {
      const payload = {
        chunks: "",
        messageId: data.messageId
      }
      dispatch(resetUpdateDebateStreamMessageText(payload));
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Transfer-Encoding", "chunked");
    headers.append('Accept', 'application/json, text/plain, */*');

    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    if (!sessions) {
      clearLogoutStorage();
      localStorage.clear();
      window.location.href = ROUTES.SIGNIN;
    }

    headers.append('Authorization', `Bearer ${sessions.token}`);
    const streamUrl = `${config.baseApiUrl}/debate/update-stream-message`;

    let response: any = await fetch(streamUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      signal: signal
    });
    if (!response.ok) {
      console.error(`Request failed with status: ${response.status} - ${response.statusText}`);
      if (response.status === 405) {
        await handleTokenExpiry();
        headers.delete('Authorization');
        const updatedSessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        headers.append('Authorization', `Bearer ${updatedSessions.token}`);

        response = await fetch(streamUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
          signal: signal
        });
      } else {
        const errorData = await response.json();
        console.log('errorData', errorData);
        const payload = { text: previousContent, messageId: data.messageId };

        dispatch(updateDebateMsgContent(payload));
        dispatch(setDebateUpdateStreamMsgLoading(false));
        dispatch(setDebateUpdateStreamMsgError(errorData.message ?? "Stream service error"));
        return;
      }
    }
    const reader = await response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        dispatch(setDebateUpdateStreamMsgLoading(false));
        break;
      }
      try {
        let chunks: any = new TextDecoder("utf-8").decode(value).toString();
        const payload = {
          chunks: chunks ?? "",
          messageId: data.messageId
        }
        dispatch(setUpdateDebateStreamMessageText(payload));
      } catch (error) {
        console.log("Error json parse", error);
      }
    }
  } catch (err: any) {
    console.log("fetch error", err);
    if (err.name === "AbortError") {
      console.log("Request aborted");
      dispatch(setDebateUpdateStreamMsgLoading(false));
      return;
    } else {
      const payload = { messageId: data.messageId };
      dispatch(removeDebateStreamMsg(payload));
    }
    dispatch(setUpdateDebateStreamLoading(false));
  }
};

const handleTokenExpiry = async () => {
  return new Promise(async function (resolve, reject) {
    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    const browserInfo = getUserBrowserInfo();
    const ipAddress = await getUserIP();

    const refreshTokenPayload: IRefreshToken | any = {
      refreshToken: sessions.refreshToken,
      userId: sessions.userId,
      ipAddress: ipAddress,
      os: browserInfo.os,
      browserName: browserInfo.browserName,
      browserVersion: browserInfo.browserVersion,
    };
    axios
      .post(Config.baseApiUrl + "/auth/refresh-token", refreshTokenPayload)
      .then(async ({ data }) => {
        const updatedResponse: ISession = {
          refreshToken: data.data.refreshToken,
          userId: sessions.userId,
          token: data.data.token,
          role: sessions.role,
          loginType:sessions?.role
        };
        console.log("updated", data);
        resolve(encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(updatedResponse)));
      })
      .catch((err) => {
        console.log("erere", err);
        if (window.location.pathname !== ROUTES.SIGNIN) {
          clearLogoutStorage();
          localStorage.clear();
          window.location.href = ROUTES.SIGNIN;
        }
        reject(err);
      });
  });
};
