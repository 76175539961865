import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { UploadIcons } from "@/components/LandingPage/Icons";
import { notebookService } from "services/notebook.service";
import { useDispatch, useSelector } from "react-redux";
import { addToLiveNotebook } from "store/actions/notebook.actions";
import { useTranslation } from "react-i18next";
import { ImSpinner9 } from "react-icons/im";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import { useRouter } from "next/router";
import Loader from "@/components/Loader";
import { PDFViewer } from "@/components/Modal/PdfPreview";
import Modal from "..";
import { setFileText } from "store/slices/TextToSpeechSlice";
import { pdfjs } from "react-pdf";
import mammoth from "mammoth"; // For Word (.docx) files

import PageRangeSelector from "./RangeInput";
import Checkbox from "@/components/Checkbox";
import {
  setresponse,
  settranslateProgress,
  setpayload,
  setOutputFileName,
  setOriginalFileText,
  setTranslatedFileText,
  setOriginalFileLanguage,
  setTranslatedFileLanguage,
  // setcurStep,
} from "store/slices/TranslationSlice";
import LanguagesSearchSelect from "@/components/LanguagesSearchSelect";
import languageData from "@/constants/language";
import axios from "axios";
import Config from "../../../config/index";
import { saveAs } from "file-saver";
import { getTextLanguage } from "lib/utils";
import MoveChatDropdown from "@/components/Search/MoveChatDropdown";
import { WriteIcon, TranslateIcon2, DownloadIcon } from "@/components/SVGs";
import { useColorMode } from "@chakra-ui/color-mode";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface IProps {
  setuploadDocument?: any;
  fromTextToSpeech?: boolean;
  fromDocumentProcessing?: boolean;
}
type OptionType = {
  value: string;
  label: string;
};
const UploadDocumentTranslationModal = ({
  setuploadDocument,
  fromTextToSpeech,
  fromDocumentProcessing,
}: IProps) => {
  const { t } = useTranslation("translation");
  // const fileTypes = [
  //   // 'application/msword',

  //   // 'text/plain',

  //   "pdf",
  //   "application/pdf",
  //   "msword",
  //   "application/msword",
  //   "text/plain",
  //   "plain",
  //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //   // 'application/xml',
  //   // 'doc',
  //   // 'docs',
  //   // 'docx',
  //   // 'dox',
  //   // 'txt'
  // ];

  const fileTypes = [
    "application/pdf", // PDF
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
    "text/plain",
    "txt", // TXT
    "application/msword", // DOC (although .doc is outdated, modern browsers often convert .doc to .docx)
  ];

  const fileTypes2 = [
    "application/msword",
    "msword",
    "text/plain",
    "txt",
    "plain",
    "pdf",
    "application/pdf",
    "application/xml",
    "doc",
    "docs",
    "docx",
    "dox",
    "csv",
    "html",
    "xlsx",
    "pptx",
  ];
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [rangeVisible, setRangeVisible] = useState(false);
  const [pageRange, setPageRange] = useState<any>({ from: 1, to: 2 });
  const [noOfPages, setNoOfPages] = useState(1);
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedRange, setIsCheckedRange] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [selected, setSelected] = useState<OptionType | null>(null);
  const [selectedLeanguage, setSelectedLeanguage] = useState("");
  const [outPutName, setOutPutName] = useState("");
  const {
    response,
    translateProgress,
    payloadData,
    outputFileName,
    originalFileLanguage,
    translatedFileLanguage,
  } = useSelector((state: any) => state.translationStore.translations);

  const { colorMode, setColorMode } = useColorMode();

  const checkBoxChange = (e: any, text: String) => {
    if(text == "all"){
      setIsChecked(e.target.checked);
      setIsCheckedRange(!e.target.checked);
    }else{
      setIsChecked(!e.target.checked);
      setIsCheckedRange(e.target.checked);
    }
    // setIsChecked(e.target.checked);
    console.log("Checkbox is checked:", e.target.checked);
  };

  const setValues = (newValues: any) => {
    setPageRange(newValues);
  };

  // const handleChange = async (selectedFile: any) => {
  //   if (
  //     selectedFile &&
  //     (fileTypes.includes(selectedFile.type) ||
  //       fileTypes.includes(selectedFile.type.split("/").pop()))
  //   ) {
  //     console.log("selectedFile", selectedFile?.type);
  //     setShowRange(true);
  //     const arrayBuffer = await selectedFile.arrayBuffer();
  //     const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
  //     setNoOfPages(pdf.numPages);
  //     if (pdf.numPages < 5) {
  //       setPageRange({ from: 1, to: pdf.numPages });
  //     } else {
  //       setPageRange({ from: 1, to: 5 });
  //     }
  //     setFile(selectedFile);
  //     dispatch(setpayload(selectedFile));
  //   } else {
  //     setShowRange(false);
  //     dispatch(setpayload(selectedFile));
  //     setFile(selectedFile);
  //   }

  //   fromDocumentProcessing;
  // };
  // console.log("pageRange--->",pageRange);

  const paginateText = (text: string, linesPerPage: number) => {
    const lines = text.split("\n"); // Split text by line
    const pages = [];
    for (let i = 0; i < lines.length; i += linesPerPage) {
      pages.push(lines.slice(i, i + linesPerPage).join("\n")); // Create a page with a subset of lines
    }
    return pages;
  };

  const handleChange = async (selectedFile: any) => {
    dispatch(setOriginalFileLanguage(""));
    dispatch(setOutputFileName(""));
    if (
      selectedFile &&
      (fileTypes.includes(selectedFile.type) ||
        fileTypes.includes(selectedFile.type.split("/").pop()))
    ) {
      console.log("selectedFile", selectedFile?.type);

      const fileType = selectedFile.type;

      // PDF handling
      if (fileType === "application/pdf") {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("selectedPages", JSON.stringify([1]));
        const response = await notebookService.generateTextFromPdf(formData);
        const fullText = response.data.data;

        const chunkLength = 20;
        const textChunk = fullText.substring(0, chunkLength);
        // const originalFileLanguage = await getTextLanguage(textChunk);
        // dispatch(setOriginalFileLanguage(originalFileLanguage?.data?.language));
        const arrayBuffer = await selectedFile.arrayBuffer();
        const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
        setNoOfPages(pdf.numPages);
        if (pdf.numPages < 10) {
          setPageRange({ from: 1, to: pdf.numPages });
        } else {
          setPageRange({ from: 1, to: 10 });
        }
        setShowRange(true);
        setFile(selectedFile);
        dispatch(setpayload(selectedFile));
      }

      // Word document handling (.docx)
      else if (
        fileType === "application/msword" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        console.log("in else if");
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("selectedPages", JSON.stringify([1]));
        const response = await notebookService.generateTextFromPdf(formData);
        const fullText = response.data.data;

        const chunkLength = 20;
        const textChunk = fullText.substring(0, chunkLength);
        const originalFileLanguage = await getTextLanguage(textChunk);
        dispatch(setOriginalFileLanguage(originalFileLanguage?.data?.language));
        const arrayBuffer = await selectedFile.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        console.log("Extracted text from DOCX:", result.value);
        const pages = paginateText(result.value, 40); // Custom pagination (40 lines per page)
        setNoOfPages(pages.length);
        if (pages.length < 5) {
          setPageRange({ from: 1, to: pages.length });
        } else {
          setPageRange({ from: 1, to: 5 });
        }
        setShowRange(true);
        setFile(selectedFile);
        dispatch(setpayload(selectedFile));
      }

      // Plain text handling (.txt)
      else if (fileType === "text/plain") {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("selectedPages", JSON.stringify([1]));
        const response = await notebookService.generateTextFromPdf(formData);
        const fullText = response.data.data;

        const chunkLength = 20;
        const textChunk = fullText.substring(0, chunkLength);
        const originalFileLanguage = await getTextLanguage(textChunk);
        dispatch(setOriginalFileLanguage(originalFileLanguage?.data?.language));
        const reader = new FileReader();
        reader.onload = function (e) {
          const textContent = e.target?.result as string;
          console.log("Text file content:", textContent);
          const pages = paginateText(textContent, 40); // Custom pagination (40 lines per page)
          setNoOfPages(pages.length);
          if (pages.length < 5) {
            setPageRange({ from: 1, to: pages.length });
          } else {
            setPageRange({ from: 1, to: 5 });
          }
          setShowRange(true);
          setFile(selectedFile);
          dispatch(setpayload(selectedFile));
        };
        reader.readAsText(selectedFile);
      }
    } else {
      console.log("selectedFile in else", selectedFile?.type);
      setShowRange(false);
      dispatch(setpayload(selectedFile));
      setFile(selectedFile);
    }
  };

  // console.log("payloadData==>", payloadData);

  const dispatch: any = useDispatch();
  const chatData = useSelector((state: any) => state.chatGptStore);
  const { documentData } = useSelector((state: any) => state.translationStore);
  const router = useRouter();

  const handleSelectedPagesChange = (newSelectedPages: any) => {
    setSelectedPages(newSelectedPages);
  };

  const { createChat } = chatData;

  const showToast = (msg: any, err?: boolean) => {
    const icon = err == true ? "iconError" : "iconCheck";
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>

          <div className="flex justify-center">
            {/* <button
            className="btn-stroke-light btn-medium md:min-w-[6rem]"
            onClick={() => toast.dismiss(t.id)}
          >
            {lt('cancel_btn"')}
          </button> */}

            {/* <button
            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
            onClick={async () => {
              toast.dismiss(t.id);
            }}
          >
           {'Close'}
          </button> */}
          </div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };
  function getPageNumbersArray() {
    const pageNumbers: number[] = [];
    for (let i = pageRange.from; i <= pageRange.to; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  // const handleNextClick = async () => {
  //   setLoading(true);
  //   if (file) {
  //     // if(file?.size > 10 * 1024 * 1024){
  //     //   showToast('File size should be less than 10mb')
  //     //   setLoading(false)
  //     //   return;
  //     // }
  //     if (file?.size < 1024) {
  //       showToast("File size should be greater than 1kb");
  //       setLoading(false);
  //       return;
  //     }
  //     if (!rangeVisible && noOfPages > 1) {
  //       showToast(t("select_page_range"));
  //       setLoading(false);
  //       return;
  //     }
  //     if (pageRange.from > pageRange.to) {
  //       showToast(t("from_to_error"));
  //       setLoading(false);
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("selectedPages", JSON.stringify(getPageNumbersArray()));
  //     try {
  //       const response = await notebookService.generateTextFromPdf(formData);

  //       if (response && response.data && !fromTextToSpeech) {
  //         let notebookData: any = {
  //           chatId: createChat?.data?.id,
  //           response: response.data.data,
  //         };
  //         if (router?.pathname?.includes("debate")) {
  //           notebookData["debateId"] = router?.query?.id;
  //         }

  //         dispatch(addToLiveNotebook(notebookData));
  //         setuploadDocument && setuploadDocument(false);
  //       } else if (fromTextToSpeech) {
  //         dispatch(setFileText(response.data.data));
  //         setModalVisible(false);
  //       } else {
  //         console.error("Invalid or missing response data.");
  //         showToast("Invalid or missing response data.");
  //       }
  //     } catch (error: any) {
  //       console.error("Error while making the API call:", error);
  //       const errorMsg =
  //         error?.response?.data?.message ?? error?.message ?? "Unknown Error";
  //       showToast(errorMsg);
  //     }
  //   } else {
  //     console.warn("No file selected.");
  //     showToast("No file selected.");
  //   }
  //   setLoading(false);
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOutPutName(event.target.value);
  };
  const handleTranslate = async () => {
    setLoading(true);
    if (!file || !selected) {
      toast.error("File and Target Language is required.");
      setLoading(false);
      return;
    }

    if (file.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      setLoading(false);
      return;
    }
    if (!outPutName) {
      showToast("Enter a name for translated File ");
      setLoading(false);
      return;
    }
    // dispatch(setpayload({ targetLanguage: selected }));
    dispatch(setOutputFileName(outPutName));
    // setOutputFileName
    if (!isChecked && showRange) {
      if (pageRange.from > pageRange.to) {
        showToast(t("from_to_error"));
        setLoading(false);
        return;
      }
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("targetLanguage", selected?.value);
        formData.append("selectedPages", JSON.stringify(getPageNumbersArray()));
        formData.append("translationName", outPutName);
        const res = await axios.post(
          `${Config.baseApiUrl}/document-translation/store`,
          formData,
          {
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              dispatch(settranslateProgress(Math.ceil(percentCompleted / 1.2)));
            },
          }
        );
        if (res.status == 200) {
          console.log(
            "res.data?.translatedDocumentUrl",
            res.data?.data?.translatedDocumentUrl
          );

          dispatch(settranslateProgress(100));
          dispatch(setresponse(res.data));
          toast(
            (t) => (
              <Notify iconCheck>
                <div className="h6">Translated successfully!</div>
              </Notify>
            ),
            { duration: 3000 }
          );
          setLoading(false);
          dispatch(settranslateProgress(0));
        } else {
          setLoading(false);
          toast.error("Failed to Translate.");
          showToast("Failed to Translate.");
          // changeStepHandler(true);
        }
      } catch (error: any) {
        dispatch(settranslateProgress(0));
        setLoading(false);
        toast.error(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to translate due to Unknown reason."
        );
        showToast(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to translate due to Unknown reason."
        );
      }
    } else {
      try {
        const formData = new FormData();
        console.log("selected", selected?.value);

        formData.append("file", file);
        formData.append("targetLanguage", selected?.value);
        // formData.append("selectedPages", JSON.stringify(getPageNumbersArray()));
        formData.append("translationName", outPutName);
        const res = await axios.post(
          `${Config.baseApiUrl}/document-translation/store`,
          formData,
          {
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              dispatch(settranslateProgress(Math.ceil(percentCompleted / 1.2)));
            },
          }
        );
        if (res.status == 200) {
          dispatch(settranslateProgress(100));
          dispatch(setresponse(res.data));
          // changeStepHandler();
          toast(
            (t) => (
              <Notify iconCheck>
                <div className="h6">Translated successfully!</div>
              </Notify>
            ),
            { duration: 3000 }
          );
          setLoading(false);
          dispatch(settranslateProgress(0));
        } else {
          setLoading(false);
          toast.error("Failed to Translate.");
          showToast("Failed to Translate.");
          // changeStepHandler(true);
        }
      } catch (error: any) {
        setLoading(false);
        dispatch(settranslateProgress(0));
        toast.error(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to translate due to Unknown reason."
        );
        showToast(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to translate due to Unknown reason."
        );
      }
    }
  };

  const handleDownload = async (url: string) => {
    if (url) {
      console.log("url-->", url);
      const extractedFileName = url.substring(url.lastIndexOf("/") + 1);

      // Optional: Extract just the extension (e.g., .pdf)
      const fileExtension = extractedFileName.substring(
        extractedFileName.lastIndexOf(".")
      );
      console.log(fileExtension);

      saveAs(url, `${outputFileName}-translated${fileExtension}`);
      dispatch(setOriginalFileLanguage(""));
      dispatch(setOutputFileName(""));
      // dispatch(settranslateProgress(0));
      // dispatch(setresponse(null));
    } else {
      // toast.error("URL Unavailable.");
      showToast("URL Unavailable.");
    }
  };

  return (
    <div style={{ width: "-webkit-fill-available" }}>
      <div
        className={`bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5 ${
          fromTextToSpeech && " mt-4"
        }`}
      >
        <label
          className={`relative cursor-pointer border-white border-dashed border w-full min-h-[190px] h-[${
            fromTextToSpeech ? 200 : 260
          }px] rounded-[20px] flex flex-col justify-center items-center`}
        >
          {loading ? (
            <Loader color="white" />
          ) : (
            <FileUploader
              disabled={loading}
              handleChange={handleChange}
              multiple={false}
              classes="drag_drop"
              name="file"
              types={fileTypes2}
            >
              <div className="flex justify-center items-center flex-col w-full mt-3  focus:!border-transparent focus-within:!outline-none cursor-pointer">
                <span className="mb-6 block">
                  <UploadIcons />
                </span>
                <h2
                  className={`h5 text-white !font-mont mb-1 ${
                    fromTextToSpeech ? "-mt-2" : ""
                  }`}
                >
                  {fromTextToSpeech ? t("Choose_File") : t("Upload")}
                </h2>
                <div className="text-white m-auto text-center  w-[90%] mb-3 text-[14px]">
                  {file
                    ? `${file?.name}`
                    : fromTextToSpeech
                    ? t("text_speech_drag")
                    :  t("doc_dragDrop")}
                </div>
              </div>
            </FileUploader>
          )}
        </label>
      </div>

      <div className="flex gap-[12px] item-center mt-[36px] mb-[17px] w-full plg:flex-col">
        <div className=" rounded-xl bg-[#F1EFFE]  p-[12px] w-full">
          <Checkbox
            disabled={false}
            className="mb-4 last:mb-0"
            textClass="text-dark"
            label={"Translate full document"}
            // key={checkbox.id}
            value={isChecked}
            onChange={(e: any) => {
              checkBoxChange(e, "all");
            }}
            reverse
          />
        </div>

        <div className="rounded-xl bg-[#FFE8D6]  p-[12px] w-full ">
          <Checkbox
            disabled={false}
            className="mb-4 last:mb-0"
            label={"Select range of pages"}
            textClass="text-dark"
            // key={checkbox.id}
            value={isCheckedRange}
            onChange={(e: any) => {
              checkBoxChange(e, "range");
            }}
            reverse
          />
        </div>
      </div>
      {!isChecked && showRange && (
        <>
          {/* <span className="text-lg mr-3 pb-1"> {t("custom_range")} </span> */}
          <div className=" flex flex-row w-full mt-[20px]">
            <PageRangeSelector
              maxLength={noOfPages}
              from={pageRange.from}
              to={noOfPages}
              setValues={setValues}
              setRangeVisible={setRangeVisible}
            />
          </div>
        </>
      )}
      {/* <div className="  sm:text-xs flex items-center !font-mont font-semibold mb-5">
        {originalFileLanguage && originalFileLanguage != "Unknown"
          ? t("detected_language") + ": " + originalFileLanguage
          : t("detected_language")}
      </div> */}
      <div className="flex mb-4 px-[12px] py-[20px] w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white mt-[34px]">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name the output document"
          value={outPutName} // Set the input value to state
          onChange={handleInputChange} // Update the state on input change
          className="border-none outline-none focus:ring-0 focus:outline-none bg-transparent w-full"
        />

        <WriteIcon color={colorMode === "dark" ? "#B0B0B0" : "#3e3e3e"}/>

        {/* You can use the 'name' value here */}
        {/* <p className="mt-2 text-gray-700 dark:text-gray-300">You entered: {outPutName}</p> */}
      </div>
      <div className="mt-[20px] mb-[34px] w-full">
        {/* <label
          htmlFor="name"
          className="mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Select Language:
        </label> */}
        <LanguagesSearchSelect
          langData={languageData}
          selected={selected}
          setSelected={setSelected}
          placeholder="Select Language"
        />
      </div>
      <button
        disabled={!file || !selected?.value || loading}
        className="btn bg-[#8E55EA] w-full text-white dark:bg-[#8E55EA] dark:border-[#8E55EA] mb-[16px] flex items-center justify-center h-[45px]"
        onClick={handleTranslate}
      >
        <TranslateIcon2 /> Translate
      </button>

      <div className="flex items-center w-full gap-[12px] plg:flex-col ">
      <MoveChatDropdown
          documentId={response?.data?._id}
          chatList={createChat.data?.chatList?._id ?? null}
          showDropDown={true}
          chatButton
        />
        <button
          disabled={!response?.data?.translatedDocumentUrl}
          className="btn bg-[#B5E4CA] w-full  dark:bg-[#B5E4CA] dark:border-[#B5E4CA] border-[#B5E4CA]  h-[60px] text-[#000] flex justify-center items-center font-[600]"
          onClick={() => handleDownload(response?.data?.translatedDocumentUrl)}
        ><DownloadIcon/> Download
        </button>

       
      </div>
    </div>
  );
};

export default UploadDocumentTranslationModal;
