import React, { useState, useEffect } from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import { PROFILE_URL } from "@/constants/user";
import { lokiChatMention } from "@/constants/debate";
import { isEmpty,size } from "lodash";
import { MentionsInput, Mention } from "react-mentions";
import { useTranslation } from "react-i18next";
import { MAX_INPUT_FIELD_LIMIT } from "@/constants/chats";
import { mentionStyle } from './defaultStyle';

const MentionInput = (props: any) => {
  const { t } = useTranslation('translation');
  const {
    setSelectedMentions,
    onClickHandler,
    description,
    setDescription,
    disabled,
    placeholder
  } = props;

  const debateData = useSelector((state: any) => state.debateStore);
  const userData = useSelector((state: any) => state.authStore);
  const { storeDebate, debateInvitedUsers } = debateData;
  const { user } = userData;

  const [customers, setCustomers] = useState<any>([]);

  useEffect(() => {
    try {
      if (!isEmpty(storeDebate.data?.user)) {
        let debateUsers = [
          lokiChatMention,
          ...debateInvitedUsers,
          storeDebate.data.user,
        ];
        debateUsers = debateUsers.filter((el: any) => el?._id !== user?.data?.id);
        setCustomers(debateUsers);
      }
    } catch (error) {
      
    }
    
  }, [debateInvitedUsers]);

  const changeHanlder = (event: any) => {
    try {
      let original_value =
      event.target.value.slice(0, MAX_INPUT_FIELD_LIMIT) ??
      event.clipboardData.getData("text").slice(0, MAX_INPUT_FIELD_LIMIT);

    setDescription(original_value);
    const mentions =
      original_value
        .match(/\(\w+\)/g)
        ?.map((match: any) => match.replace(/\(|\)/g, "")) || [];

    let description = original_value;
    description = description.replace(/@\[([^\]]+)\]\(([^)]+)\)/g, "");
    setSelectedMentions(mentions);
    } catch (error) {
      
    }
    
  };

  const handleKeyDown = (event: any) => {
    try {
      if(event.key == "Enter" && !event.shiftKey){
        event.preventDefault();
      }
      if (event.key === "Enter" && !event.shiftKey && size(description?.trim()) > 0) {
        onClickHandler(event);
        setDescription("");
      }
    } catch (error) {
      console.log('Empty input field');
    }
    
  };

  return (
    <MentionsInput
      placeholder={placeholder ?? t('ask_anything')+' ...'}
      maxLength={MAX_INPUT_FIELD_LIMIT}
      value={description}
      disabled={disabled}
      aria-valuemax={MAX_INPUT_FIELD_LIMIT}
      id="description-input"
      onChange={changeHanlder}
      onKeyDown={handleKeyDown}
      style={mentionStyle()}
      className={`w-full focus-visible:border-transparent relative !scrollbar-none`}
    >
      <Mention
        trigger="@"
        className="text-primary-1 bg-white dark:bg-n-6 !scrollbar-none"
        style={{ zIndex: 10, position: "inherit" }}
        appendSpaceOnAdd={true}
        displayTransform={(id: any, value: any) => {
          return `@${value}`;
        }}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <>
          <figure className="relative w-8 h-8 rounded-full">
            <Image
              //@ts-ignore
              src={suggestion?.image ?? PROFILE_URL}
              //@ts-ignore
              alt={suggestion.display}
              fill
              className="rounded-full w-full h-full"
            />
            </figure>
            {highlightedDisplay}
          </>
        )}
        //@ts-ignore
        data={customers && customers.map((data) => ({
          id: data?._id,
          display: data?.userName,
          image: data?.profileUrl,
        }))}
      />
    </MentionsInput>
  );
};

export default MentionInput;
