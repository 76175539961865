import React from "react";
import {Tooltip } from "flowbite-react";
import Image from "next/image";

interface IProps {
    tooltipContent: string;
    className?:string;
    src?:any;
    key?:any;
    onClick?:any
}

const ImageButtonIndicator = ({ tooltipContent,className,src,key,onClick}: IProps) => {
  return (
    <Tooltip
      content={<div className="truncate">{tooltipContent}</div>}
      placement="bottom"
      className={`bg-n-4  text-xs Tooltip max-w-[150px] z-50 ${className}`}
    >
        <figure
            className="w-9 ss:w-6 ss:h-6 h-9 rounded-full border border-white relative -ml-2 md:-ml-3 cursor-pointer"
            key={key}
            onClick={onClick}
          >
            <Image
              src={src}
              fill
              alt="profile"
              className="w-full h-full rounded-full"
            />
          </figure>
    </Tooltip>
  );
};

export default ImageButtonIndicator;
