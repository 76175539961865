import React, { useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { MAX_INPUT_FIELD_LIMIT } from "@/constants/chats";

type QuestionsTextareaProps = {
  allowEdit?: any;
  handleEditQuestion?: any;
  value?: any;
  onKeyDown?: any;
  onKeyUp?: any;
};
const QuestionsTextarea = ({
  allowEdit,
  handleEditQuestion,
  value,
  onKeyDown,
  onKeyUp,
}: QuestionsTextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current && value) {
      const length = value.length;
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(length, length);
    }
  }, []);

  return (
    <>
      <TextareaAutosize
        ref={textareaRef}
        className={`${allowEdit ? "border-blue-500 py-1.5 px-2" : "border-transparent"} rounded-lg border w-full AtScroll bg-transparent body2 text-n-7 outline-none resize-none placeholder:text-n-4/75 dark:text-n-1 dark:placeholder:text-n-4 overflow-auto scrollbar-none`}
        autoFocus
        value={value}
        onChange={handleEditQuestion}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        maxLength={MAX_INPUT_FIELD_LIMIT}
      />
    </>
  );
};

export default QuestionsTextarea;
