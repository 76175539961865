import Icon from '@/components/Icon';
import { Audio } from '@/components/LandingPage/Icons'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import React from 'react'
interface IProps {
    nextClick: Function
    state: IDocumentTranslatorState;
    setState: Function;
    mtype?: string;
  }

const GenerateTranscription = ({nextClick,setState,state}:IProps) => {
  return (
    <div className='mt-5'>
    <div className='p-3 border-2 border-[#F3F5F7] dark:border-n-4 rounded-[20px] mb-8'>
    <div className='w-full flex justify-between items-start mb-3'>
          <div className='flex items-center gap-6'>
          <span className='w-16 !font-mont h-16 flex justify-center items-center bg-[#E5F2FE] rounded-lg'>
            <Audio/>
          </span>
          <div>
            <p className='font-bold w-[400px] md:w-[200px] sm:w-[120px] truncate'>{state?.file?.name}</p>
            <p className='text-xs'>{(Number(state?.file?.size) / 1000000).toFixed(2)} MB</p>
            {/* <span className='text-xs'><b>{Math.ceil(Number(state.file?.size) / 7)} words</b></span> */}
          </div>
        </div>
        <span onClick={() => { nextClick(true) }} ><Icon className="w-5 h-5 cursor-pointer" fill='#909292' name="close" /></span>
    </div>
    <span className='text-xs text-n-4 !font-mont'>Audio Length: 12:18</span>
    </div>

    <button className='btn btn-blue w-full'>Generate Transcription</button>

    </div>
  )
}

export default GenerateTranscription