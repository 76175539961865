import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import ModalShareChat from "@/components/ModalShareChat";
import Notifications from "./Notifications";
import Profile from "./Profile";
import { notifications } from "@/mocks/notifications";
import LiveNoteBook from "./LiveNoteBook/LiveNoteBook";
import ExportMedia from "./ExportMedia/ExportMedia";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import ImageLibrarySidebar from "../ImageLibrarySidebar";
import { ROUTES } from "@/constants/routes";
import SelectLanguage from "../Chat/SelectLanguage";
import TextToSpeech from "../TextToSpeech";
import BugReport from "../BugReport/Index";
import Transcription from "../Transcription";
import DocumentTranslating from "../DocumentTranslating";
import DocumentProcessing from "../DocumentProcessing";

type RightSidebarProps = {
  className?: string;
  visible?: boolean;
  toggle?: string;
  setToggle?: string;
};

const RightSidebar = ({
  className,
  visible,
  toggle,
  setToggle,
}: RightSidebarProps) => {
  const { t } = useTranslation("translation");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const router = useRouter();
  return (
    <>
      <div
       className={twMerge(
        `absolute top-0 right-0 bottom-0 flex flex-col ${
          router.pathname === ROUTES.DOCUMENT_PROCESSING ? "w-[41.875rem] 4xl:w-[31.875rem]" : "w-[31.875rem]"
        } 2xl:w-[25rem] pb-8 bg-n-1 rounded-r-[1.25rem] border-l border-n-3 lg:rounded-[1.25rem] lg:invisible lg:opacity-0 lg:transition-opacity lg:z-20 lg:border-l-0 lg:shadow-2xl md:fixed md:w-[calc(100%-4rem)] md:border-l md:rounded-none dark:bg-n-6 dark:border-n-5 h-auto
        ${
          visible && "lg:visible lg:opacity-100"
        } ${className}`
      )}
      
      >
        <div className="topbar flex justify-end items-center flex-shrink-0 h-[4.09rem] px-9 border-b border-n-3 lg:pr-18 md:pr-16 dark:border-n-5 gap-6 md:gap-4 md:pl-2">
          <BugReport />
          <Notifications items={notifications} />
          <button
            className="hidden btn-dark btn-medium"
            onClick={() => setVisibleModal(true)}
          >
            {t("share")}
          </button>
          
          <SelectLanguage />
          <Profile />
        </div>
        {router.pathname === ROUTES.PHOTO_GENERATION || router.pathname?.includes("/features") ? (
          <ImageLibrarySidebar />
        ) : router.pathname === ROUTES.TEXT_SPEECH ? (
          <TextToSpeech />
        ) :router.pathname === ROUTES.DOCUMENT_TRANSLATING ? (
          <DocumentTranslating />
        ) :router.pathname === ROUTES.DOCUMENT_PROCESSING ? (
          <DocumentProcessing />
        ) :router.pathname === ROUTES.TRANSCRIPTION ? (
          <Transcription />
        ) : (
          <LiveNoteBook />
        )}
      </div>
      <ModalShareChat
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      />
    </>
  );
};

export default RightSidebar;
