import { RES_PER_PAGE } from "@/constants/pagination";
import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, size } from "lodash";
import {
  getStoredDocumentList,
  // getDocumentListById,
  storeDocumentList,
  // exportDocumentLists,
  destroyDocumentList,
  updateDocumentListId,
  getDocumentListById,
} from "store/actions/documentList.actions";

export const initialState = {
  translations: {
    response: null,
    translateProgress: 0,
    curStep: 1,
    loading: false,
    payloadData: {
      targetLanguage: "",
      file: "",
    },
    outputFileName: "",
    error: null,
    originalFileText: "",
    originalFileLanguage: "",
    translatedFileText: "",
    translatedFileLanguage: "",
  },
  storeDocumentListData: {
    data: null,
    loading: false,
    error: null,
  },
  storedDocumentLists: {
    data: [],
    loading: false,
    error: null,
  },
  destroyDocumentListData: {
    data: null,
    loading: false,
    error: null,
  },
  documentListDetails: {
    data: { documents: [], currentPage: 0, pages: 0, totalDocuments: 0, perPage: RES_PER_PAGE },
    loading: false,
    error: null,
  },
  updateDocumentListId: {
    data: null,
    loading: false,
    error: null,
  },
  modalStatesDocument: {
    search: false,
    documentListModal: false,
    moveDocumentConfirmation: false,
    abortDocument: false,
  },
  moveDocumentListData: { data: null },
  selectedDocumentListItem: { data: null },
};

export const TranslationSlice = createSlice({
  name: "translationStore",
  initialState,
  reducers: {
    resetAllTranslations(state, action) {
      return initialState;
    },
    setresponse(state, action) {
      state.translations.response = action.payload;
    },
    setcurStep(state, action) {
      state.translations.curStep = action.payload;
    },
    setpayload(state, action) {
      console.log("Action Payload ", action.payload);

      state.translations.payloadData = action.payload;
    },
    settranslateProgress(state, action) {
      state.translations.translateProgress = action.payload;
    },
    setOutputFileName(state, action) {
      state.translations.outputFileName = action.payload;
    },
    setOriginalFileText(state, action) {
      state.translations.originalFileText = action.payload;
    },
    setOriginalFileLanguage(state, action) {
      state.translations.originalFileLanguage = action.payload;
    },
    setTranslatedFileText(state, action) {
      state.translations.translatedFileText = action.payload;
    },
    setTranslatedFileLanguage(state, action) {
      state.translations.translatedFileLanguage = action.payload;
    },
    resetStoredDocumentListData(state, action) {
      state.storeDocumentListData.loading = false;
      state.storeDocumentListData.data = null;
      state.storeDocumentListData.error = null;
    },
    resetUpdateDocumentListId(state, action) {
      state.updateDocumentListId.loading = false;
      state.updateDocumentListId.data = null;
      state.updateDocumentListId.error = null;
    },
    setMoveDocumentModal(state, action) {
      if (state.modalStatesDocument) {
        state.modalStatesDocument.moveDocumentConfirmation = action.payload;
      } else {
        // This ensures state is defined if for some reason it's missing
        state.modalStatesDocument = { ...initialState.modalStatesDocument };
        state.modalStatesDocument.moveDocumentConfirmation = action.payload;
      }
    },
    setMoveDocumentListData(state, action) {
      if (!state.moveDocumentListData) {
        state.moveDocumentListData = { ...initialState.moveDocumentListData };
      }
      state.moveDocumentListData.data = action.payload;
    },
    setDocumentListModal(state, action) {
      state.modalStatesDocument.documentListModal = action.payload;
    },
    setSelectedDocumentListItem(state, action) {
      state.selectedDocumentListItem.data = action.payload;
    },

  },
  extraReducers: {
    [storeDocumentList.pending.type]: (state, action: any) => {
      state.storeDocumentListData.loading = true;
      state.storeDocumentListData.data = null;
      state.storeDocumentListData.error = null;
    },
    [storeDocumentList.fulfilled.type]: (state: any, action: any) => {
      state.storeDocumentListData.data = action.payload.data;
      state.storeDocumentListData.loading = false;
      state.storeDocumentListData.error = null;
    },
    [storeDocumentList.rejected.type]: (state, action) => {
      state.storeDocumentListData.data = null;
      state.storeDocumentListData.loading = false;
      state.storeDocumentListData.error = action.error?.message;
    },
    // Get stored document lists
    [getStoredDocumentList.pending.type]: (state, action: any) => {
      state.storedDocumentLists.loading = true;
      state.storedDocumentLists.data = state.storedDocumentLists.data || [];
      state.storedDocumentLists.error = null;
    },
    [getStoredDocumentList.fulfilled.type]: (state: any, action: any) => {
      console.log("Action Payload in Fulfilled: ", action.payload); // Debugging log
      state.storedDocumentLists.data = action.payload?.data || [];
      state.storedDocumentLists.loading = false;
      state.storedDocumentLists.error = null;
    },

    [getStoredDocumentList.rejected.type]: (state, action) => {
      // Ensure state.storedDocumentLists exists before trying to access its properties
      state.storedDocumentLists = state.storedDocumentLists || {};
      state.storedDocumentLists.data = state.storedDocumentLists.data || [];
      state.storedDocumentLists.loading = false;
      state.storedDocumentLists.error =
        action.error?.message || "An unknown error occurred";
    },
    // Reducers for destroyDocumentList actions
    [destroyDocumentList.pending.type]: (state, action: any) => {
      if (!state.destroyDocumentListData) {
        // Ensure destroyDocumentListData exists
        state.destroyDocumentListData = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.destroyDocumentListData.loading = true;
      state.destroyDocumentListData.data = null;
      state.destroyDocumentListData.error = null;
    },
    [destroyDocumentList.fulfilled.type]: (state: any, action: any) => {
      if (!state.destroyDocumentListData) {
        // Ensure destroyDocumentListData exists
        state.destroyDocumentListData = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.destroyDocumentListData.data = action.payload?.data || null; // Make sure payload has data
      state.destroyDocumentListData.loading = false;
      state.destroyDocumentListData.error = null;
    },
    [destroyDocumentList.rejected.type]: (state, action) => {
      console.log("Rejected action: ", action); // Log the action for debugging
      console.log("State before updating: ", state); // Log the state
      if (!state.destroyDocumentListData) {
        state.destroyDocumentListData = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.destroyDocumentListData.data = null;
      state.destroyDocumentListData.loading = false;
      state.destroyDocumentListData.error =
        action.error?.message || "Error deleting document list";
    },
    // update document feedback || get translated documents 
    [getDocumentListById.pending.type]: (state, action: any) => {
      if (!state.documentListDetails) { // Ensure documentListDetails exists
        state.documentListDetails = { 
          data: { 
            documents: [], 
            currentPage: 0, 
            pages: 0, 
            totalDocuments: 0, 
            perPage: RES_PER_PAGE 
          }, 
          loading: false, 
          error: null 
        };
      }
      state.documentListDetails.loading = true;
      state.documentListDetails.error = null;
      state.documentListDetails.data.documents = [];
    },
    
    [getDocumentListById.fulfilled.type]: (state: any, action: any) => {
      if (!state.documentListDetails) { // Ensure documentListDetails exists
        state.documentListDetails = { 
          data: { 
            documents: [], 
            currentPage: 0, 
            pages: 0, 
            totalDocuments: 0, 
            perPage: RES_PER_PAGE 
          }, 
          loading: false, 
          error: null 
        };
      }
      state.documentListDetails.loading = false;
    
      if (
        action.meta.arg.page === 1 ||
        !isEmpty(action.meta.arg.filter) ||
        !isEmpty(action.meta.arg.search)
      ) {
        state.documentListDetails.data.documents = action.payload?.data?.documents || []; // Ensure payload has documents
      } else {
        state.documentListDetails.data.documents = [
          ...(state.documentListDetails.data.documents || []), // Ensure documents array exists
          ...(action.payload?.data?.documents || []), // Append new documents
        ];
      }
      state.documentListDetails.data.currentPage = action.payload?.data?.currentPage || 0; // Ensure payload values exist
      state.documentListDetails.data.pages = action.payload?.data?.pages || 0;
      state.documentListDetails.data.totalDocuments = action.payload?.data?.totalDocuments || 0;
      state.documentListDetails.data.perPage = action.payload?.data?.perPage || RES_PER_PAGE;
      state.documentListDetails.error = null;
    },
    
    [getDocumentListById.rejected.type]: (state, action) => {
      console.log("Rejected action: ", action); // Log the action for debugging
      console.log("State before updating: ", state); // Log the state
      if (!state.documentListDetails) {
        state.documentListDetails = { 
          data: { 
            documents: [], 
            currentPage: 0, 
            pages: 0, 
            totalDocuments: 0, 
            perPage: RES_PER_PAGE 
          }, 
          loading: false, 
          error: null 
        };
      }
      state.documentListDetails.loading = false;
      state.documentListDetails.data.documents = [];
      state.documentListDetails.error = action.error?.message || "Error fetching document list details";
    },

    //update Document List by Id
    [updateDocumentListId.pending.type]: (state, action: any) => {
      if (!state.updateDocumentListId) {
        // Ensure updateDocumentListId exists
        state.updateDocumentListId = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.updateDocumentListId.loading = true;
      state.updateDocumentListId.data = null;
      state.updateDocumentListId.error = null;
    },

    [updateDocumentListId.fulfilled.type]: (state: any, action: any) => {
      if (!state.updateDocumentListId) {
        // Ensure updateDocumentListId exists
        state.updateDocumentListId = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.updateDocumentListId.data = action.payload?.data || null; // Make sure payload has data
      state.updateDocumentListId.loading = false;
      state.updateDocumentListId.error = null;
    },

    [updateDocumentListId.rejected.type]: (state, action) => {
      console.log("Rejected action: ", action); // Log the action for debugging
      console.log("State before updating: ", state); // Log the state
      if (!state.updateDocumentListId) {
        state.updateDocumentListId = {
          data: null,
          loading: false,
          error: null,
        };
      }
      state.updateDocumentListId.data = null;
      state.updateDocumentListId.loading = false;
      state.updateDocumentListId.error =
        action.error?.message || "Error updating document list";
    },
  },
});

export const {
  resetAllTranslations,
  setcurStep,
  setpayload,
  setresponse,
  settranslateProgress,
  setOutputFileName,
  setOriginalFileText,
  setOriginalFileLanguage,
  setTranslatedFileText,
  setTranslatedFileLanguage,
  resetStoredDocumentListData,
  setMoveDocumentModal,
  setMoveDocumentListData,
  setDocumentListModal,
  resetUpdateDocumentListId,
  setSelectedDocumentListItem,
} = TranslationSlice.actions;

export default TranslationSlice.reducer;
