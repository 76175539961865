export const mentionStyle = () => {
  return {
    suggestions: {
      item: {
        "&focused": {
          backgroundColor: "#0084FF",
          color: "white",
        },
      },
    },
  };
};
