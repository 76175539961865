import React, { useState } from 'react'
import DocumentOptionSelect from './DocumentOptionSelect';
import Modal from '..';
import SelectSubjectModal from '../SelectSubjectModal';
import UploadDocumentSelectModal from '../UploadDocumentSelectModal';
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import UploadingDocument from '@/components/UploadingDocumentModal';
import toast from 'react-hot-toast';
import Notify from '@/components/Notify';
import axios from 'axios';
import config from '@/config/index';
import { isEmpty, size } from 'lodash';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { addSummaryDocument } from 'store/slices/ChatGptSlice';
import { useTranslation } from "react-i18next";


type DocumentSelectModal = {
    onCancel : () => void;
    selectedChatModel: any;
};
const DocumentSelectModal = ({ onCancel, selectedChatModel }: DocumentSelectModal) => {
    const router = useRouter();
    const dispatch: any = useDispatch();

    const [SelectedExamType, setSelectedExamType] = useState<any>();
    const [examData, setexamData] = useState({
      file: "",
      chatId: "",
      type: "",
      subject: "",
      domain: ""
    });
    const [examLoading, setexamLoading] = useState<boolean>(false);
    const { t:lt } = useTranslation('translation');
    const chatData = useSelector((state: any) => state.chatGptStore);

    const [visibleModal, setVisibleModal] = useState<boolean>(false); 
    const [uploadDocument, setuploadDocument] = useState<boolean>(false); 
    const [curStep, setcurStep] = useState<number>(1);
    const [mtype, setmtype] = useState<string>("summary");
    const [response, setresponse] = useState<any>({});
    const [translateProgress, settranslateProgress] = useState<number>(0);
    const [type, setType] = useState<any>(false); 

    const modalopen = () => {
      if (type === 0) {
          setVisibleModal(true)
      }
      if (type == 1) {
        initExamMeResponse('chat')
      }
      if (type === 2) {
        setuploadDocument(true)
      }
    }

    const changeStepHandler = (toBack: boolean = false) => {
        try {
          if (toBack) {
            if (curStep != 1) {
              setcurStep((ps: number) => ps - 1)
            }
          } else {
            if (curStep != 4) {
              setcurStep((ps: number) => ps + 1)
            }
          }
        } catch (error) {
          
        }
      }
      
      const initExamMeResponse = async (typee:string) => {
        try {
          setexamLoading(true);
          const formData = new FormData();
          formData.append('file', examData.file);
          formData.append('type', typee);
          formData.append('subject', examData.subject);
          formData.append('domain', examData.domain);

          if (router.pathname === ROUTES.CHAT) {
            if (!isEmpty(chatData.createChat.data?.id)) {
              formData.append('curChat', chatData.createChat.data?.id);
              formData.append('chatId', chatData.createChat.data?.id);
            }
          } else if (
            router.query.id &&
            (router.pathname === ROUTES.CHAT_ID ||
            router.pathname === ROUTES.CHAT_LIST_ID)
          ) {
            //@ts-ignore
            formData.append('curChat', router.query.id);
            //@ts-ignore
            formData.append('chatId', router.query.id);
          }
    
          changeStepHandler()
          const res = await axios.post(`${config.baseApiUrl}/document-summary/examMe`,
            formData,
            {
              onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                settranslateProgress(Math.ceil(percentCompleted))
              }
            }
          );
          if (res.status == 200) {
            setresponse(res.data)
            toast((t) => (
              <Notify iconCheck>
                <div className="h6">{lt('summarize_success')}</div>
              </Notify>
            ));
            dispatch(addSummaryDocument(res.data))
            setcurStep(1)
            settranslateProgress(0);
            setVisibleModal(false);
            setuploadDocument(false);
            onCancel()
          } else {
            toast.error(lt('fail_exam'))
            // changeStepHandler(true)
            setcurStep((ps: number) => ps - 1)
            setuploadDocument(false)
          }
          setexamLoading(false)
        } catch (error) {
          console.log(error);
          setuploadDocument(false)
          setcurStep((ps: number) => ps - 1)
          //@ts-ignore
          toast.error(error?.response?.data?.message ?? error?.data?.message ?? error?.message ?? lt('fail_translate'))
          // changeStepHandler(true)
          setexamLoading(false)
        }
      }

  return (
    <div>
        <h6 className='text-base font-semibold !font-mont mb-5'>{lt('select_option')}</h6>
        <DocumentOptionSelect setType={setType} setSelectedExamType={setSelectedExamType}/>
        <button className='btn btn-blue w-full mt-5' onClick={modalopen}>{lt('next')}</button>
          <Modal
              className=""
              classOverlay=""
              classWrap="md:min-h-screen-ios !p-9  !py-6 max-w-[688px]"
              classButtonClose="absolute top-6 right-6 w-6 h-6 rounded-full bg-n-2 md:right-5 dark:bg-n-4 dark:fill-white dark:hover:fill-white"
              visible={visibleModal}
              onClose={() => {setVisibleModal(false) }}
          >
          <SelectSubjectModal examLoading={examLoading} initExamMeResponse={initExamMeResponse} onCancel={() => {setVisibleModal(false)}} examData={examData} setexamData={setexamData} />
        </Modal>
        <Modal
              className=""
              classOverlay=""
              classWrap="md:min-h-screen-ios !p-9  !py-6 max-w-[688px]"
              classButtonClose="absolute top-6 right-6 w-6 h-6 rounded-full bg-n-2 md:right-5 dark:bg-n-4 dark:fill-white dark:hover:fill-white"
              visible={uploadDocument}onClose={() => {setuploadDocument(false) }}
          >
            { curStep===1&& <UploadDocumentSelectModal nextClick={()=>{initExamMeResponse('document')}} state={examData} setState={setexamData}/>}
            { curStep===2&& <UploadingDocument nextClick={changeStepHandler} mtype={mtype} state={examData} setState={setexamData} progress={translateProgress}/>}
        </Modal>
    </div>
  )
}

export default DocumentSelectModal