import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { useTranslation } from "react-i18next";
import { MAX_INPUT_FIELD_LIMIT } from "@/constants/chats";
import { mentionStyle } from './defaultStyle';

const MentionInput = (props: any) => {
    const { t } = useTranslation('translation');
    const {
        textareaRef,
        value,
        onChange,
        onKeyDown,
        placeholder,
        disabled,
        mentionUsers
    } = props;

    const [customers, setCustomers] = useState<any>([]);

    useEffect(() => {
        if (mentionUsers && mentionUsers.length > 0) {
            setCustomers(mentionUsers);
        }
    }, [mentionUsers]);

    const handleOnChange = (event: any, newValue: string, newPlainTextValue: string, mentions: any[]) => {
        // If there's already one mention, prevent adding more
        const mentionCount = (newPlainTextValue.match(/@\[(.*?)\]/g) || []).length;
        if (mentionCount <= 1) {
            onChange(event, newValue, newPlainTextValue, mentions);
        }
    };

    return (
        <MentionsInput
            placeholder={placeholder || t('ask_anything') + ' ...'}
            maxLength={MAX_INPUT_FIELD_LIMIT}
            value={value}
            disabled={disabled}
            aria-valuemax={MAX_INPUT_FIELD_LIMIT}
            id="description-input"
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            style={mentionStyle()}
            className={`w-full focus-visible:border-transparent relative !scrollbar-none ${props?.fromGuest && 'text-black'}`}
            inputRef={textareaRef}
        >
            <Mention
                trigger="@"
                className="text-primary-1 bg-white dark:bg-n-6 !scrollbar-none"
                style={{ zIndex: 10, position: "inherit" }}
                appendSpaceOnAdd={true}
                displayTransform={(id: any, value: any) => `@${value}`}
                data={customers.map((data: any) => ({
                    id: data.id,
                    display: data.userName,
                }))}
            />
        </MentionsInput>
    );
};

export default MentionInput;
