import { useEffect, useState } from "react";
import Link from "next/link";
import Field from "@/components/Field";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "store/actions/auth.actions";
import { isEmpty } from "lodash";
import { signupSchema } from "lib/validations/authentications";
import { BiErrorCircle } from "react-icons/bi";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsEye } from "react-icons/bs";
import { IoEyeOffOutline } from "react-icons/io5";
import { ROUTES } from "@/constants/routes";
import { setNavigateTo } from "store/slices/AuthSlice";
import { setSelectedChatModal } from "store/slices/ChatGptSlice";
import { CHAT_MODEL } from "@/constants/chats";

type CreateAccountProps = {
  setForgetSelectedIndex?: any;
  setForgot?: any;
  setSignUp?: any;
  navigateTo?: any;
};

const CreateAccount = ({
  setForgetSelectedIndex,
  setForgot,
  setSignUp,
  navigateTo
}: CreateAccountProps) => {
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("auth");

  const userData = useSelector((state: any) => state.authStore);

  const initialErrors = {
    name: "",
    email: "",
    password: "",
  };

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [eye, setEye] = useState(false);

  useEffect(() => {
    if (!isEmpty(userData.registerUser.data)) {
      setForgetSelectedIndex(1);
      setForgot(true);
      setSignUp(true);
    }
  }, [userData.registerUser]);
  const router = useRouter();
  const referralCode = router.query.referralCode || "";
  const campainId = router.query.campainId || "";

  const handleRegisterUser = async (event: any) => {
    event.preventDefault();

    let data:any = {
      userName: name,
      email,
      password,
      referalInvite: referralCode,
      campainId: campainId,
    };
    if(userData.guestUser.data){
      data.guestId = userData.guestUser.data.id
    }
    let fieldErrors;

    await signupSchema
      .validate({ name, email, password }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      dispatch(registerUser(data)).unwrap().then(() => {
        if(navigateTo){
          if(navigateTo === ROUTES.CHAT){
            dispatch(setSelectedChatModal({ name: "Normal Chat", key: CHAT_MODEL.GEMENI_MODEL }))
          }
          else{
            dispatch(setNavigateTo(navigateTo))
          }
        }
      });
    }
  };
  const isAllowToCreate = () => {
    if (
      userData.registerUser.loading ||
      !isChecked ||
      !email ||
      !password ||
      !name
    ) {
      return true;
    }
  };

  return (
    <form action="">
      <div className="mb-4">
        <Field
          classInput="dark:bg-n-7 dark:border-n-7"
          placeholder={lt("enter_name")}
          icon="user"
          type="text"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
            setErrors(initialErrors);
          }}
          required
        />
        {errors.name && (
          <div className="text-red-500 text-xs mt-1">{errors.name}</div>
        )}
      </div>
      <div className="mb-4">
        <Field
          classInput="dark:bg-n-7 dark:border-n-7"
          placeholder={lt("type_email")}
          icon="email"
          type="email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrors(initialErrors);
          }}
          required
        />
        {errors.email && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            <BiErrorCircle className="text-base" /> {errors.email}
          </div>
        )}
      </div>
      <div className="mb-6 relative">
        <Field
          classInput="dark:bg-n-7 dark:border-n-7"
          placeholder={lt("type_password")}
          icon="lock"
          type={!eye ? "password" : " text"}
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value);
            setErrors(initialErrors);
          }}
          required
        />
        {errors.password && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            {" "}
            <BiErrorCircle className="text-base" /> {errors.password}
          </div>
        )}
        <span className="absolute top-1/2 -translate-y-1/2 right-3">
          {!eye ? (
            <BsEye
              className="cursor-pointer fill-n-7 dark:fill-n-3"
              onClick={() => {
                setEye(true);
              }}
            />
          ) : (
            <IoEyeOffOutline
              className="cursor-pointer fill-n-7 dark:fill-n-3"
              onClick={() => {
                setEye(false);
              }}
            />
          )}
        </span>
      </div>
      <div className="flex items-center gap-2 mb-6">
        <input
          className="h-[22px] w-[22px]  inline-flex flex-shrink-0"
          type="checkbox"
          checked={isChecked}
          // @ts-ignore
          defaultValue={isChecked}
          onClick={() => setIsChecked(!isChecked)}
        />

        <div className="caption1" id="headlessui-portal-root">
          {lt("agree_account")}{" "}
          <Link
            className="text-n-5 transition-colors hover:text-n-7 dark:text-n-3 dark:hover:text-n-1 font-bold"
            href={ROUTES.PRIVACY_POLICY}
          >
            {lt("privacy_policy")}
          </Link>
          .
        </div>
      </div>
      <button
        className="btn-blue btn-large w-full"
        type="submit"
        disabled={isAllowToCreate()}
        onClick={handleRegisterUser}
      >
        {lt("create_myAccount")}
      </button>
    </form>
  );
};

export default CreateAccount;
