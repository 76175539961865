import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ModalShareChat from "@/components/ModalShareChat";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import DebateUser from "../DebateUsers";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import SelectLanguage from "./SelectLanguage";
import MoveChatDropdown from "../Search/MoveChatDropdown";
import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import { io } from "socket.io-client";
import { SOCKET_OPTIONS, SOCKET_URL } from "@/config/socketConfig";
import {
  setDebateInvitedUsers,
  setIsDebateArchieved,
} from "store/slices/DebateSlice";
import {
  Copywriting,
  GPT,
  Loki,
  Education,
  Download,
} from "../LandingPage/Icons";
import TranslatingProgressbar from "../TranslatingProgressbar";
import { saveAs } from "file-saver";
import {
  setcurStep,
  setOriginalFileLanguage,
  setOutputFileName,
  setresponse,
  settranslateProgress,
} from "store/slices/TranslationSlice";
import { useTranslation } from "react-i18next";

const socket = io(SOCKET_URL, SOCKET_OPTIONS);

type ChatProps = {
  title: string;
  children: React.ReactNode;
  setToggle?: any;
  toggle?: any;
  debate?: any;
  guest?: any;
  docProcessing?: boolean;
  isLoading?: any;
  setIsLoading?: any;
};

const Chat = ({
  title,
  children,
  setToggle,
  toggle,
  debate,
  guest,
  docProcessing,
  isLoading,
  setIsLoading,
}: ChatProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");
  const { pathname, query } = router;
  const debateData = useSelector((state: any) => state.debateStore);
  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  const { translateProgress, response, payloadData, outputFileName } =
    useSelector((state: any) => state.translationStore.translations);

  const { user } = userData;
  const { storeDebate, debateInvitedUsers, updateDebate, updateDebateStream } =
    debateData;
  const { createChat, stream, storeMessageStream } = chatData;

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoading) {
      scrollInstantToLastMsg();
      setIsLoading(false);
    }
  }, [isLoading]);

  const scrollInstantToLastMsg = () => {
    const messageContainer = document.getElementById(
      "messageContainerChild"
    )?.parentElement;
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight, // Scroll to the bottom
        left: 0,
        behavior: "auto", // Use "auto" for instant scrolling
      });
    }
  };

  const scrollToLastMessage = () => {
    try {
      if (chatContainerRef && chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    } catch (error) {}
  };

  const downloadFile = async () => {
    const url = response?.data?.translatedDocumentUrl;
    if (url) {
      const extractedFileName = url.substring(url.lastIndexOf("/") + 1);

      // Optional: Extract just the extension (e.g., .pdf)
      const fileExtension = extractedFileName.substring(
        extractedFileName.lastIndexOf(".")
      );
      saveAs(url, `${outputFileName}-translated${fileExtension}`);
      dispatch(settranslateProgress(0));
      dispatch(setresponse(null));
      dispatch(setOriginalFileLanguage(""));
      dispatch(setOutputFileName(""));
    } else {
      console.log("error downloading... ");
    }
  };
  useLayoutEffect(() => {
    scrollInstantToLastMsg();
  }, [createChat.loading]);

  useLayoutEffect(() => {
    if (stream || storeMessageStream.loading) {
      scrollToLastMessage();
    }
  }, [createChat.data]);

  useLayoutEffect(() => {
    if (updateDebate.loading || updateDebateStream.loading) {
      scrollToLastMessage();
    }
  }, [storeDebate.data.messages]);

  useLayoutEffect(() => {
    if (pathname === ROUTES.DEBATE_ID) {
      scrollInstantToLastMsg();
    }
  }, [storeDebate.loading]);

  const handleInviteDebateSocketEvent = (payload: any) => {
    try {
      const updatedInvitedUsers = payload.invitedUsers;

      if (size(storeDebate?.data) >= 0 && query.id === payload.debateId) {
        dispatch(setDebateInvitedUsers([...updatedInvitedUsers]));
      }
    } catch (error) {}
  };

  const handleUpdateDebateDataEvent = (payload: any) => {
    try {
      if (size(storeDebate?.data) >= 0 && query.id === payload.debateId) {
        dispatch(setIsDebateArchieved(payload.isArchived ?? false));
      }
    } catch (error) {}
  };

  useEffect(() => {
    try {
      socket.on(
        `${SOCKET_EVENT_TYPES.UPDATE_DEBATE_INVITED_USERS}_${user?.data?.id}`,
        handleInviteDebateSocketEvent
      );
      return () => {
        socket.off(
          `${SOCKET_EVENT_TYPES.UPDATE_DEBATE_INVITED_USERS}_${user?.data?.id}`,
          handleInviteDebateSocketEvent
        );
      };
    } catch (error) {}
  }, [handleInviteDebateSocketEvent]);

  useEffect(() => {
    try {
      socket.on(
        `${SOCKET_EVENT_TYPES.UPDATE_DEBATE_STATUS_AND_DATA}_${user?.data?.id}`,
        handleUpdateDebateDataEvent
      );
      return () => {
        socket.off(
          `${SOCKET_EVENT_TYPES.UPDATE_DEBATE_STATUS_AND_DATA}_${user?.data?.id}`,
          handleUpdateDebateDataEvent
        );
      };
    } catch (error) {}
  }, [handleUpdateDebateDataEvent]);

  const routes = [
    ROUTES.TRACKER,
    ROUTES.CHECKOUT,
    ROUTES.AUDIOLIBRARY,
    ROUTES.UPDATESANDFAQS,
    ROUTES.REFERRAL_MANAGEMENT,
    ROUTES.TRANS_LIBRARY,
    ROUTES.IMAGE_LIBRARY,
  ];

  const isDebateUser = debateInvitedUsers?.find(
    (el: any) => el?._id === user?.data?.id
  );
  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;
  const { storedChatLists } = chatData;

  const ModelRef: any = {
    "gemini-pro": (
      <>
        <Loki /> Loki
      </>
    ),
    "gpt-35-turbo": (
      <>
        <GPT /> Professor
      </>
    ),
    "copy-writer": (
      <>
        <Copywriting /> Copywriting
      </>
    ),
    "embeded-model": (
      <>
        <Education /> Professor
      </>
    ),
    artist_profile: (
      <>
        <Loki /> {t("Artist")}
      </>
    ),
    architect_profile: (
      <>
        <Loki /> {t("Architect")}
      </>
    ),
    biologist_profile: (
      <>
        <Loki /> {t("Biologist")}
      </>
    ),
    business_profile: (
      <>
        <Loki /> {t("Business Master")}
      </>
    ),
    chemical_profile: (
      <>
        <Loki /> {t("Chemical Engineer")}
      </>
    ),
    designer_profile: (
      <>
        <Loki /> {t("Designer")}
      </>
    ),
    computer_profile: (
      <>
        <Loki /> {t("Computer Scientist")}
      </>
    ),
    doctor_profile: (
      <>
        <Loki /> {t("Doctor")}
      </>
    ),
    lawyer_profile: (
      <>
        <Loki /> {t("Lawyer")}
      </>
    ),
    engineer_profile: (
      <>
        <Loki /> {t("Engineer")}
      </>
    ),
    historian_profile: (
      <>
        <Loki /> {t("Historian")}
      </>
    ),
    economist_profile: (
      <>
        <Loki /> {t("Economist")}
      </>
    ),
    physicist_profile: (
      <>
        <Loki /> {t("Physicist")}
      </>
    ),
    mathematician_profile: (
      <>
        <Loki /> {t("Mathematician")}
      </>
    ),
    political_profile: (
      <>
        <Loki /> {t("Political Analyst")}
      </>
    ),
    philosopher_profile: (
      <>
        <Loki /> {t("Philosopher")}
      </>
    ),
    psychologist_profile: (
      <>
        <Loki /> {t("Psychologist")}
      </>
    ),
    theologist_profile: (
      <>
        <Loki /> {t("Theologist")}
      </>
    ),
    professor_profile: (
      <>
        <Loki /> {t("Professor")}
      </>
    ),
    sociologist_profile: (
      <>
        <Loki /> {t("Sociologist")}
      </>
    ),
    mega_lawyer_profile: (
      <>
        <Loki /> {t("mega_lawyer")}
      </>
    ),
  };

  return (
    <>
      {docProcessing ? (
        <></>
      ) : (
        <>
          {!guest && (
            <div
              className={` ${
                routes.includes(pathname) ? "" : "lg:!pr-16 ss:!pr-14"
              } !pr-6 md:pr-0 py-3 border-b border-n-3 flex justify-between sticky top-0 z-10 gap-4 bg-white dark:bg-n-6 w-full items-center px-6 md:px-5 rounded-t-xl shadow-[0_0.75rem_2.5rem_-0.75rem_rgba(0,0,0,0.06)] 2xl:px-6  dark:border-n-5 dark:shadow-[0_0.75rem_2.5rem_-0.75rem_rgba(0,0,0,0.15)] ${
                router.pathname?.includes("/features") && "z-0"
              }`}
            >
              <div
                className={
                  "h5 truncate md:h6 font-mont font-[800] ml-[16px] md:ml-[2px]"
                }
              >
                {title}
              </div>
              <div className={`flex items-center gap-4 md:gap-2 ml-6 md:ml-0 `}>
                {pathname !== ROUTES.DEBATE_ID &&
                  !isEmpty(createChat.data?.id) &&
                  ModelRef[`${createChat?.data?.chatModel}`] &&
                  pathname.includes(ROUTES.CHAT) && (
                    <div className="max-w-[250px] rounded-lg py-2.5 px-3 border border-n-3 dark:border-n-5 text-center text-sm font-medium">
                      <div className="flex items-center gap-2">
                        {ModelRef[`${createChat?.data?.chatModel}`] ?? "N/A"}
                      </div>
                    </div>
                  )}
                {debate &&
                  (isDebateAdmin || isDebateUser) &&
                  !isDebateArchived && <DebateUser />}

                {pathname !== ROUTES.DEBATE_ID &&
                  !isEmpty(createChat.data?.id) &&
                  size(storedChatLists?.data) > 0 && (
                    <MoveChatDropdown
                      chatId={createChat.data?.id}
                      chatList={createChat.data?.chatList?._id ?? null}
                      chatButton
                    />
                  )}
                {pathname !== ROUTES.DOCUMENT_PROCESSING &&
                (translateProgress || response) &&
                translateProgress != 0 ? (
                  <TranslatingProgressbar completed={translateProgress} small />
                ) : response ? (
                  //HIDE DOWNLOAD ICON
                  // pathname !== ROUTES.DOCUMENT_PROCESSING &&
                  //   <button
                  //   className="btn btn-blue w-auto rounded-full p-1 flex items-center justify-center"
                  //   onClick={downloadFile}
                  // >
                  //   <Download />
                  // </button>
                  <div></div>
                ) : null}
                {pathname !== ROUTES.CHAT &&
                  pathname !== ROUTES.CHAT_ID &&
                  pathname !== ROUTES.CHAT_LIST &&
                  pathname !== ROUTES.CHAT_LIST_ID &&
                  pathname !== ROUTES.DEBATE &&
                  pathname !== ROUTES.DEBATE_ID &&
                  pathname !== ROUTES.TEXT_SPEECH &&
                  pathname !== ROUTES.DOCUMENT_TRANSLATING &&
                  pathname !== ROUTES.DOCUMENT_PROCESSING &&
                  pathname !== ROUTES.PHOTO_GENERATION &&
                  !pathname?.includes("/features") &&
                  pathname !== ROUTES.TRANSCRIPTION && <SelectLanguage />}
              </div>
            </div>
          )}
        </>
      )}

      <div
        ref={chatContainerRef}
        id="messageContainer"
        className={`relative z-2 grow overflow-y-auto scroll-smooth pt-[24px] px-9 2xl:px-[15px] 4xl:px-[20px] no-scrollbar ${
          pathname === ROUTES.PHOTO_GENERATION ||
          pathname?.includes("/features")
            ? " overflow-y-hidden overflow-x-hidden"
            : "p-0"
        } `}
      >
        {children}
      </div>
      <ModalShareChat
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      />
    </>
  );
};

export default Chat;
