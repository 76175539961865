import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";


interface IProps {
  completed: number;
  small?: boolean
}
const TranslatingProgressbar = ({ completed, small }: IProps) => {
  return <ProgressBar padding="0" width={small ? "40px" : "64px"} height={small ? "40px" : "64px"} borderRadius="50%" bgColor="" maxCompleted={100} completedClassName="justify-center items-center w-full h-full flex bg-[#0084FF]" labelColor="white" labelClassName="" completed={completed} />
};
export default TranslatingProgressbar