import { HttpService } from "./base.service";
import { IExportChatData, IStoreDocumentListData,IUpdateDocumentListData } from "../interfaces/document.interface";

class DocumentListService extends HttpService {
  private readonly prefix: string = "document-list";

  index = (): Promise<any> => this.get(this.prefix + '/index');

  store = (data: IStoreDocumentListData): Promise<any> => this.post(this.prefix + "/store", data);
  update = (data: any): Promise<any> => this.put(this.prefix + `/update/${data.id}`, data);
  destroy = (id: any): Promise<any> => this.delete(this.prefix + `/destroy/${id}`, null);
  updateDocumentList = (data: IUpdateDocumentListData): Promise<any> => this.post(this.prefix + '/update-document-list', data);
  show = (params: any): Promise<any> => this.get(this.prefix + `/showByPagination?documentListId=${params.documentListId}&page=${params.page}&perPage=${params.perPage}&search=${params.search}&filter=${params.filter}`);
  // export = (data: IExportChatData): Promise<any> => this.post(this.prefix + '/export', data);
  // deleteMultiple = (ids: any): Promise<any> => this.put(this.prefix + `/delete`, { chatIds: ids });
}

export const documentListService = new DocumentListService();
