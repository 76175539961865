import React, { useState, useEffect, useRef } from "react";
import Notify from "@/components/Notify";
import { toast } from "react-hot-toast";
import Icon from "@/components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import UploadLiveNoteBookModal from "../Modal/UploadLiveNoteBookModal";
import Select from "@/components/Select";
import { PitchOptions, modesList, speeds } from "@/constants/audio";
import { getPlainTextFromHTML } from "lib/getPlainText";
import { isEmpty, size } from "lodash";
import { getVoicesList } from "store/actions/voicesList.actions";
import {
  generateAudio,
  getInprogressAudios,
} from "store/actions/audio.actions";
import { TextInput } from "flowbite-react";
import {
  setAudioDetails,
  setIsAudioGenerated,
} from "store/slices/TextToSpeechSlice";
import { setFromAudioSummary } from "store/slices/AudioSlice";
import { useMediaQuery } from "react-responsive";
import { Language, SpeedIcon, VoiceIcon, VoicePitch } from "../SVGs";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import UploadDocumentTranslationModal from "../Modal/UploadDocumentTranslation";
import LanguagesSearchSelect from "../LanguagesSearchSelect";
import languageData from "@/constants/language";
import Main from "@/templates/HomePage/Main";
import { setSelectedChatModal } from "store/slices/ChatGptSlice";
import { CHAT_MODEL } from "@/constants/chats";
const LanguageDetect = require("languagedetect");

const lngDetector = new LanguageDetect();

const DocumentProcessing = ({ fromFeature }: any) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [toggle, setToggle] = useState<boolean>(true);
  
  useEffect(() => {
    dispatch(
      setSelectedChatModal({
        name: "Normal Chat",
        key: CHAT_MODEL.GEMENI_MODEL,
      })
    );
  }, []);

  return (
    // <>
    //   <div className="flex flex-col justify-between items-center sm:flex-col sm:gap-3 sm:mb-2 px-9 md:px-6 h-[93%] -mt-[5%]">

    //     Processing Side Bar
    <Main setToggle={setToggle} toggle={toggle} docProcessing={true} />
    //   </div>
    // </>
  );
};

export default DocumentProcessing;
