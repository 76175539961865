import { ROUTES } from "@/constants/routes";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import { IoMdMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

type BurgerProps = {
    className?: string;
    onClick: () => void;
    visibleRightSidebar: boolean;
};

const Burger = ({ className, onClick, visibleRightSidebar }: BurgerProps) => {
    const [mounted, setMounted] = useState<boolean>(false);
    const router = useRouter();

    const { pathname } = router;
    useEffect(() => {
        setMounted(true);
    }, []);

    return mounted ? (
        <MediaQuery maxWidth={1023}>
            <button
                className={`
                ${!visibleRightSidebar?"top-4 right-0":"top-15px pmd:top-4 right-5 pmd:right-0"}
                z-[25] shrink-0  flex items-center w-9 ss:w-6 ss:h-6 h-9  ml-auto mr-6 tap-highlight-color absolute  ${
                    visibleRightSidebar && "absolute sm:fixed"
                } ${className}`}
                onClick={onClick}
            >
                <span className="text-4xl !font-mont ss:text-3xl">
                  {!visibleRightSidebar? <IoMdMenu />:<RxCross2/>}
                </span>
            </button>
        </MediaQuery>
    ) : null;
};

export default Burger;
